<template>
  <div class="edit" ref="edit">

    <q-resize-observer @resize="getChapterOffsets" />

    <narrative-editor
      :editable="editable"
      v-model="content"
      v-if="content.type"
      ref="editor"
      @focus="e=>$emit('showBuilder')"
      @toggleDrop="e=>$emit('toggleDrop',e)"
      @refreshOffsets="refreshOffsets = true"
    />

    <pre v-if="debug" class="debug">{{chapters}}</pre>

  </div>
</template>

<script>
import { UseEase, clone } from '../tic';
import { ref, computed, watch } from '@vue/composition-api'
import NarrativeEditor from './NarrativeEditor';

import { dom } from 'quasar'
const { offset } = dom

export default {
  name: "NarrativeEdit",

  props: {

    debug:{
      type:Boolean,
      default:false
    },

    editable:{
      type:Boolean,
      default:true
    },

    chapters:{
      type:Array,
      default:() => []
    },

    activeChapter:{
      type:Number,
      default:0
    }

  },

  setup (props, context) {
  },

  components: {
    NarrativeEditor
  },

  data () {
    return {
      content:{},
      localChapters:[],
      skipProps:{
        type:undefined,
        title:undefined,
        content:undefined
      },
      refreshOffsets:false,
    }
  },

  computed: {
  },

  watch: {

    chapters: {
      handler() {
        const hasChanged = this.chapters.toString()!=this.localChapters.toString();
        if (hasChanged)
        {
          console.log('📕 Narrative-edit: update content')
          this.localChapters = clone(this.chapters);
          this.content = this.parseNarrative();
        }

        if (this.refreshOffsets)
        {
          setTimeout(e=>this.getChapterOffsets(),1); //NOTE timeout to make sure editor flow has refreshed
          this.refreshOffsets = false;
        }

      },
      immediate:true,
      deep: true
    },

    content: {
      handler(to,from) {
        if (Object.keys(from).length) this.parseTiptap();
      },
      deep: true
    }
  },

  mounted () {
    console.log('📕 Narrative-edit mounted')
  },

  methods: {
    parseNarrative () {

      console.log('📕 Narrative-edit: parse Narrative to TipTap')

      //from narrative JSON to tiptap JSON

      let parsed = {
        type: "doc",
        content: []
      }

      //parse
      this.localChapters.forEach((node,index) => {

        if (node.type=='paragraph' || node.type.indexOf('source')>-1)
        {
          //support content without chapters
          this.addNode(node,parsed.content);
        }
        else if (node.type=='chapter')
        {

          parsed.content.push({
            type:'heading',
            attrs:{
              level:2,
              blockData:Object.assign({}, node, this.skipProps) //store narrative block data in heading, so we don't loose it when editing in TipTap
            },
            content:node.title? [{ type:'text', text:node.title }]:[],
          })


          node.content.forEach((node,index) => {

          //sub-chapters
          if (node.type=='subchapter')
          {
            parsed.content.push({
              type:'heading',
              attrs:{
                level:3,
                blockData:Object.assign({}, node, this.skipProps)
              },
              content:[{ type:'text', text:node.title }]
            })

            //sub-chapter content

            if (node.content) node.content.forEach(node => this.addNode(node,parsed.content))
            else
            {
              console.log('? other props',node)
            }
          }
          else
          {
            //chapter without sub-chapters
            this.addNode(node,parsed.content);
          }

        });

        }

      });

      return parsed;

    },

    addNode (node,target) {
      //add supported nodes

      if (node.type=='paragraph' || node.type=='keyquestion')
      {
        node.attrs = {
          blockData:Object.assign({}, node, this.skipProps)
        }
        target.push(node)
      }

//       if (node.type=='keyquestion') //NOTE alternative implementation: key question as vue node
//       {
//         target.push({
//           type:"NarrativeKeyQuestion",
//           attrs:{
//             uuid:node.uuid,
//             related:node.related
//           },
//           content:node.content || [
//             {
//               "type":"text",
//               "text":node.text
//             }
//           ]
//         })
//       }

      if (node.type.indexOf('source')>-1)
      {
        const
          type = node.type=='source'? 'Source':'Collection',
          isCollection = type=='Collection';

        //set caption type
        if (node.content)
        {
          node.content[0].attrs.blockData = { isCaption:type }
        }

        //parse source object to editor readable node
        target.push({
          type:"NarrativeSource"+(isCollection? 'Collection':''),
          attrs:{
            title:node.title,
            caption:node.caption,
            description:node.description,
            src:node.asset?.url,
            type:node.asset?.type,
            related:node.related,
            sources:node.sources?.map(s => {
              return {
                uuid:s.uuid,
                title:s.title,
                text:s.caption,
                url:s.asset.url
              }
            })
          },
          content:node.content || [
            {
              "type": "paragraph",
              "attrs": { "blockData": { isCaption:type } },
              "content": [{
                "type":"text",
                "text":node.description
              }]
            }
          ]
        })
      }

    },

    parseTiptap (doc) {

      console.log('📕 Narrative-edit: parse TipTap to Narrative')

      //from tiptap JSON to narrative JSON

      let chapter,subchapter,chapters = [];

      this.content.content.forEach((node,index) => {

        //insert (sub)chapters for headings
        if (node.type=='heading')
        {
          //skip empty headings
          if (!node.content) return;

          const
            type = node.attrs.level==2? 'chapter':'subchapter',
            data = node.attrs.blockData,
            block = {
              type:type,
              title:node.content[0]? node.content[0].text:'', //TODO remove formatting
              summary:[],
              content:[]
            }

          //add narrative block data
          for (const p in data) {
            if (data[p]) block[p] = data[p];
          }
          //TODO always add default props? (title, slug, ...)

          if (type=='chapter')
          {
            //reset subchapter, create new chapter
            subchapter = undefined;
            chapter = block;

            chapters.push(chapter)

          }
          else
          {
            subchapter = block;
            chapter.content.push(subchapter);
          }

        }
        else
        {
          //insert content in current (sub)chapter
          const target = subchapter || chapter;

          //support narratives without actual chapters: pushes nodes directly into main content array
          const content = target? target.content:chapters;

//           if (!target && index==0)
//           {
//             //skip anything before first heading
//             return console.log('skip ',node.type,'@index=',index)
//           }

				  //console.log('push node=',node.type,'into',target)

				  if (node.type=='NarrativeSource')
				  {
            content.push({
              type:'source',
              uuid:node.attrs.uuid,
              title:node.attrs.title,
              description:this.getText(node.content),
              content:node.content,
              related:node.attrs.related,
              asset:{
                type:'image',
                url:node.attrs.src,
                type:node.attrs.type
              }
            })
				  }
				  else if (node.type=='NarrativeSourceCollection')
				  {
            content.push({
              type:'sourceCollection',
              title:node.attrs.title,
              description:this.getText(node.content),
              content:node.content,
              related:node.attrs.related,
              sources:node.attrs.sources.map(s => {
                return {
                  type:'source',
                  uuid:s.uuid,
                  title:s.title,
                  caption:s.text,
                  asset:{
                    type:'image',
                    url:s.url
                  }
                }
              })
            });
				  }
				  else
				  {
				    const
				      data = node.attrs.blockData,
               block = {
                type:node.type,
                content:node.content
              }

            //add block data
            for (const p in data) {
              if (data[p]) block[p] = data[p];
            }

            content.push(block)
          }

        }
      });

      //notify parent
      this.$emit('edit',chapters);

    },

    getText (content) {
      //get all text props from a tiptap content array

      let text = '';
      content.forEach(node => {
        if (node.type=='paragraph')
        {
          if (node.content) node.content.forEach(textnode => text += textnode.text)
        }
        text += ' '; //add space between paragraphs
      })

      return text;
    },

    getChapterOffsets () {
      console.log('NEdit: getChapterOffsets')

      const
        offsets = [],
        chapterNodeOffsets = [],
        elm = document.querySelector('.ProseMirror');

      const t = Date.now();

      if (!elm) return; //DOM not ready

      Array.from(elm.getElementsByTagName('h2')).forEach(e => {
        const
          offset = e.offsetTop,
          nodeOffsets = [];

        offsets.push(offset);

        //also get childnode offsets within chapter, to sync sidebar
        let node = e.nextElementSibling;
        while (node && node.nodeName!='H2') {
          nodeOffsets.push(node.offsetTop - offset)
          node = node.nextElementSibling;
        }

        chapterNodeOffsets.push(nodeOffsets);
      });

//       console.log('took '+(Date.now() - t)+'ms');
//       console.log(chapterNodeOffsets)

      this.$emit('offsets',offsets)
      this.$emit('nodeOffsets',chapterNodeOffsets)
      this.$emit('resize');
    },

  }
}
</script>

<style lang="stylus">
@import '~quasar-variables'

/* non-scoped style! */

/*
.ProseMirror > *::before
  content:''
  position:absolute;
  left:0;
  width:100%;
  height:1px;
  background-color:rgba(250,0,0,.5);
 */


.ProseMirror
  max-width:770px;
  /* color:$black; */

.ProseMirror h2:not(:first-child)
  margin-top:100px;

.ProseMirror h3
  position:relative;
  margin-left:25px;

.ProseMirror p
  position:relative;
  padding-left:25px;
  padding-right:25px;
  margin-bottom:19px;

.ProseMirror
  p:not(.is-empty):hover::before,
  p.ProseMirror-selectednode::before
    content:''
    position:absolute;
    left:0;
    top:3px;
    width:20px;
    height:20px;
    background-color:$light-gray;

.ProseMirror p.ProseMirror-selectednode
  cursor:grab;

.ProseMirror p.ProseMirror-selectednode::after
  content:'';
  position:absolute;
  top:3px;
  left:0;
  width:100%;
  height:100%;
  box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);
  pointer-events:none;

.ProseMirror p.ProseMirror-selectednode::before
  background-color:$plasticgroen !important;
  pointer-events:none;


.ProseMirror p + div.source-node
  margin-top:32px;

.ProseMirror h3::after
  content:'';
  position:absolute;
  left:-75px;
  top:0;
  width:50px;
  height:50px;
  background-color:$plasticgroen;

.ProseMirror .is-empty::before
  content: attr(data-placeholder);
  float: left;
  color:alpha($black,.22);
  pointer-events: none;
  height: 0;

.narrative .q-placeholder::placeholder
  color:alpha($black,.22) !important;
  opacity:1 !important;

.narrative .source-title .q-placeholder::placeholder {
  color: rgba(255,255,255,1) !important;
  opacity:.5 !important;
}


.ProseMirror question
  display:block;
  position:relative;
  margin-bottom:19px;
  padding:9px 0 12px 66px;
  font-size: 20px;
  font-weight:600;
  font-style:italic;
  color:$eigeel;

.ProseMirror question::after
  content:'';
  position:absolute;
  left:0;
  top:0;
  width:50px;
  height:50px;
  background-color:$eigeel;

</style>