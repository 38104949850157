<template>
	<section id="hiFilter">
		<q-btn
			:color="getColor"
          	no-caps
          	flat
          	@click="showFilters=!showFilters"
          	>
			<i class="fad fa-filter"></i>&nbsp;Filter by Tags&nbsp;<i class="fad fa-tags"/>
		</q-btn>

		<q-chip
			removeable
			@remove="removeFilterTag(at._id)"
			:class="`bg-${getColor} text-primary`"
			:key="at._id"
			v-for="at in active"
			:label="at.name"
			>
			<!-- deze code is nodig omdat er anders geen remove X icoontje is -->
			<i @click="removeFilterTag(at._id)" aria-hidden="false" role="button" class="q-chip__icon q-chip__icon--remove cursor-pointer q-icon notranslate material-icons" tabindex="-1" aria-label="Remove">cancel</i>

		</q-chip>

	<q-dialog v-model="showFilters" full-width @hide="updateFilter">
		<q-card>
				<q-bar :class="`bg-${getColor} text-white`">
				<span><i class="fad fa-tags"/>&nbsp;
				Filter content by tag</span>
				<q-space />
          		<q-btn @click="showFilters = false" dense round flat icon="close" />
			</q-bar>

	<q-scroll-area style="height: 80vh">
	<div style="padding: 1rem; display: grid; grid-template-columns: repeat(3, 1fr);">
		<div v-for="tag in tags">
			<h5 class="tagname">{{tag.name}}</h5>
			<label v-for="child in tag.child">
				<input @click="toggleFilter(child)" :key="child._id" :checked="isChecked(child._id)" type="checkbox"> {{child.name}}
				<q-badge v-if="getCount(child.uuid)" color="grey-5" text-color="grey-10" rounded> {{ getCount(child.uuid) }} </q-badge>

				<br>
			</label>
		</div>
	</div>
	</q-scroll-area>


	<q-card-actions class="justify-center  q-pa-md q-mx-auto" :class="`bg-${getColor} text-white`">
		<q-btn color="grey-7" @click="active=[]" label="reset"/>
		<q-btn color="grey-7" label="Ok" v-close-popup/>
	</q-card-actions>
	</q-card>
	</q-dialog>

</section>
</template>

<script>
import { API } from "@/tic";
import getColor from '../mixins/getColor.mixin.js';

export default {
  name: 'cFilter',
  mixins: [getColor],
  props: {
  	activeTags: {
  		type: Array,
  	},
    ct: {
      type: String,
      default: ''
    }
  },

  computed: {
  },

  watch: {
  	activeTags(foo, bar) {
  		let vm = this
  		console.log("activeTags changed!!!", foo)
  		// console.log("activeTags: ", vm.activeTags)
  		// console.log("allTags: ", vm.allTags)
  		vm.active = []
  		for (let at of foo) {
  			console.log("check ", at)
				let e = vm.allTags.filter(e => e.uuid === at)
				console.log("eres: ", e)
  			if (e) {
  				console.log("add tag to active: ", e)
  				vm.active.push(e[0])
  			}
  		}

  		console.log("active: ", vm.active)

  	}
  },

  mounted() {
  	let vm = this
  	console.log("cFilter mounted")
  	API.get(`/tags/all/${vm.tagcloud}`).then((r) => {
  		vm.tags = r.data.data.child
  		vm.counts = r.data.data.counts
  		// flat array of objects with all tags.
  		vm.allTags = vm.tags.flatMap(parent => parent.child)
  		// add a v-model var to each tag; not used
  		// vm.tags.map(e => ({...e, selected:true}))
  	})

  },

  methods: {

  	getCount(uuid) {
  		return this.counts.filter(e => e.uuid===uuid) ?
  		this.counts.filter(e => e.uuid===uuid)[0]?.count : '42'
  	},

  	removeFilterTag(tag_id)
  	{
  		let vm = this
  		// console.log("remove tag ", tag_id)
  		vm.active = vm.active.filter(e => e._id!==tag_id)
  		vm.$emit('updateFilter', vm.active)

  	},

    isChecked(value) {
    	let vm = this
	    let ids = vm.active.map(e => e._id)
	    return ids.includes(value)
    },

  	updateFilter() {
  		console.log("** filter hide")
  		this.$emit('updateFilter', this.active)
  	},

  	toggleFilter(tag) {
  		let vm = this
  		let ids = vm.active.map(e => e._id)
  		if (ids.includes(tag._id)) {
  			vm.active = vm.active.filter(e => e._id!==tag._id)
  		} else {
  			vm.active.push(tag)
  		}
  	},

  	setVal(value) {
  		console.log("set value: ", value)
  	}
  },

  data() {
  	return {
  		active: [],
  		showFilters: false,
  		filter: [],
  		tags: [],
  		allTags: [], // flat all tags
  		counts: [],
  		tagcloud: 'european-association-of-history-educators'

  	}
  }

}


 </script>


<style>
.ellipsis {
  color: white;
  text-transform: uppercase;
}

.q-chip__icon--remove {
  color: white !important;
}

.tagname {
	padding: 0;
	margin-top: 1em;
	margin-bottom: 0.2em;
}

</style>