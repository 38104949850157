<template>
  <div v-if="!$q.loading.isActive" class="historical-content">
    <section id="historical-content-page">
      <div class="row q-mx-auto justify-between items-start">
        <div class="col" v-if="!chapter">
          <h1 class="module_name">{{ m.name }}</h1>
        </div>
        <div class="col" v-else>
          <h5 class="module_title">{{ m.name }}</h5>
          <h3 class="hc_title">{{ getName(chapter) }}</h3>
        </div>
      </div>

      <div class="row q-mx-auto justify-between items-start">
        <div class="col-3 q-pr-md">
          <span v-if="chapter">
            <img :src="current.thumb" />
          </span>
          <span v-else>
            <img :src="m.thumb" />
          </span>
        </div>
        <div class="col q-pr-md" v-if="chapter">
          <p v-html="current.intro" />
        </div>
        <div class="col q-pr-md" v-else>
          <p
            class="intro"
            style="white-space: pre-line"
            v-html="m.introduction"
          />
        </div>

        <div class="col-3 panel">
          <h5>
            <i class="fa fa-home" />&nbsp;
            <a :href="getUrl()">{{ m.name }}</a>
          </h5>
          <ol>
            <li :key="ci" v-for="(e, ci) in m.chapters">
              <a :href="getUrl(e.slug)">{{ e.name }}</a>
            </li>
          </ol>

          <h6><i class="fa fa-users" /> Acknowledgements</h6>
          <p>{{ m.acknowledgements }}</p>

          <h6><i class="fa fa-copyright" /> Copyright</h6>
          <p>
            This module is published under the
            <strong>{{ m.copyright.name }}</strong> licence.
          </p>
        </div>
      </div>
    </section>


    <span :key="ix" v-for="(e, ix) in items">
      <section class="q-mb-xl">
        <q-separator light class="q-my-lg" />
        <div class="row q-mx-auto q-px-xl justify-between items-start">
          <div class="col-3 q-pr-md">
            <img v-if="e.icon" :src="e.icon.url" />
            <p class="q-mb-xl">{{ e.icon.title }}</p>

            <div v-if="e.la && e.la.length">
              <h6 class="q-my-md">
                <i class="fa fa-users" /> Learning Activities
              </h6>
              <q-list bordered separator :key="i" v-for="(d, i) in e.la">
                <q-item>
                  <a :href="`/learning-activity/${d.slug}`">{{ d.title }}</a>
                </q-item>
              </q-list>
            </div>

            <div v-if="e.downloads.length">
              <h6 class="q-my-md"><i class="fa fa-download" /> Downloads</h6>
              <q-list bordered separator :key="i" v-for="(d, i) in e.downloads">
                <q-item>
                  <a :href="d.url">{{ d.title }}</a>
                </q-item>
              </q-list>
            </div>
          </div>
          <div class="col content">
            <h5 class="q-mt-none">{{ e.title }}</h5>
            {{ e.intro }}
            <p v-html="e.embed_code" />
          </div>
        </div>
      </section>
    </span>

  </div>
</template>

<script>
import { API } from '../tic';
export default {
  name: 'HcBySlug',
  //props: ['type', 'slug','chapter'],
  props: {
    type: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    },
    chapter: {
      type: String,
      default: ''
    }
  },

  watch: {
    chapter: {
      immediate: true,
      handler(chapter) {
        let vm = this;
        this.items = [];
        this.isLoading = true;
        API.get(`/get-hc-items/${this.chapter}`).then(resp => {
          this.items = { ...resp.data.data };
          this.isLoading = false;
        });
      }
    }
  },

  methods: {
    getName(slug) {
      const n = this.m?.chapters
        .filter(e => {
          return e.slug === slug ? e.name : false;
        })
        .pop();
      this.current = n;
      return n.name;
    },

    getUrl(chapter) {
      if (chapter) {
        return `/historical-content/${this.type}/${this.slug}/${chapter}`;
      } else {
        return `/historical-content/${this.type}/${this.slug}`;
      }
    }
  },

  mounted() {
    console.log('## hc-by-slug mounted: ', this.slug);
    this.isLoading = true;
    API.get(`/get-historical-content/${this.slug}`).then(resp => {
      this.m = { ...resp.data.data };
      this.isLoading = false;

      // TODO 4-dec-2022 this code was active but giving errors
      // not sure what this was supposed to do,
      // neither this.ch and this.c are referened in the html

      // console.log("a: ", this.chapter)
      // if (this.chapter) {
      //   this.ch = this.c.items[this.chapter];
      // }
    });

    // TODO 4-dec-2022 this code was already commented out
    // the /get-historical-chapter call does not exist in the API
    // if (this.chapter) {
    //   console.log("GET CHAPTER INFO FOR: ", this.chapter)
    //   API.post('/get-historical-chapter', {
    //     slug: this.chapter
    //   })
    //   .then((resp) => {
    //     console.log("resp ", resp)
    //     this.ch = {...resp.data.data}
    //     this.isLoading = false
    //   })
    // }
  },

  data() {
    return {
      isLoading: true,
      current: {},
      m: {}, // current module (by slug)
      items: []
    };
  }
};
</script>

<style scoped>
a:hover,
a:focus {
  color: #0a4;
}
a:hover {
  cursor: pointer;
}

a,
.panel a {
  color: #0a4;
  background: transparent;
  text-decoration: none;
  line-height: inherit;
  border-bottom: none;
}
</style>
