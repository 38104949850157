<template>
  <!-- <q-header reveal> -->
  <header :class="{ hover: hover }">
    <!-- <div v-if="showWarning" class="warning">
      <center>
        <h4 class="flex-center warning">
          <span style="background-color: #fad201; color: black"
            >&nbsp;NOTICE: THIS IS THE TEST SERVER&nbsp;</span
          >
        </h4>
      </center>
    </div> -->

    <div
      id="menu-full-width"
      class="row justify-between"
      @mouseleave="hover = false"
    >
      <div class="menu-bar" :class="{ hover: hover, dark: dark }" />

      <!-- MAIN -->

      <nav
        role="navigation"
        class="menu row"
        :class="[
          {
            hover: hover,
            dark: dark,
          },
        ]"
      >
        <router-link custom v-slot="{ href, navigate }" to="/">
          <a
            class="nav-block home"
            :class="'bg-' + bgColor"
            :href="href"
            :title="$store.state.version"
            @click="navigate"
            @mouseenter="hover = true"
          >
            <svg
              class="logo svg"
              viewBox="0 0 100 100"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                d="M61.6031006,14 L61.6031006,32.425024 L38.3968994,32.425024 L38.3968994,14 L25,14 L25,82.5965153 L38.1979695,82.5965153 L41.2642338,65.9755796 L41.2162162,65.9755796 L38.5958293,57.8537522 L38.5958293,57.8537522 C37.3324791,53.0708142 39.1888852,48.0078042 43.2445736,45.1751478 C47.3002619,42.3424914 52.6928785,42.3424914 56.7485668,45.1751478 C60.8042551,48.0078042 62.6606612,53.0708142 61.397311,57.8537522 L61.397311,57.8537522 L58.7906434,65.9755796 L58.7357662,65.9755796 L61.8020305,82.5965153 L75,82.5965153 L75,14 L61.6031006,14 Z"
              ></path>
            </svg>
          </a>
        </router-link>

        <div class="row no-wrap" :class="hover ? 'bg-' + bgColor : ''">
          <router-link
            v-for="(link, i) in links"
            :key="i"
            custom
            v-slot="{ href, navigate, isActive }"
            :to="link.to"
          >
            <a
              class="nav-block"
              :class="[
                (dark ? 'text-' : 'bg-') + link.color,
                { active: isActive },
              ]"
              @click="navigate"
              :href="href"
            >
              <i :class="link.icon" />
              <label>{{ link.label }}</label>
            </a>
          </router-link>
        </div>
      </nav>

      <!-- USER -->

      <div id="login" class="row items-center" :class="{ hover: hover }">
        <span v-if="isAuthenticated">
          <!-- show name and logout -->
          <LogoutButton :dark="dark" />
          <q-btn
            flat
            no-caps
            dense
            size="13px"
            class="q-px-sm"
            to="/my/profile"
            icon="far fa-cog"
            :label="displayname"
            :color="dark ? 'white' : 'dark-gray'"
          >
            <!-- possible conflict with beta-badge and future notifications? -->
            <q-badge
              v-if="showBeta"
              color="primay"
              floating
              style="right: -10px"
              >ß</q-badge
            >
          </q-btn>
        </span>
        <span v-else>
          <!-- NOT authenticated; show login/register buttons -->
          <LoginButton :dark="dark" />
          <RegisterButton :dark="dark" />
        </span>

        <q-dialog class="welcome" :value="showWelcome">
          <q-card style="width: 500px">
            <q-card-section class="q-gutter-md">
              <div class="row">
                <div class="col-shrink q-pr-md">
                  <svg
                    class="logo svg"
                    viewBox="0 0 100 100"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="width: 100px; padding: 0; background-color:white"
                  >
                    <path
                      d="M61.6031006,14 L61.6031006,32.425024 L38.3968994,32.425024 L38.3968994,14 L25,14 L25,82.5965153 L38.1979695,82.5965153 L41.2642338,65.9755796 L41.2162162,65.9755796 L38.5958293,57.8537522 L38.5958293,57.8537522 C37.3324791,53.0708142 39.1888852,48.0078042 43.2445736,45.1751478 C47.3002619,42.3424914 52.6928785,42.3424914 56.7485668,45.1751478 C60.8042551,48.0078042 62.6606612,53.0708142 61.397311,57.8537522 L61.397311,57.8537522 L58.7906434,65.9755796 L58.7357662,65.9755796 L61.8020305,82.5965153 L75,82.5965153 L75,14 L61.6031006,14 Z"
                      fill="#00AAEE"
                    ></path>
                  </svg>
                </div>
                <div class="col">
                  <h5 class="title">Welcome to Historiana</h5>
                  <h5>{{ displayname }}</h5>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-dialog>

        <!-- DO NOT REMOVE ----->
        <div
          v-show="this.$store.state.feedback"
          id="feedback"
          :class="this.$store.state.feedback.status"
        >
          <h3>{{ this.$store.state.feedback.msg }}</h3>
        </div>
      </div>
    </div>
  </header>
  <!-- </q-header> -->
</template>

<script>
import { API } from "../tic";
// still used for the bad password animation
import LoginButton from "@/components/LoginButton";
import LogoutButton from "@/components/LogoutButton";
import RegisterButton from "@/components/RegisterButton";
import { mapGetters } from "vuex";
//import { router } from "../main.js"

export default {
  name: "HiHeader",

  components: {
    LoginButton,
    LogoutButton,
    RegisterButton,
  },

  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "transparent",
    },
  },

  data() {
    return {
      pages: [
        {
          to: "/historical-content",
          label: "Historical Content",
          icon: "fa fa-landmark",
          color: "plasticgroen",
        },
        {
          to: "/teaching-learning",
          label: "Teaching & Learning",
          icon: "fa fa-users",
          color: "blauwgroen",
        },
        // {
        //   to: "/narratives/new",
        //   label: "Narrative Builder",
        //   icon: "fa fa-books",
        //   color: "primary",
        // },
        {
          to: "/builder",
          label: "E-Activity Builder",
          icon: "fa fa-pencil",
          color: "primary",
        },
        {
          to: "/my",
          label: "My Historiana",
          icon: "fa fa-user",
          color: "primary",
        },
        {
          to: "/about",
          label: "About",
          icon: "fa fa-info",
          color: "cyaan",
        },
      ],
      adminPages: [
        {
          to: "/partners",
          label: "Partners",
          icon: "fa fa-handshake-alt",
          color: "korenblauw",
        },
        {
          to: "/admin",
          label: "Site Admin",
          icon: "fa fa-user-cog",
          color: "primary",
        },
      ],
      hover: false,
      showRegister: false,
      showReset: false,
      loggedIn: false,
    };
  },

  computed: {
    ...mapGetters("user", [
      "displayname",
      "isAuthenticated",
      "showBeta",
      "isAdmin",
    ]),
    ...mapGetters(["showWelcome"]),
    ...mapGetters({ store_showLogin: "showLogin" }),

    showWarning() {
      return document.location.hostname === "test.historiana.eu";
    },

    showLogin: {
      get() {
        return this.store_showLogin;
      },
      set(newName) {
        return newName;
      },
    },

    links() {
      return this.isAdmin ? this.pages.concat(this.adminPages) : this.pages;
    },
  },

  methods: {
    doShowWelcome() {
      this.$store.commit("showWelcome", true);
    },

    doShowLogin() {
      this.$store.commit("showLogin", true);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~quasar-variables';

/* new menu */
header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2000;
  padding-bottom: 2em;

  &:not(.hover) {
    pointer-events: none;
  }
}

#menu-full-width {
  max-width: none;
  margin: 0 50px;
}

.menu {
  width: auto;
  max-width: none !important;

  a {
    display: block;
    border: 1px solid #d7d7d7;
    border-right-width: 0;
    transition: transform $menu-transition, background-color $menu-transition;
    pointer-events: all;
  }

  a:not(:hover):not(.active):not(.home) {
    color: $dark-gray !important;
    background-color: $white !important;
  }

  a:last-child {
    border-right-width: 1px;
  }

  a.home {
    border: none;
    background-color: transparent;
    transition: transform $menu-transition;
    transform-origin: 0 0;
  }
}

/*
   path
     transition: fill $menu-transition;
*/
a:hover, a.active {
  color: $white;
}

&.dark {
  a {
    border-color: $white;
  }

  a:not(:hover):not(.active):not(.home) {
    color: $white !important;
    background-color: transparent !important;
  }

  a:not(.home):hover {
    background-color: $white !important;
  }
}

.page-scrolled .menu:not(.hover) a.home {
  transform: scale(0.5);
}

.page-scrolled {
  .menu:not(.hover) a:not(.home), #login:not(.hover), .menu-bar:not(.hover) {
    transform: translateY(-100%);
  }

  a.home path {
    fill: $white !important;
  }
}

.is-homepage {
  a.home path {
    fill: $cyaan;
  }

  .page-scrolled a.home {
    background-color: $cyaan !important;
  }
}

.is-historical-content {
  a.home path {
    fill: $plasticgroen;
  }

  .page-scrolled a.home {
    background-color: $plasticgroen !important;
  }
}

.is-narrative-view {
  a.home path {
    fill: $white;
  }

  .page-scrolled a.home {
    background-color: $plasticgroen !important;
  }
}

.is-teaching-learning {
  a.home path {
    fill: $blauwgroen;
  }

  .page-scrolled a.home {
    background-color: $blauwgroen !important;
  }
}

.is-my-historiana, .is-activity-builder {
  a.home path {
    fill: $primary;
  }

  .page-scrolled a.home {
    background-color: $primary !important;
  }
}

.is-partner-page {
  a.home path {
    fill: $korenblauw;
  }

  .page-scrolled a.home {
    background-color: $korenblauw !important;
  }
}

.menu-bar {
  position: absolute;
  inset: 0;
  height: 50px;
  transition: transform $menu-transition;
  transform: translateY(-100%);
  z-index: -1;
}

.menu-bar {
  background-color: $light-gray;

  &.dark {
    background-color: alpha($black, 0.1);
  }
}

.page-scrolled {
  .menu-bar.hover {
    transform: none;
  }
}

#login {
  height: 50px;
  pointer-events: all;
  transition: transform $menu-transition;
  z-index: 10;
}

@media (max-width: 1110px) {
  #menu-full-width >>> .q-btn__content {
    i.on-left {
      margin-right: 0;
    }

    span.block {
      display: none !important;
    }
  }
}

@media (max-width: 1010px) {
  #menu-full-width {
    margin: 0 25px 0 0;
  }
}

.hi-notify {
  // border: 3px solid white;
  width: 24em;
  height: 8em;
  font-size: 20px !important;
  font-weight: bold;
  text-align: center;
}

.q-header .row, .q-footer .row, section > .row {
  max-width: 1240px;
}

/* see for other colors https://gitlab.com/webtic/Historiana/snippets/1656948 */
#feedback {
  z-index: 100;
  position: fixed;
  max-width: 24em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  box-shadow: $shadow-6dp;
  /*
    -webkit-animation: slideInDown 2s ease;
    -moz-animation:    slideInDown 2s ease;
    -o-animation:      slideInDown 2s ease;
    animation:         slideInDown 2s ease;
  */
}

#feedback.ok {
  color: $white;
  background-color: $plasticgroen;
  /* background-color:rgba(0,170,68,0.8); */
  padding: 2em;
}

#feedback.nok {
  color: $white;
  background-color: $oranje;
  /* background-color:rgba(255,102,0,0.8); */
  padding: 2em;
}

#feedback.info {
  color: $black;
  background-color: $white;
  /* background-color:rgba(255,255,255,0.8); */
  padding: 2em;
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

/* the el-dialog containing the round welcome */
.welcome {
  background: transparent;
  text-align: center;
  border: 0 !important;
}

.title {
  color: #0ae;
  font-weight: 600;
}

/* the round welcome ball */
#welcome_modal {
  border-radius: 50%;
  height: 400px;
  width: 400px;
  text-align: center;
  background-color: rgba(254, 254, 254, 1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hi-notify {
  // border: 3px solid white;
  width: 24em;
  height: 8em;
  font-size: 20px !important;
  font-weight: bold;
  text-align: center;
}

div.warning {
  font-weight: bold;
  color: white;
  display: block;
  background: repeating-linear-gradient(
    45deg,
    #fad201,
    #fad201 10px,
    #000 10px,
    #000 20px
  );
}
</style>
