<!--
  implementation of global tags
-->

<template>
  <section id="hiFilter">
    <div class="row q-mx-auto items-center">
      <!-- <div class="col-shrink q-px-0 q-pr-md">
        <h4>What are you looking for?</h4>
      </div> -->

      <div class="col" v-if="context === 'hc'">
        <q-select
          multiple
          use-chips
          behavior="dialog"
          color="grey-12"
          label="Historical periods"
          v-model="periods"
          :options="options_periods"
          @input="updateTags"
        >
          <!-- prefix icon -->
          <!-- <template v-slot:prepend>
            <i class="fa fa-list"></i>
          </template> -->
          <!-- <template v-slot:selected></template> -->

          <template
            v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }"
          >
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>

      <div class="col" v-if="context === 'hc'">
        <q-select
          multiple
          use-chips
          behavior="dialog"
          color="grey-12"
          label="Themes"
          v-model="themes"
          :options="options_themes"
          @input="updateTags"
        >
          <template
            v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }"
          >
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>

      <div class="col" v-if="context === 'hc'">
        <q-select
          multiple
          use-chips
          behavior="dialog"
          color="grey-12"
          label="Source types"
          v-model="sourcetypes"
          :options="options_sourcetypes"
          @input="updateTags"
        >
          <template
            v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }"
          >
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>

      <div class="col" v-if="context === 'tl'">
        <q-select
          multiple
          use-chips
          behavior="dialog"
          color="grey-12"
          label="Historical Thinking"
          v-model="tl_thinking"
          :options="tl_options_thinking"
          @input="updateTags"
        >
          <template
            v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }"
          >
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>

      <div class="col" v-if="context === 'tl'">
        <q-select
          multiple
          use-chips
          behavior="dialog"
          color="grey-12"
          label="Teaching Methods"
          v-model="tl_methods"
          :options="tl_options_methods"
          @input="updateTags"
        >
          <template
            v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }"
          >
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>

      <div class="col" v-if="context === 'tl'">
        <q-select
          multiple
          use-chips
          behavior="dialog"
          color="grey-12"
          label="Teaching Challenges"
          v-model="tl_challenges"
          :options="tl_options_challenges"
          @input="updateTags"
        >
          <template
            v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }"
          >
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
    </div>
  </section>
</template>
<script>
import { API } from "@/tic";
import { isThisISOWeek } from "date-fns";

export default {
  name: "HiTags",

  props: {
    context: {
      type: String,
      default: "",
    },

    // tags can be activated by clicking a tag on a card
    // to reflect state we need to pass it here.
    showactiveTags: [],
  },

  mounted: function () {
    //console.log("MOUNT hiTags");
    this.loadData();
  },

  data() {
    return {
      query: "",
      periods: [],
      sourcetypes: [],
      themes: [],
      activeTags: [],

      options_periods: [],
      options_sourcetypes: ["s1"],
      options_themes: ["s1"],

      tl_thinking: [],
      tl_methods: [],
      tl_challenges: [],

      tl_options_thinking: [],
      tl_options_methods: ["s1"],
      tl_options_challenges: ["s1"],
    };
  },

  watch: {
    showactiveTags: function () {
      // als deze is aangepast heeft de parent iets gedaan en dus heeft
      // de gebruiker op een q-chip in een Card geklikt.
      // we reflecteren die stage in de pulldown
      // dat moet wat omslachtig omdat we niet weten in welke pulldown
      // de gekozen uuid zit, het zou zelfs kunnen dat er op een Tag van T&L
      // is geklikt terwijl we in HC zitten.

      this.showactiveTags.map((tag) => {
        if (this.periods.filter((e) => e.value === tag).length === 0) {
          if (this.options_periods.map((e) => e.value).includes(tag)) {
            this.periods.push(this.options_periods.filter((e) => e.value === tag)[0]);
          }
        }
      });

      this.showactiveTags.map((tag) => {
        if (this.sourcetypes.filter((e) => e.value === tag).length === 0) {
          if (this.options_sourcetypes.map((e) => e.value).includes(tag)) {
            this.sourcetypes.push(
              this.options_sourcetypes.filter((e) => e.value === tag)[0]
            );
          }
        }
      });

      this.showactiveTags.map((tag) => {
        if (this.themes.filter((e) => e.value === tag).length === 0) {
          if (this.options_themes.map((e) => e.value).includes(tag)) {
            this.themes.push(this.options_themes.filter((e) => e.value === tag)[0]);
          }
        }
      });

      this.showactiveTags.map((tag) => {
        if (this.tl_thinking.filter((e) => e.value === tag).length === 0) {
          if (this.tl_options_thinking.map((e) => e.value).includes(tag)) {
            this.tl_thinking.push(
              this.tl_options_thinking.filter((e) => e.value === tag)[0]
            );
          }
        }
      });

      this.showactiveTags.map((tag) => {
        if (this.tl_methods.filter((e) => e.value === tag).length === 0) {
          if (this.tl_options_methods.map((e) => e.value).includes(tag)) {
            this.tl_methods.push(
              this.tl_options_methods.filter((e) => e.value === tag)[0]
            );
          }
        }
      });

      this.showactiveTags.map((tag) => {
        if (this.tl_challenges.filter((e) => e.value === tag).length === 0) {
          if (this.tl_options_challenges.map((e) => e.value).includes(tag)) {
            this.tl_challenges.push(
              this.tl_options_challenges.filter((e) => e.value === tag)[0]
            );
          }
        }
      });
    },
  },

  methods: {
    updateTags() {
      // we only need the uuid from here
      this.activeTags = [
        ...this.periods.map((e) => e.value),
        ...this.sourcetypes.map((e) => e.value),
        ...this.themes.map((e) => e.value),
        ...this.tl_thinking.map((e) => e.value),
        ...this.tl_methods.map((e) => e.value),
        ...this.tl_challenges.map((e) => e.value),
      ];

      // send to parent (Historical Content and/or Teaching & Learning indexes)
      console.log("hiTags:updateTags");
      this.$emit("updateTags", this.activeTags);
    },

    handleInput(event) {
      console.log("handleinput ", event);
      this.$emit("input", event);
    },

    loadData() {
      console.log("loadData!", this.context);

      if (this.context === "hc") {
        API.get("/tags/get/Historical Periods").then((r) => {
          this.options_periods = [...r.data.data.children];
        });

        API.get("/tags/get/Themes").then((r) => {
          this.options_themes = [...r.data.data.children];
        });

        API.get("/tags/get/Source Types").then((r) => {
          this.options_sourcetypes = [...r.data.data.children];
        });
      }

      if (this.context === "tl") {
        API.get("/tags/get/Historical Thinking").then((r) => {
          this.tl_options_thinking = [...r.data.data.children];
        });

        API.get("/tags/get/Teaching Methods").then((r) => {
          this.tl_options_methods = [...r.data.data.children];
        });

        API.get("/tags/get/Teaching Challenges").then((r) => {
          this.tl_options_challenges = [...r.data.data.children];
        });
      }
    },
  },
};
</script>

<style>
.ellipsis {
  color: black;
  text-transform: uppercase;
}

.q-chip__icon--remove {
  color: #222 !important;
}
</style>
