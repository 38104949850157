<template>
  <div id="workbench" class="ea-canvas" @mouseleave="onMouseLeave">

    <div style="position:fixed; left:0; top:0; color:magenta; background-color:rgba(0,0,0,.7); z-index:1000; pointer-events:none">
      <!-- {{ sources.map(s => ({ id:s.uuid, desc:s.description })) }} -->
      <!-- {{ activeNode }} -->
      <br>
    </div>

    <discovery-zoomer
      id="zoomer"
      ref="zoomer"
      :controls="nodes.length || addSources.length? true:false"
      :min-scale="minScale"
      :nodes="nodes"
      @update="zoomerUpdate"
      @click="onClick"
      @addNode="newNode"
    >
      <!-- <img src="./grid-dev-2000.svg"/> -->
    </discovery-zoomer>

    <!-- intro -->
    <h5
      v-if="!nodes.length && !addSources.length"
      class="guide"
    >
      <span v-if="mode!='teacher'">Please add sources in teacher mode first</span>
      <span v-else>Start creating your Discovery Map by adding some <a href="/add-media" class="text-hardroze" @click.prevent="$emit('addMedia')">sources</a></span>
    </h5>

    <!-- <q-btn no-caps xcolor="primary" @click.stop="" label="Add text Node" icon="fa fa-plus"/> -->

    <!-- debugging -->
<!--
    <div class="debug-line-vertical"/>
    <div class="debug-line-horizontal"/>
 -->

    <!-- nodes map -->
    <div
      id="map"
      ref="nodesmap"
      :class="{
        active:activeMap,
        drag:drag,
        add:add || addConnection,
        edit:edit
      }"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
      @mousemove="onMouseMove"
      @touchstart="onTouchStart"
      @touchend="onTouchEnd"
      @touchmove="onTouchMove"
      @wheel="onWheel"
    >

      <!-- nodes -->
      <section ref="nodes">
        <div
          v-for="(n,i) in nodes"
          class="node"
          :class="[{
            active:n.active,
            edit:n.edit,
            draggable:n.draggable,
            selected:n.selected,
            covered:n.covered,
            open:n.open,
            start:n.start
          }, n.l]"
          :key="i"
          :style="{
            left:nodeLeft(n.x) + 'px',
            top:nodeTop(n.y) + 'px',
            width:nodeWidth(n.w)+ 'px',
            height:n.edit? 'auto':calcNodeHeight(n)+ 'px',
            padding:nodePadding +'px',
            backgroundColor:n.covered? getShadeColor(n.shade):'#fefefe',
          }"
        >
          <!-- <div style="position:absolute; left:0; top:-26px; font-size:14px; opacity:.8">layout={{ n.l }}</div> -->
          <div class="sources" :ref="n.id+'Sources'" :style="{ height:calcNodeHeight(n,true) +'px'}">
            <div
              class="source"
              v-for="(s,index) in nodeSources(n.id)"
              :key="s.uuid"
              @click="selectedSource=index+1;showSourceDetail=true"
            >
              <img :src="s.url" @mousedown.prevent>
              <q-btn
                v-if="n.active"
                round
                :icon="'fa fa-'+(n.edit? 'pencil':'info')"
                size="10px"
                class="action"
              />
            </div>
          </div>

          <!-- add sources button -->
          <q-btn
            flat
            v-if="n.edit"
            color="primary"
            no-caps
            icon="fal fa-images"
            label="Add sources"
            class="full-width"
            :disabled="activeNodeSources.length==maxSources"
            @click="$emit('addMedia')"
          />

          <!-- node text -->
          <q-input
            ref="node_title"
            v-if="n.edit"
            v-model="n.text"
            type="textarea"
            label="Node title"
            filled
            class="caption-input q-my-xs"
            maxlength="40"
            rows="2"
            counter
            :color="nodeColorNames[n.c]"
            @wheel.stop
          />
          <div
            v-else-if="n.text"
            class="caption"
            :style="captionStyle(n)"
          >{{ n.text }}
          </div>

          <!-- node color picker -->
          <div
            v-if="n.edit"
            class="colors row justify-center"
          >
            <span
              v-for="(c,cIndex) in nodeColors"
              class="color"
              :class="{ active:n.c==cIndex }"
              :key="c"
              :style="{ backgroundColor:c }"
              @click="setNodeColor(cIndex)"
            />
          </div>

          <!-- remove node button -->
          <q-btn
            v-if="n.edit"
            flat
            round
            dense
            class="float-right"
            icon="fas fa-trash"
            color="korenblauw"
            size="12px"
            @click.stop="removeNode(n.id)"
          />

          <!-- discover indicator -->
          <div
            v-if="(mode=='student' || mode=='view') && n.open"
            class="discover row fit justify-center items-center text-primary"
            :style="{ fontSize:labelSize +'px' }"
            :class="{ discovered:isDiscovered(n.id) }"
            @mousedown="passEvent"
            @mouseup="passEvent"
            @touchstart="passEvent"
            @touchend="passEvent"
          >
            <q-btn
              no-caps
              dense
              :flat="!n.start"
              color="hardroze"
              :size="labelSize +'px'"
              :style="{ padding:labelSize/4 +'px '+ labelSize/2 +'px', opacity:.8 }"
            ><i class="far fa-eye" :style="{ marginRight:labelSize/2 +'px' }"/> Discover
            </q-btn>
          </div>

          <!-- draggable indicator -->
          <div
            v-if="mode=='teacher' && n.active && !n.edit"
            class="controls draggable row fit justify-center items-center"
          >
            <i class="drag draggable fal fa-arrows" :style="{ fontSize:dragSize +'px'}"/>
          </div>

          <!-- edit node button -->
          <div
            class="edit"
            v-if="showControls && n.active"
          >
            <q-toggle
              icon="fa fa-pencil"
              dense
              :dark="n.l!='text'"
              :value="n.edit"
              :style="editButtonStyle(n)"
              @mousedown.stop
              @touchstart.stop
              @input="editNode"
            />
          </div>

          <!-- add connection button -->
          <q-btn
            v-if="showControls && n.active && !n.edit"
            round
            class="add"
            :size="(buttonSize)+'px'"
            :style="{ top:n.l=='text'? '100%':buttonPosition(n) +'px' }"
            :color="nodeColorNames[n.c]"
            @mousedown.stop
            @touchstart.stop
            @click="newConnection"
          >
            <q-icon>
              <svg width="34px" height="25px" viewBox="0 0 34 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <path d="M30.0769231,17.0052678 C32.2435786,17.0052678 34,18.7616892 34,20.9283447 C34,23.0950003 32.2435786,24.8514216 30.0769231,24.8514216 C28.3689705,24.8514216 26.9159379,23.7599799 26.3772468,22.2365181 L7.62275325,22.2365181 C7.08406215,23.7599799 5.63102947,24.8514216 3.92307692,24.8514216 C1.75642137,24.8514216 0,23.0950003 0,20.9283447 C0,18.7616892 1.75642137,17.0052678 3.92307692,17.0052678 C5.63102947,17.0052678 7.08406215,18.0967096 7.62275325,19.6201714 L26.3772468,19.6201714 C26.9159379,18.0967096 28.3689705,17.0052678 30.0769231,17.0052678 Z M17.4903834,0 C18.0319483,0 18.4711503,0.439202068 18.4711503,0.980766892 L18.4711503,0.980766892 L18.4711503,5.39421791 L22.8846014,5.39421791 C23.4261662,5.39421791 23.8653682,5.83341998 23.8653682,6.3749848 L23.8653682,6.3749848 L23.8653682,7.35575169 C23.8653682,7.89731652 23.4261662,8.33651859 22.8846014,8.33651859 L22.8846014,8.33651859 L18.4711503,8.33651859 L18.4711503,12.7499696 C18.4711503,13.2915344 18.0319483,13.7307365 17.4903834,13.7307365 L17.4903834,13.7307365 L16.5096166,13.7307365 C15.9680517,13.7307365 15.5288497,13.2915344 15.5288497,12.7499696 L15.5288497,12.7499696 L15.5288497,8.33651859 L11.1153986,8.33651859 C10.5738338,8.33651859 10.1346318,7.89731652 10.1346318,7.35575169 L10.1346318,7.35575169 L10.1346318,6.3749848 C10.1346318,5.83341998 10.5738338,5.39421791 11.1153986,5.39421791 L11.1153986,5.39421791 L15.5288497,5.39421791 L15.5288497,0.980766892 C15.5288497,0.439202068 15.9680517,0 16.5096166,0 L16.5096166,0 Z"/>
              </svg>
            </q-icon>
          </q-btn>

        </div>
      </section>

      <!-- connections -->
      <svg
        v-if="nodes.length"
        id="connections"
        class="fit"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="pointer-events:none;"
      >
        <defs>
          <!-- circle markers -->
          <marker
            v-for="(color,i) in nodeColors.concat(['#d0d0d0'])"
            :key="`dot_${i}`"
            :id="`dot_${i}`"
            markerWidth="4"
            markerHeight="4"
            refX="2"
            refY="2"
          >
            <circle cx="2" cy="2" r="1.7" stroke="none" :fill="color"/>
          </marker>

          <!-- arrow markers -->
          <marker
            v-for="(color,i) in nodeColors"
            :key="`arrow_${i}`"
            :id="`arrow_${i}`"
            markerWidth="5"
            markerHeight="5"
            refX="2.5"
            refY="2.5"
            orient="auto-start-reverse"
          >
            <path d="M 0 0 L 5 2.5 L 0 5 z" :fill="color"/>
          </marker>
        </defs>

        <!-- connection lines -->
        <g ref="lines" class="lines">
          <path
            v-for="(c,i) in connections"
            :key="`c_${i}`"
            :class="{ active:c.active, covered:c.covered }"
            :d="getPath(c,i)"
            :stroke="c.covered? connectionCoverColor:nodeColors[c.c]"
            :stroke-width="strokeWidth"
            :stroke-dasharray="c.dashed"
            stroke-linecap="round"
            fill="none"
            :marker-start="'url(#dot_'+(c.covered? 5:c.c)+')'"
            :marker-end="'url(#'+(mode=='teacher'? 'arrow_':'dot_')+(c.covered? 5:c.c)+')'"
          />
        </g>
      </svg>

      <!-- connection labels -->
      <div
        class="label"
        v-for="(c,i) in connections"
        :key="`l_${i}`"
        :class="{ edit:c==activeConnection, active:c.active, covered:c.covered }"
        :style="getLabelStyle(i,c)"
        @mousedown.stop="onLabelDown(c,...arguments)"
        @mouseup.stop="onLabelUp"
      >
        <div class="label-color" :style="{ backgroundColor:c.covered? connectionCoverColor:nodeColors[c.c] }"/>

        <!-- edit connection UI -->
        <div v-if="c==activeConnection" @mousedown.stop>
          <q-input
            ref="connection_label"
            v-model="c.text"
            filled
            label="Connection title"
            style="width:200px"
            maxlength="25"
            counter
            :color="nodeColorNames[c.c]"
            @keyup.enter="exitEditConnection"
          />
          <div class="colors row justify-center">
            <span
              v-for="(color,cIndex) in nodeColors"
              class="color"
              :class="{ active:c.c==cIndex }"
              :key="color"
              :style="{ backgroundColor:color }"
              @click="setConnectionColor(cIndex)"
            />
          <q-btn
            flat
            round
            dense
            class="swap"
            icon="far fa-exchange-alt"
            :color="nodeColorNames[c.c]"
            size="12px"
            @click.stop="swapConnectionDirection(c)"
          ><q-tooltip>Swap direction</q-tooltip>
          </q-btn>
          <q-btn
            flat
            round
            dense
            class="remove"
            icon="fas fa-trash"
            color="korenblauw"
            size="12px"
            @click.stop="removeConnection(c)"
          />
          </div>
        </div>

        <!-- text label-->
        <span v-else :class="{ covered:c.covered }">{{ c.text || 'Untitled connection' }}</span>
      </div>

      <!-- ghost node -->
      <div
        ref="ghost"
        v-if="add && ghostVisible"
        class="new node"
        :style="{
          left:mx + 'px',
          top:my + 'px',
          width:nodeWidth(nw/1000)+ 'px',
        }"
      >
        <div v-if="addSources.length" class="sources fit" @mousedown.prevent>
          <img ref="ghostimage" class="fit" :src="addSources[0].url">
        </div>
        <div
          ref="caption"
          class="caption"
          :style="{ paddingTop:labelSize/4 +'px', fontSize:labelSize +'px' }"
        >Place new node <span v-if="addMultiple" class="multiple">{{ addMultiple-(addSources.length-1) }}/{{ addMultiple }}</span>
        </div>

      </div>

      <!-- connection cursor -->
      <div
        v-if="addConnection"
        class="connection-area"
        :style="{ left:mx+'px', top:my+'px', backgroundColor:nodeColors[addConnection.c] }"
      />

    </div>

    <!-- sources detail display -->
    <div id="sources"
      v-if="activeNodeSources.length>=1"
      :class="{ collapsed:!showSourceDetail }"
      @mousedown.stop
      @mouseup.stop
      @touchend.stop
    >
      <div id="sources-header" class="row justify-end items-center">
        <q-pagination
          dark
          v-if="activeNodeSources.length>1"
          v-model="selectedSource"
          color="white"
          :max="activeNodeSources.length"
          :input="true"
          :boundary-links="false"
        />
      </div>

      <div id="source-display" v-if="activeNodeSource">

        <!-- video player -->
        <div v-if="video_supported.includes(activeNodeSource.type)" class="row content-center fit">
          <hi-video ref="video" :thumb="activeNodeSource.thumb_url" :url="activeNodeSource.playback" style="width:100%"/>
        </div>

        <!-- image zoomer -->
        <v-zoomer v-else
          controls
          ref="sourceZoomer"
          class="fit"
          background-color="rgba(0,0,0,.5)"
          :zoom="1"
          :zoomed.sync="showSourceZoomed">
          <img :src="activeNodeSource.url">
        </v-zoomer>

        <!-- remove source -->
        <q-btn
          v-if="mode=='teacher'"
          round
          size="sm"
          icon="fas fa-trash"
          class="remove-source"
          @click="removeNodeSource"
        />

      </div>

      <!-- source info panel -->
      <source-info
        show
        fixed
        dark
        :mode="mode"
        :source.sync="activeNodeSource"
        @store="$emit('updateSource')"
      />

      <!-- sources detail close -->
      <q-btn
        style="position:absolute; right:8px; top:8px; z-index:1000"
        flat
        round
        size="sm"
        color="white"
        icon="fa fa-times"
        @click="showSourceDetail=!showSourceDetail"
      />
    </div>

    <!-- instructions -->
    <block-instructions
      v-model="instructions"
      :mode="mode"
      :show="showInstructions"
      @close="closeInstructions"
    />

  </div>
</template>

<script>
import { clone } from '../../tic';
import { mapGetters } from 'vuex';
import DiscoveryZoomer from './components/discovery-zoomer';
import BlockInstructions from '../BlockInstructions'
import SourceInfo from '../../components/SourceInfo';
import HiVideo from '../../components/hiVideo';

export default {
  name: 'BBdiscovering',

  components: {
    DiscoveryZoomer,
    SourceInfo,
    BlockInstructions,
    HiVideo
  },

  props: {
    blockId: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'student'
    },
    view: {
      type: String,
      default: 'view'
    },
    showMedia: { //medialib visibility
      type: Boolean,
      default:false
    },
    showInstructions: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      nodeColors:[
        '#EEBB00',
        '#CC0000',
        '#00AAEE',
        '#55BB00',
        '#AA0099'
      ],
      nodeColorNames:[
        'eigeel',
        'rood',
        'cyaan',
        'grasgroen',
        'lichtpaars'
      ],
      nodeDefaultColor:2,
      nodeCoverColor:'#eaeaea',
      nodeLineLength:20,   //characters to fit on single line
      connectionCoverColor:'#dedede',
      maxShade:0,
      maxSources:3,
      showSourceDetail:false,
      showSourceZoomed:false,
      selectedSource:1,

      instructions:null,
      nodes:[],
      sources:[],
      addSources:[],
      connections:[],
      labels:[],
      discovered:[],

      //node add/edit/drag
      zoom:0,
      mx:0,
      my:0,
      activeNode:false,
      drag:false,
      edit:false,
      add:false,
      addMultiple:0,
      addConnection:false,
      activeConnection:false,
      ghostVisible:false,
      onLabel:false,
      nw: 176,  //default dimensions of a new node
      nh: 200,
      container: { x:0, y:0 },

      //values below are provided by zoomer component
      cx: 0, //center of zoomer image (includes pan transform)
      cy: 0,
      zw: 1000, //current display width/height of zoomer image
      zh: 1000,
    };
  },

  computed: {
    ...mapGetters('activity',['record','answer']),

    minScale() {
      return this.edit? 1:.25;
    },

    labelSize() {
      return Math.min(this.zoom,1) * 16
    },

    nodePadding() {
      return this.labelSize/2
    },

    dragSize() {
      return Math.max(20,this.labelSize*2)
    },

    buttonSize() {
      return Math.max(10,this.labelSize - 2)
    },

    removeButtonSize() {
      return Math.max(8,this.labelSize - 6)
    },

    strokeWidth() {
      return this.zoom<.6? 1:2;
    },

    showControls() {
      return this.mode=='teacher' && this.zoom>.5;
    },

    pointerNode() {
      return { x:this.nodeX(this.mx)+.1, y:this.nodeY(this.my)+.1, w:.2,h:.2}
    },

    activeNodeSources() {
      return this.activeNode? this.nodeSources(this.activeNode.id):[];
    },

    activeNodeSource() {
      return this.activeNodeSources[this.selectedSource-1];
    },

    activeMap() {
      return this.activeNode && this.mode!='student' && this.mode!='view';
    }

  },

  watch: {
    record: {
      //monitor global record update (e.g. login changes after block is mounted)
      immediate: true,
      deep: true,
      handler() {
        console.log('BB-discovery: record changed')

        if (this.record && this.record.nodes)
        {
          this.instructions = this.record.instructions;
          this.setActions();
          this.setNodes();
          this.setSources();
          this.setConnections();
        }
      }
    },

    edit: {
      immediate: true,
      handler: 'setAddSources'
    },

    addSources (sources) {
      //add nodes while addSources contains elements
      if (sources.length && this.mode=='teacher') this.newNode();
    },

    mode (m) {
      //handle teacher/student view toggle in BlockEditor
      this.setAddSources();
      this.setActions();
      this.resetSelection();

      if (m=='student')
      {
        if (this.activeConnection) this.exitEditConnection();
        this.setDiscovery();
      }
      else
      {
        this.$store.commit('activity/syncCurrentRecord',this.blockId)
        this.clearDiscovery();
      }

      this.$refs.zoomer.refresh();
    },

    showMedia (show) {
      //store connection changes
      if (show && this.activeConnection) this.exitEditConnection();

      //reset zoom level to 100% when adding source-nodes
      if (show==false && this.addSources.length) this.$refs.zoomer.fit(false,1)
    },

    showSourceDetail (show) {
      if (!show) this.$refs.sourceZoomer.reset();
    },

    selectedSource () {
      this.$refs.sourceZoomer.reset();
    }
  },

  mounted () {
    console.log('BB-discovery mounted')

    this.containerPosition();

    //listen for esc key
    window.addEventListener('keydown', this.exitNewNode);
  },

  destroyed() {
    window.removeEventListener('keydown', this.exitNewNode);
  },

  methods: {

    /* setup */

    setAddSources () {
      //mediaLib settings
      let options = { label:'Add to Map', button:'Add source nodes' }
      if (this.edit)
      {
        //add to current node, with maximum
        options = {
          show:false, //no button in sidebar
          label:'Add to Node',
          max:this.maxSources - this.activeNodeSources.length,
          props:{ node:this.activeNode.id },
          skip:this.activeNodeSources.map(s => s.uuid)
        }
      }
      this.$emit('addSources',this.mode=='teacher'? options:false);
    },

    setActions () {
      //instructions
      this.$emit('addInstructions',this.mode=='teacher'? true:this.instructions && this.instructions!='');
    },

    closeInstructions () {
      this.$emit('closeInstructions');
      if (this.mode=='teacher') this.store(true);
    },

    setNodes () {
      //deep copy nodes from store record & add properties for list view
      this.nodes = clone(this.record.nodes).map(n => {
        n.active = false;
        n.edit = false;
        n.draggable = false;
        n.selected = false;

        return n;
      });

      //continue editing (after sources change)
      if (this.edit)
      {
        //re-connect active node
        this.setActiveNode(this.nodes.find(n => n.id==this.activeNode.id));
        this.editNode();
        this.$nextTick(() => { this.setNodeLayout() });
      }
    },

    setSources () {
      //deep copy sources
      this.sources = clone(this.record.images);

      //get disconnected source(s), will trigger 'add node'
      this.addSources = this.sources.filter(s => !s.node);
      if (this.addSources.length>1) this.addMultiple = this.addSources.length;

      //fit map to view (when not editing or adding sources)
      if (!this.edit && !this.addSources.length)
      {
        this.$nextTick(() => { this.$refs.zoomer.fit(false) });
      }
    },

    setConnections () {
      this.connections = clone(this.record.connections);

      //continue setting up discovery mode when in student view
      if (this.mode=='student' || this.mode=='view') this.setDiscovery();
    },


    /* discovery (student view) */

    setDiscovery () {

      if (!this.nodes.length) return;

      //apply answer state if any
      if (this.answer) this.discovered = clone(this.answer.discovered || []);

      //cover all connections
      this.connections.forEach(c => { c.covered = true });

      //cover all nodes, except for starting nodes
      this.nodes.forEach(n => {

        //reset teacher view (for toggling in blockeditor)
        n.active = false;
        n.edit = false;
        n.draggable = false;

        //check if already opened in answer
        let discovered = this.discovered.find(v => v.id==n.id);
        if (discovered)
        {
          n.open = discovered.open;
          n.covered = discovered.covered;
        }
        else
        {
          //set default closed and covered
          if (!n.open) n.open = false;
          if (n.covered!==false) n.covered = true;
        }

        //check connections
        let incoming = this.connections.filter(c => c.to[0]==n.id);
        let outgoing = this.connections.filter(c => c.from[0]==n.id);

        if (!incoming.length && outgoing.length)
        {
          //auto-detected start node
          n.start = true;
          n.open = true;
          n.covered = false;
          n.shade = 0;

          //shade connected nodes
          this.setDiscoveryShade(n,0);
        }

        //reveal outgoing connections if in answer
        if (discovered && !discovered.covered) outgoing.forEach(c => c.covered = false);

        return n;
      })

      //check if a startnode was found, otherwise set first node as starting point
      if (!this.nodes.some(n => n.start))
      {
        const n = this.nodes[0];
        n.start = true;
        n.open = true;
        n.covered = false;
        n.shade = 0;
        this.setDiscoveryShade(n,0);
      }

      this.maxShade = Math.max(...this.nodes.map(n => n.shade===undefined? -1:n.shade));

      this.$nextTick(() => { this.$refs.zoomer.fit() });
    },

    setDiscoveryShade (node,shade) {

      //set shade for each (outgoing) connected node
      let nodes = this.getDiscoveryNext(node.id);

      nodes.forEach(n => {

        //set shade, only if not set already via other path
        if (n.shade===undefined)
        {
          n.shade = shade+1;

          //continue path
          this.setDiscoveryShade(n,shade+1);
        }
      })

    },

    getShadeColor (shade) {
      let v = 130;
      v += shade * (100/this.maxShade); //range 130 - 230
      return 'rgb('+v+','+v+','+v+')';
    },

    getDiscoveryNext (nodeId) {
      //get next node(s) for node
      let next = [];
      this.connections
        .filter(c => c.from[0]==nodeId)
        .forEach(c => {
          next.push(this.nodes.find(n => n.id==c.to[0]))
        });

      return next;
    },

    discoverNode (node) {

      //zoom to node & connected (outgoing) nodes
      let connected = this.getDiscoveryNext(node.id);

      if (!connected.length)
      {
        //if there's no outgoing connections (end of sequence), use incoming
        this.connections
          .filter(c => c.to[0]==node.id)
          .forEach(c => {
            connected.push(this.nodes.find(n => n.id==c.from[0]))
          });
      }

      //add active and previous node for zooming
      let current = [node];
      this.connections
        .filter(c => c.to[0]==node.id)
        .forEach(c => {
          let prev = this.nodes.find(n => n.id==c.from[0] && n.open);
          if (prev) current.push(prev);
        });

      this.$refs.zoomer.fit(current.concat(connected));

      //reveal node
      if (node.start || node.covered)
      {
        node.covered = false;

        //reveal connections
        this.connections
          .filter(c => c.from[0]==node.id)
          .forEach(c => { c.covered = false });

        //open connected nodes
        connected.forEach(n => { n.open = true })
      }

      //open disconnected nodes if all other are uncovered
      const disconnected = this.nodes.filter(n => n.shade===undefined && !n.open);
      if (disconnected.length)
      {
        const revealed = this.nodes.filter(n => n.covered==false);
        if (revealed.length + disconnected.length == this.nodes.length) disconnected.forEach(n => n.open = true);
      }

      //store answer
      this.store();
    },

    discoverConnection (c) {
      //click on connection label
      console.log('student click on connection',JSON.stringify(c))
      //->do we need this?
    },

    isDiscovered (id) {
      return this.answer && this.answer.discovered? this.answer.discovered.find(n => n.id==id && !n.covered):false;
    },

    clearDiscovery () {
      //remove discovery props
      this.setNodes();
      this.setConnections();
    },


    /* nodes */

    captionStyle (node) {
      //return node caption style
      const padding = this.labelSize/4;
      const textNode = node.l=='text';

      return {
        paddingTop:textNode? 0:padding +'px',
        height:this.labelSize * 1.25 * (node.text.length<this.nodeLineLength? 1:2) + padding +'px',
        fontSize:this.labelSize +'px',
        fontWeight:this.zoom>=1? 400:600,
        color:this.nodeColors[node.c],
      }
    },

    editButtonStyle (node) {
      //return positioning for edit toggle
      const out = node.l=='text' && !this.edit;
      return {
        margin:((out? -4:1)*this.buttonSize)+'px '+(out? 0:this.buttonSize)+'px 0 0'
      }
    },

    buttonPosition (node) {
      //return add-connection button position for node height and current scale
      return ((node.w * this.zw) - (this.nodePadding*2)) / node.a;
    },

    nodeSources (nodeId) {
      return this.sources.filter(s => s.node==nodeId)
    },

    newNode () {
      //switch to 'add node' (show ghostnode instead of pointer)
      this.resetSelection();
      this.add = true;
    },

    addNewNode() {
      this.add = false;
      this.ghostVisible = false;

      //generate new id: current highest id + 1
      let id = this.nodes.length? this.nodes.map(n => parseInt(n.id.substring(1))).reduce((a, b) => Math.max(a, b)) + 1:0;
      id = 'n'+id;

      //calculate height with source aspectratio //->TBD add support for multi-source layouts and text-only nodes
      const img = this.$refs.ghostimage;
      const h = ((this.nw - 16)/img.naturalWidth) * img.naturalHeight + 16; //exclude 8px margin on all sides

      //store aspect ratio for current sources layout //->TBD handle multiple sources
      let aspect = img.naturalWidth / img.naturalHeight;

      //insert new node at current position
      const node = {
        id:id,
        x:this.nodeX(this.mx),
        y:this.nodeY(this.my),
        w:this.nw/1000,
        a:aspect,
        h:h/1000,
        c:this.nodeDefaultColor,
        active:false,
        edit:false,
        draggable:false,
      }

      this.nodes.push(node);
      this.setActiveNode(node);

      //attach source if any
      if (this.addSources.length)
      {
        const source = this.addSources.shift(); //this will re-trigger newNode if there are sources left to be added
        source.node = id;

        //done adding?
        if (this.addSources.length==0)
        {
          //select first added node
          if (this.addMultiple>0)
          {
            this.resetSelection();
            this.setActiveNode(this.nodes[this.nodes.length-this.addMultiple]);
          }

          this.addMultiple = 0;
        }
      }
      else
      {
        //text node
        this.editNode()
      }

      //save changes
      this.store(true)
    },

    exitNewNode (e) {
      if (e===true || e.key=='Escape')
      {
        //exit 'add node'
        this.add = false;

        //cancel new connection
        if (this.addConnection) this.addNewConnection(true)

        //remove disconnected source, if any
        if (this.addSources.length)
        {
          const index = this.sources.indexOf(this.addSources.shift());
          this.sources.splice(index,1);
          this.store(true);
        }

      }
    },

    setActiveNode(node) {
      if ((this.mode=='student' || this.mode=='view') && !node.open) return; //un-opened node in student view

      node.active = true;
      node.draggable = this.mode=='teacher';
      this.activeNode = node;

      if (this.mode=='student' || this.mode=='view') this.discoverNode(node)
    },

    editNode () {
      const node = this.activeNode;

      if (node.edit) return this.exitEditNode()

      //swidth to edit mode
      node.draggable = false;
      node.edit = true;
      this.edit = true;

      //center node @ 1.5 zoom
      this.$refs.zoomer.fit([node],1.5);
    },

    setNodeColor (index) {
      this.activeNode.c = index;
      //focus node title input
      this.$nextTick(() => { this.$refs.node_title[0].focus() });
    },

    setNodeLayout () {
      //determine multiple sources layout
      const node = this.activeNode;

      //get natural image sizes for each source
      let aspects = [];
      this.$refs[node.id+'Sources'][0].childNodes.forEach(e => aspects.push(e.childNodes[0].naturalWidth / e.childNodes[0].naturalHeight));

      console.log('apects:',aspects);

      let aspect,width = 160;
      let layout = aspects.reduce((a,v) => a += v<1? 'p':'l','');

      switch (aspects.length)
      {
        case 0:
          //text-only node
          layout = 'text';
          aspect = 160/44; //two-line height
          break;

        case 1:
          //single source
          layout = '';
          aspect = aspects[0]; //use actual source aspect
          break;

        case 2:
          if ((layout.match(/p/g) || []).length==2)
          {
            //2 portraits
            width = 220;
            aspect = 1.25;
          }
          else if ((layout.match(/l/g) || []).length==2)
          {
            //2 landscapes
            aspect = 2/3;
          }
          else
          {
            //1 landscape + 1 portrait
            width = 140;
            aspect = .55;
          }
          break;

        case 3:
          if ((layout.match(/p/g) || []).length==3)
          {
            //3 portraits
            width = 250;
            aspect = 1.2;
          }
          else if ((layout.match(/l/g) || []).length==3)
          {
            //3 landscapes
            aspect = .5;
          }
          else if ((layout.match(/p/g) || []).length==2)
          {
            //2 portraits + 1 landscape
            width = 210;
            aspect = 4/5;
          }
          else
          {
            //2 landscapes + 1 portrait
            width = 250;
            aspect = 1.2;
          }
          break;
      }

      node.l = layout;
      node.a = aspect;

      this.setNodeWidth(node,width);
      this.setNodeHeight(node);

      //re-center node
      this.$refs.zoomer.fit([node],1.5);

      //store changes
      this.store(true);

      //update media skip list
      this.setAddSources();
    },

    setNodeWidth (node,w) {
      node.w = (w + 16) / 1000;
    },

    setNodeHeight (node) {
      //set node height based on current width, aspect and padding
      node.h = (((node.w - 16/1000) / node.a) + 16/1000); // add 8px padding on all sides
    },

    removeNodeSource () {
      //remove a source from active node, confirm first
      this.$q.dialog({
        title: '<i class="fa fa-trash"></i>&nbsp;Confirm Remove',
        message: '<p>Removing source from selected Node.</p><p>Are you sure?</p>',
        html: true,
        cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
        ok: { label: 'Yes', color: 'primary', noCaps: true, }
      }).onOk(() => {
        const nodeId = this.activeNode.id;
        this.sources = this.sources.filter(s => s.node!=nodeId || (s.node==nodeId && s.uuid!=this.activeNodeSource.uuid));

        //reset sources view
        this.selectedSource = 1;
        this.showSourceDetail = false;
        this.setAddSources();

        //set new layout
        this.$nextTick(() => { this.setNodeLayout() });
      })
    },

    exitEditNode () {
      console.log('done editing')

      //->TBD calculate new aspect for multiple sources

      this.activeNode.edit = false;

      //back to selected mode (draggable)
      this.edit = false;
      this.activeNode.draggable = true;
      this.$refs.zoomer.fit([this.activeNode],1)

      //store changes
      this.store(true);
    },

    removeNode (id) {

      //remove a node, confirm first
      this.$q.dialog({
        title: '<i class="fa fa-trash"></i>&nbsp;Confirm Remove',
        message: '<p>Removing Node, all connections to and from this node will be lost as well.</p><p>Are you sure?</p>',
        html: true,
        cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
        ok: { label: 'Yes', color: 'primary', noCaps: true, }
      }).onOk(() => {

        this.resetSelection();

        //remove node, related media and related connections
        this.nodes = this.nodes.filter(n => n.id!=id);
        this.sources = this.sources.filter(s => s.node!=id);
        this.connections = this.connections.filter(c => c.to[0]!=id && c.from[0]!=id);

        //fit new map
        this.$nextTick(() => this.$refs.zoomer.fit(false));

        //store
        this.store(true);
      });
    },

    deselectNodes() {
      this.nodes.forEach(n => { n.selected = false })
    },


    /* connections */

    newConnection (e) {

      const node = this.activeNode;
      const connection = {
        from:[node.id,'r'],
        to:['pointer'],
        active:true,
        text:'New Connection',
        c:node.c,
        dashed:4
      }

      //set initial mx and my (on button)
      this.mx = e.clientX - this.container.x;
      this.my = e.clientY - this.container.y;

      //insert connection
      this.connections.push(connection)
      this.addConnection = connection;
    },

    addNewConnection (esc) {
      //append new connection if valid
      if (esc || this.addConnection.to=='pointer')
      {
        //remove from collection if not connected
        this.connections.pop()

        //reset nodes, keep active node
        this.resetSelection(true);
      }
      else
      {
        //remove dashed style
        delete this.addConnection.dashed;
        delete this.addConnection.active;

        this.resetSelection();

        //switch to edit mode
        this.addConnection.text = '';
        this.editConnection(this.addConnection);
      }

      this.addConnection = false;
    },

    editConnection (c) {
      this.activeConnection = c;
      this.activeConnectionBefore = clone(c);

      //get connected nodes
      const nodes = this.nodes.filter(n => n.id==c.from[0] || n.id==c.to[0]);

      //center connected nodes at 1.5 zoom
      this.$refs.zoomer.fit(nodes,1.5)

      //focus label input
      this.$nextTick(() => { this.$refs.connection_label[0].focus() });
    },

    setConnectionColor (index) {
      this.activeConnection.c = index;
      //keep label input focussed
      this.$nextTick(() => { this.$refs.connection_label[0].focus() });
    },

    swapConnectionDirection (c) {
      //swap direction of a connection
      let from = c.from;
      c.from = c.to;
      c.to = from;

      //keep label input focussed
      this.$nextTick(() => { this.$refs.connection_label[0].focus() });
    },

    exitEditConnection (save) {
      //check if connection has changed and store if so
      if (save || JSON.stringify(this.activeConnection) !== JSON.stringify(this.activeConnectionBefore))
      {
        this.store(true,true);

        //auto-select target node when connection is new (no text)
        if (this.mode=='teacher' && !this.activeConnectionBefore.text) this.setActiveNode(this.nodes.find(n => n.id==this.activeConnection.to[0]));
      }

      //reset UI
      this.activeConnection = false;
      delete this.activeConnectionBefore;
      this.$refs.zoomer.fit(false);
    },

    removeConnection (connection) {

      //remove connection, confirm first
      this.$q.dialog({
        title: '<i class="fa fa-trash"></i>&nbsp;Confirm Remove',
        message: '<p>Removing Connection, are you sure?</p>',
        html: true,
        cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
        ok: { label: 'Yes', color: 'primary', noCaps: true, }
      }).onOk(() => {

        //remove from array and store it (via exitEdit)
        this.connections = this.connections.filter(c => c!=connection);
        this.exitEditConnection(true);

      })

    },


    /* vuex */

    store (save,forced) {
      //update activity or submit answer data
      if (this.mode=='teacher' || forced)
      {
        this.storeRecord(save)
      }
      else
      {
        if (!this.editor) this.storeAnswer();
      }
    },

    storeRecord (save) {
      //send record to store, we clone the local arrays otherwise the cleanup mapping is applied on them as well
      this.$store.commit('activity/setRecord', {
        block: this.blockId,
        record: {
          instructions:this.instructions,
          images:this.sources,
          nodes:clone(this.nodes).map(this.cleanUp),
          connections:clone(this.connections).map(this.cleanUp)
        }
      });

      if (save) this.$store.dispatch("activity/save");
    },

    storeAnswer () {
      //store reveal progress
      this.$emit('answer',{
        discovered:this.nodes.filter(n => n.open).map(n => ({ id:n.id, open:n.open, covered:n.covered }))
      })
    },

    cleanUp (v) {
      //remove dislay properties before pushing to data store
      delete v.active;
      delete v.edit;
      delete v.draggable;
      delete v.selected;
      return v;
    },


    /* event handling */

    zoomerUpdate (state) {
      this.cx = state.x;
      this.cy = state.y;
      this.zw = state.w;
      this.zh = state.h;
      this.zoom = state.z;
    },

    onClick (e,x,y) {

      if (this.add) return this.addNewNode();
      if (this.addConnection) return this.addNewConnection();
      if (this.activeConnection) return this.exitEditConnection();

      //click event received from zoomer: manually match against currend selections
      let onNode = this.nodes.some(node => {

        let sx = this.nodeLeft(node.x);
        let sy = this.nodeTop(node.y);
        let sw = this.nodeWidth(node.w);
        let sh = this.calcNodeHeight(node);

        if (x>=sx && x<=sx+sw && y>=sy && y<=sy+sh)
        {
          if (this.edit) this.exitEditNode();
          this.setActiveNode(node);
          return true;
        }
      });

      //no match, reset active selection
      if (!onNode)
      {
        if (this.activeNode.edit) return this.exitEditNode()

        this.resetSelection();
        if (this.addConnection) this.exitNewNode(true);

      }

      //reset other/all selections
      this.nodes.forEach(n => {
        if (n!=this.activeNode)
        {
          n.active = false;
          n.edit = false;
          n.draggable = false;
        }
      })
    },

    resetSelection (keepactive) {
      this.deselectNodes();

      if (!keepactive && this.activeNode)
      {
        this.activeNode.active = false;
        this.activeNode.edit = false;
        this.activeNode.draggable = false;
        this.activeNode = false;
        this.edit = false;
      }
    },

    onMouseDown (e) {
      if (this.add || this.addConnection) this.passEvent(e)
      this.onLabel = false;
      this.startDrag(e.target,e.clientX,e.clientY)
    },
    onLabelDown (c,e) {
      //start drag with pointer on label: store x,y for click detect, pass event to zoomer
      this.onLabel = { x:e.clientX, y:e.clientY, connection:c }
      this.passEvent(e);
    },
    onLabelUp (e) {
      //label pointer up: drag-end or click?
      let dx = this.onLabel.x - e.clientX;
      let dy = this.onLabel.y - e.clientY;
      if (!this.activeNode && Math.abs(dx)<3 && Math.abs(dy)<3)
      {
        //click: edit or discover connection
        this[(this.mode=='teacher'? 'edit':'discover')+'Connection'](this.onLabel.connection);
        this.$refs['zoomer'].onMouseLeave()
      }
      else
      {
        this.onLabel = false;
        this.passEvent(e);
      }
    },
    onMouseUp (e) {
      if (this.add || this.addConnection) this.passEvent(e);
      if (this.drag) this.endDrag(e.clientX,e.clientY)
    },
    onMouseMove (e) {
      this.passEvent(e);
      let x = e.clientX, y = e.clientY;

      if (this.add) this.ghostNode(x,y);
      if (this.drag) this.dragNode(x,y);
      if (this.addConnection) this.onNode(x,y);
    },
    onMouseLeave () {
      //stop panning in zoomer when pointer leaves tool area
      this.$refs['zoomer'].onMouseLeave()
    },
    onTouchStart (e) {
      if (e.touches.length === 1) this.startDrag(e.target,e.touches[0].clientX,e.touches[0].clientY)
    },
    onTouchEnd (e) {
      if (this.drag && e.touches.length === 0) this.endDrag(e.touches[0].clientX, e.touches[0].clientY);
    },
    onTouchMove (e) {
      if (this.drag && e.touches.length === 1) this.dragNode(e.touches[0].clientX, e.touches[0].clientY);
    },
    onWheel (e) {
      this.passEvent(e)
    },
    passEvent (event) {
      //pass event to zoomer elm
      this.$refs['zoomer'].$el.dispatchEvent(new event.constructor(event.type, event));
    },

    containerPosition () {
      const { x, y } = this.$refs.nodesmap.getBoundingClientRect();
      this.container = { x:x, y:y }
    },

    ghostNode (x,y) {
      //placeholder node centered at pointer position
      this.mx = x - this.container.x - ((this.nw/2) * this.zoom);
      this.my = y - this.container.y - ((this.nh/2) * this.zoom);
      this.ghostVisible = true;
    },

    onNode (x,y) {

      //update pointer position
      this.mx = x - this.container.x;
      this.my = y - this.container.y;

      //set origin anchor based on pointer move
      let anchor, node = this.activeNode;
      let ox = this.nodeLeft(node.x);
      let oy = this.nodeTop(node.y);
      let ow = this.nodeWidth(node.w);
      let oh = this.calcNodeHeight(node);

      if (this.my>oy && this.my<oy + oh)
      {
        //horizontal
        if (this.mx>ox + ow && this.mx<ox + 1.5*ow) anchor = 'r';
        if (this.mx>ox - .5*ow && this.mx<ox) anchor = 'l';
      }
      if (this.mx>ox && this.mx<ox + ow)
      {
        //vertical
        if (this.my>oy - .5*oh && this.my<oy) anchor = 't';
        if (this.my>oy + oh && this.my<oy + 1.5*oh) anchor = 'b';
      }
      if (anchor) this.addConnection.from[1] = anchor;


      //hittest target nodes
      let onNode = this.nodes.some((node) => {

        let tx = this.nodeLeft(node.x);
        let ty = this.nodeTop(node.y);
        let tw = this.nodeWidth(node.w);
        let th = this.calcNodeHeight(node);

        if (this.mx>=tx && this.mx<=tx+tw && this.my>=ty && this.my<=ty+th)
        {
          if (node!=this.activeNode)
          {
            this.deselectNodes();
            node.selected = true;

            //on node, check on which side and set connection anchor
            const c = this.addConnection;
            let anchor;

            if (this.my>ty+.25*th && this.my<ty + .75*th)
            {
              //horizontal
              if (this.mx<tx+.33*tw) anchor = 'l';
              if (this.mx>tx+.66*tw) anchor = 'r';
            }
            if (this.mx>tx+.25*tw && this.mx<tx + .75*tw)
            {
              //vertical
              if (this.my<ty+.33*th) anchor = 't';
              if (this.my>ty+.66*th) anchor = 'b';
            }
//             if (this.mx<sx+.33*sw) anchor = 'l';
//             if (this.mx>sx+.66*sw) anchor = 'r';
//             if (this.my<sy+.33*sh) anchor = 't';
//             if (this.my>sy+.66*sh) anchor = 'b';

            if (anchor)
            {
              c.to = [node.id,anchor];
            }
            else
            {
              c.to = ['pointer'];
            }

          }
          return true;
        }
      })

      if (!onNode)
      {
        this.addConnection.to = ['pointer'];
        this.deselectNodes()
      }

    },

    startDrag (elm,x,y) {

      if (!elm.classList.contains('draggable')) return;

      this.containerPosition();
      this.drag = {
        x:x - this.container.x,
        y:y - this.container.y,
      }

      const node = this.activeNode;
      this.drag.nx = this.nodeLeft(node.x);
      this.drag.ny = this.nodeTop(node.y);
    },

    dragNode (tx,ty) {
      //get delta
      let dx = tx - this.container.x - this.drag.x;
      let dy = ty - this.container.y - this.drag.y;

      //apply to node
      const node = this.activeNode;
      node.x = this.nodeX(this.drag.nx + dx);
      node.y = this.nodeY(this.drag.ny + dy);
    },

    endDrag (x,y) {
      //drag-end or click?
      let dx = x-this.container.x-this.drag.x;
      let dy = y-this.container.y-this.drag.y
      if (Math.abs(dx)<3 && Math.abs(dy)<3)
      {
        //click: switch to edit mode
        this.editNode()
      }
      else
      {
        //drag-end: store new position
        this.store(true)
      }

      this.drag = false;
    },

    //pixel position/size of selection elm
    nodeLeft (x) {
      return this.cx + (x * this.zw);
    },
    nodeTop (y) {
      return this.cy + (y * this.zh);
    },
    nodeWidth (w) {
      return w * this.zw;
    },
    nodeHeight (h) {
      return h * this.zh;
    },
    calcNodeHeight (n,source) {

      //calculate height based on source container aspect and current node padding
      const margin = this.nodePadding * 2;
      const sw = (n.w * this.zw) - margin;
      const textNode = n.l=='text';
      const sh = textNode? 0:sw / n.a;

      //add text height
      let th = 0;
      if (n.text)
      {
        //two line max (line-height = 1.25)
        th += this.labelSize * 1.25 * (n.text.length<this.nodeLineLength? 1:2)
        //add padding
        th += this.labelSize/4;
      }

      return source? sh:sh + th + margin;
    },

    //relative position/size of node  elm
    nodeX (x) {
      return (x - this.cx) / this.zw;
    },
    nodeY (y) {
      return (y - this.cy) / this.zh;
    },
    nodeW (w) {
      return w / this.zw;
    },
    nodeH (h) {
      return h / this.zh;
    },

    getPath(c,index) {
      //get curved path for connection
      let positions = [];
      let controls = [];

      //from/to nodes
      [this.nodes.find(n => n.id==c.from[0]), this.nodes.find(n => n.id==c.to[0])]
        .forEach((node,i) => {

          let x,y,cx,cy;

          if (!node)
          {
            //new connection, draw to mouseposition while no node is selected
            x = this.nodeX(this.mx);
            y = this.nodeY(this.my);
            cx = x;
            cy = y;
          }
          else
          {

            //get node position
            x = node.x;
            y = node.y;
            cx = x;
            cy = y;
            //use calculated height instead of node.h
            let h = this.calcNodeHeight(node)/this.zh;

            //add anchor position, set curve control point
            switch (c[i==0? 'from':'to'][1]) {
              case 't': //top
                x += node.w/2;
                cx = x;
                cy = y - .2;
                break

              case 'r': //right
                x += node.w;
                y += h/2;
                cx = x + .16;
                cy = y;
                break

              case 'b': //bottom
                x += node.w/2;
                y += h;
                cx = x;
                cy = y + .2;
                break

              case 'l': //left
                y += h/2;
                cx = x - .16;
                cy = y;
                break
            }

          }

          positions.push([this.nodeLeft(x),this.nodeTop(y)]);
          controls.push([this.nodeLeft(cx),this.nodeTop(cy)]);

        });

      //calculate and store center point for label
      this.labels[index] = this.getBezierPointAtLength(.5,positions[0],controls[0],controls[1],positions[1]);

      return 'M'+positions[0].join()+' C'+controls[0].join()+' '+controls[1].join()+' '+positions[1].join();
    },

    getBezierPointAtLength(t, start, c1, c2, end) {
      return {
        x: Math.pow(1-t,3) * start[0] + 3 * t * Math.pow(1 - t, 2) * c1[0]
          + 3 * t * t * (1 - t) * c2[0] + t * t * t * end[0],
        y: Math.pow(1-t,3) * start[1] + 3 * t * Math.pow(1 - t, 2) * c1[1]
          + 3 * t * t * (1 - t) * c2[1] + t * t * t * end[1]
      };
    },

    getLabelStyle(index) {

      const p = this.labels[index];
      let style = {
        left:p.x +'px',
        top:p.y +'px',
        fontSize:this.labelSize +'px',
      }

      if (this.activeConnection) style.borderColor = this.nodeColors[this.activeConnection.c];

      return style;
    },

  },
}
</script>

<style scoped lang="stylus">
@import '~quasar-variables'

#workbench
{
  overflow:hidden;
}

#zoomer,
#map,
#connections
{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
}

#zoomer
{
  background-color:#f5f5f5;
}

.guide {
  position:absolute;
  left:20px;
  right:20px;
  top:100px;
  text-align:center;
  z-index:1;
}

/*
#zoomable-debug
{
  width:100%;
  height:100%;
  background-color:rgba(250,0,0,.1);
  background-size: 20px 20px;
  background-image:
    linear-gradient(to right, rgba(0,0,0,.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0,0,0,.1) 1px, transparent 1px);
}
 */


.debug-line-horizontal
{
  position:absolute;
  left:0;
  top:50%;
  width:100%;
  height:0;
  border-top:1px dotted rgba(250,0,0,.5);
  z-index:2000;
  pointer-events:none;
}
.debug-line-vertical
{
  position:absolute;
  left:50%;
  top:0;
  width:0;
  height:100%;
  border-left:1px dotted rgba(250,0,0,.5);
  z-index:2000;
  pointer-events:none;
}


#sources
{
  position:absolute;
  right:0;
  top:0;
  width:100%;
  height:100%;
  transition:transform .3s ease 0s;
  z-index:1500;

  background-color:rgba(0,0,0,.7);
    /* background-color:rgba(245,245,245,.85); */
/*
  box-shadow: -2px 0 5px rgba(0,0,0,.2);
 */
}
#sources.collapsed
{
  transform:translateX(100%);
}
#sources-header
{
  position:absolute;
  width:60%;
  padding:1px 15px 1px 13px;
  height:46px;

  background-color:rgba(0,0,0,.2)
  color:$white;

  z-index:1;
}
#sources.collapsed #sources-header
{
  padding-left:4px;
  background:none;
}
#source-display
{
  position:relative;
  width:60%;
  height:100%;
  color:$white;
}
#sources >>> .source-info
{
    left:60%;
    top:0;
    height:100%;
    width:40%;
}
#sources .remove-source
{
  position:absolute;
  right:50%;
  transform:translateX(50%);
  bottom:15px;
  background-color:alpha($korenblauw 0.5);
}

#map
{
  pointer-events:none;
  user-select: none;
  overflow:hidden;
}
#map.drag,
#map.add,
/* #map.edit */
{
  pointer-events:all;
}
#map.add
{
  cursor:none;
}
#map.active .node:not(.selected):not(.active),
#map.active svg .lines path:not(.active),
#map.active .label:not(.active) .label-color
{
  opacity:.5;
}



.node
{
  position:absolute;
  border-radius:4px;
  padding:8px;

  background-color:$white;

  box-shadow:0 3px 3px -2px rgba(0,0,0,.3),0 3px 4px 0 rgba(0,0,0,.2),0 1px 8px 0 rgba(0,0,0,.15)
}
.node.active
{
  /* z-index:1; */
}


.node.draggable
{
  pointer-events:auto;
  /* cursor:move; */
}
.node.edit
{
  pointer-events:auto;
  z-index:1000;
}
.node.covered > *
{
  opacity:0;
}

.node.new
{
  background-color:alpha($white, 0.5);
  /* opacity:.6; */
/*
  border-style:dashed;
  border-width:2px;
  opacity:.8;
  border-color:$korenblauw;
 */
}
.node.new .sources
{
  opacity:.6;
}
.node.new .caption
{
  color:$korenblauw;
}
.node.new .multiple
{
  color:$hardroze;
}

.node .discover
{
  position:absolute;
  left:0;
  top:0;
  opacity:1;
  cursor:pointer;
  pointer-events:auto;
}
.node:not(.covered) .discover
{
  opacity:0;
}
.node.active .discover
{
  pointer-events:none;
}
.node.start:not(.active) .discover:not(.discovered)
{
  opacity:1;
}

.node.active.open
{
  pointer-events:auto;
}
/*
.node:not(.covered) .discover,
{
  opacity:0;
}
 */

.node .sources
{
  width:100%;
  display:flex;
}
.node .source
{
  position:relative;
}
.node .source img
{
  display:block;
  width:100%;
  height:100%;
  object-fit:cover;
}
.node .source .action
{
  display:none;
  color:$white;
  position:absolute;
  right:8px;
  bottom:8px;
  background-color:alpha($korenblauw,.5)
}
.node.active .source
{
  cursor:pointer;
}
.node.active .source:hover .action
{
  display:block;
}

/* sources layouts */
.node.t .sources
{
}

.node.pp .source:nth-child(2)
{
  margin-left:1px;
}
.node.ppp .source:nth-child(2)
{
  margin:0 1px;

}
.node.ll .sources,
.node.lp .sources,
.node.pl .sources,
.node.lll .sources
{
  flex-direction: column;
}
.node.ll .source:nth-child(2),
.node.lp .source:nth-child(2),
.node.pl .source:nth-child(2)
{
  margin-top:1px;
}
.node.ll .source,
.node.lp .source,
.node.pl .source
{
  max-height:50%;
  flex-grow:2;
}
.node.lll .source
{
  max-height:calc(1/3 * 100% - 1px);
}
.node.lll .source:nth-child(2)
{
  margin:1px 0;
}

.node.lp .source:nth-child(1),
.node.pl .source:nth-child(2)
{
  max-height:38%;
}
.node.lp .source:nth-child(2),
.node.pl .source:nth-child(1)
{
  max-height:62%;
}

.node.ppl .sources,
.node.plp .sources,
.node.pll .sources,
.node.lpp .sources,
.node.llp .sources,
.node.lpl .sources
{
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap:1px;
  grid-template-rows: repeat(2, 50%);
  grid-auto-flow: dense;
}
.node.ppl .sources,
.node.plp .sources
{
  grid-template-rows: 55% 45%;
}
.node.lpp .sources
{
  grid-template-rows: 42% 58%;
}
.node.llp .sources,
.node.lpl .sources
{
  grid-template-columns: 55% calc(45% - 1px);
}
.node.pll .sources
{
  grid-template-columns: calc(45% - 1px) 55%;
}


.node.ppl .source:nth-child(3),
.node.plp .source:nth-child(2),
{
  margin-top:1px;
  grid-column-start: span 2;
}
.node.lpp .source:nth-child(1)
{
  margin-bottom:1px;
  grid-column-start: span 2;
}
.node.lpl .source:nth-child(1),
.node.llp .source:nth-child(1),
.node.pll .source:nth-child(2)
{
  margin-bottom:1px;
}


.node.llp .source:nth-child(3),
.node.lpl .source:nth-child(2),
{
  grid-column-start:2;
  grid-row-start:1;
  grid-row-end:3;
}
.node.pll .source:nth-child(1)
{
  grid-column-start:1;
  grid-row-start:1;
  grid-row-end:3;

}


.node .caption
{
  text-align:center;
  overflow:hidden;
  line-height:1.25;
}

#map >>> .q-textarea .q-field__native
{
  resize:none !important;
  padding-right:12px;
}

#map >>> .q-field--filled .q-field__control
{
  padding-right:0;
}

.node .edit
{
  position:absolute;
  right:0;
  top:0;
  width:auto;
  height:auto;
}

.node .add
{
  position:absolute;
  right:0;
  transform:translate(50%,-100%);
  z-index:1000;
}
.node.text .add
{
  transform:translate(50%,-50%);
}

.node .remove
{
  color:$white;
  background-color:alpha($korenblauw,.5)
}

.node > .draggable
{
  position:absolute;
  left:0;
  top:0;
  background-color:rgba(255,255,255,0)
  border-radius:4px;
}
.node > .draggable:hover
{
  /* background-color:rgba(255,255,255,1) */
  /* background-color:alpha($hardroze,.2) */
}
.node.text > .draggable:hover
{
  background-color:rgba(0,0,0,.3)
}
.node .drag
{
  display:none;
/*
  position:absolute;
  left:0;
  top:0;
  transform:translate(-50%,-50%);
 */
  color:$white;
  text-shadow:0 2px 4px rgba(0,0,0,.5);
}
.node > .draggable:hover .drag
{
  display:block;
}


.colors
{
  margin-bottom:4px;
}
.color
{
  margin:10px 10px 0 0;
  display:block;
  width:18px;
  height:18px;
  border-radius:50%;
  border:2px solid transparent;
  opacity:.5;
  cursor:pointer;
}
.color:last-child
{
  margin-right:0;
}
.color.active
{
  margin-top:9px;
  border-color:rgba(0,0,0,.6);
  opacity:1;
  width:20px;
  height:20px;
}
.color:hover
{
  opacity:1;
}


/* connections */

.label
{
  position:absolute;
  padding:1px 6px;
  color:$white;
  border:2px solid transparent;

   border-radius:10px;
  font-weight:600;
  white-space:nowrap;

  transform:translate(-50%,-50%);

  background-color:#f5f5f5;

  pointer-events:auto;
}
.label-color
{
  position:absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;
  margin:-2px;
  border-radius:10px;
  z-index:-1;
}
.label .covered
{
  color:transparent;
}

.label.edit
{
  padding:5px;
  background-color:$white;
  z-index:1000;

}
.label.edit .label-color
{
  opacity:.08;
}
.label .remove
{
  margin:5px -5px 0 5px;
}
.label .swap
{
  margin:5px 0 0 0;
}


.connection-area
{
  position:absolute;
  width:24px;
  height:24px;
  opacity:.3;
  border-radius:50%;
  transform:translate(-50%,-50%);
  cursor:none;
}

</style>