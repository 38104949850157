<template>
  <node-view-wrapper
    class="source-node"
    ref="nodeview"
    :class="{ 'has-focus':focus, 'is-portrait':portrait }"
    @dragover.native="dragOver"
    @dragleave.native="dragLeave"
    @drop.native.prevent="drop"
  >
    <div class="source-header">
      <div
        class="drag-handle"
        contenteditable="false"
        draggable="true"
        data-drag-handle
        @click="selectNode"
      />
     <q-input
        v-if="edit"
        ref="title"
        textarea
        autogrow
        borderless
        dense
        :placeholder="(collection && slide=='index'? 'Slideshow':'Source')+' title'"
        v-model="sourceTitle"
        class="source-title"
        @focus="inputFocus(true)"
        @blur="inputFocus(false)"
        @input="textInput"
      />
      <h4 v-else class="source-title">{{sourceTitle}}</h4>
      <!-- we will probably use the square button below in a later iteration
      <div
        class="hidden nav-block small source-info row justify-around items-center cursor-pointer"
        @mouseover="hoverInfo=true"
        @mouseout="hoverInfo=false"
        @click.stop.prevent="toggleInfo"
      >
        <i class="fa" :class="showInfo? 'fa-times':'fa-info'"/>
      </div>-->
    </div>
    <div class="source-content">

      <!-- multiple sources carousel -->
      <q-responsive
        v-if="collection"
        class="source-slides"
        contenteditable="false"
        :ratio="3/2"
        @click="selectNode"
        draggable
      >
        <q-carousel
          contenteditable="false"
          v-model="slide"
          animated
          transition-prev="slide-right"
          transition-next="slide-left"
          :thumbnails="!showCollectionEdit && slide!='index'"
          infinite
          arrows
          :swipeable="swipeable"
          :control-text-color="showInfo? 'korenblauw':'white'"
          @mousedown.stop
        >
          <!-- index slide with source thumbs -->
          <q-carousel-slide
            contenteditable="false"
            draggable="false"
            key="indexslide"
            name="index"
            img-src="/static/blank.svg"
          >
            <q-scroll-area class="fit droppable">
              <div class="index-slide">

                <!-- static thumbs (read) -->
                <div
                  v-if="!edit"
                  class="flex"
                >
                  <div
                    class="item"
                    v-for="(s,index) in sources"
                    :key="s.uuid"
                    @click="slide=index"
                  >
                    <div>
                      <img class="thumb" :src="s.url"/>
                      <q-tooltip v-if="s.title" self="bottom middle">{{s.title}}</q-tooltip>
                    </div>
                  </div>
                </div>

                <!-- editable thumbs (edit) -->
                <template v-else>
                  <div class="info">
                    <i class="fa fa-info-circle"/> Drag thumbnails to change order, click trashcan to remove. Drag a source from the sidebar to add.
                  </div>

                  <draggable
                    class="sortable droppable"
                    v-model="sources"
                    animation="200"
                    ghost-class="ghost"
                    :component-data="{ attrs:{ class:'droppable' }}"
                    :disabled="noSorting"
                    @dragstart.native.stop
                    @mousedown.native.stop
                    @start="isSorting = true"
                    @end="isSorting = false"
                  >
                    <transition-group
                      type="transition"
                      tag="div"
                      name="!isSorting 'flip-list':null"
                    >
                      <div
                        class="item droppable"
                        v-for="(s,index) in sources"
                        :key="s.uuid"
                      >
                        <div><img
                          class="thumb"
                          :src="s.url"
                          @click="slide=index"
                        /></div>
                        <edit-button
                          v-if="sources.length>2"
                          class="absolute-bottom-left remove"
                          style="bottom:12px"
                          icon="fa fa-trash"
                          @mouseover.native="noSorting=true"
                          @mouseout.native="noSorting=false"
                          @click="removeSource(index)"
                        />
                      </div>
                    </transition-group>
                  </draggable>
                </template>

              </div>
            </q-scroll-area>
          </q-carousel-slide>

          <!-- source slides -->
          <q-carousel-slide
            contenteditable="false"
            draggable="false"
            v-for="(s,i) in node.attrs.sources"
            :key="s.uuid"
            :name="i"
            :img-src="s.url"
            @mousedown="selectNode"
         >
            <!-- slide info overlay -->

<!-- REPLACED WITH INDEX SLIDE, remove code below after testing

            <div
              v-if="edit"
              class="source-info-overlay source-text absolute-full"
              :class="{
                hover:hoverInfo,
                active:showInfo
              }"
            >
              <q-scroll-area class="fit">
                <section>
                  <q-input
                    textarea
                    autogrow
                    v-if="edit"
                    class="slide-title"
                    borderless
                    dense
                    v-model="s.title"
                    placeholder="Source title"
                  />
                  <h4 v-else class="slide-title">{{s.title}}</h4>
                  <q-input
                    textarea
                    autogrow
                    v-if="edit"
                    class="source-text"
                    borderless
                    dense
                    v-model="s.text"
                    placeholder="Write source caption …"
                  />
                  <div v-else v-html="s.text"/>
                </section>
              </q-scroll-area>
            </div>
 -->
          </q-carousel-slide>
         </q-carousel>

        <!-- slides edit overlay -->
        <div class="fit absolute-top-left overflow-hidden no-pointer-events">

            <div
              class="source-info-overlay absolute-full all-pointer-events"
              :class="{
                active:showCollectionEdit
              }"
              draggable="false"
              @mousedown.stop
              @dragstart.stop
            >
              <q-scroll-area class="fit droppable">

                <div class="info">
                  <i class="fa fa-info-circle"/> Drag thumbnails to change order, click trashcan to remove. Drag a source from the sidebar to add.
                </div>

                <draggable
                  class="sortable droppable"
                  v-model="sources"
                  animation="200"
                  ghost-class="ghost"
                  :component-data="{ attrs:{ class:'droppable' }}"
                  :disabled="noSorting"
                  @dragstart.native.stop
                  @mousedown.native.stop
                  @start="isSorting = true"
                  @end="isSorting = false"
                >
                  <transition-group
                    type="transition"
                    tag="div"
                    name="!isSorting 'flip-list':null"
                  >
                    <div
                      class="item droppable"
                      v-for="(s,index) in sources"
                      :key="s.uuid"
                    >
                      <img
                        class="thumb"
                        :src="s.url"
                      />
                      <edit-button
                        v-if="sources.length>2"
                        class="absolute-bottom-left remove"
                        icon="fa fa-trash"
                        @mouseover.native="noSorting=true"
                        @mouseout.native="noSorting=false"
                        @click="removeSource(index)"
                      />
                    </div>
                  </transition-group>
                </draggable>

              </q-scroll-area>
            </div>

        </div>

        <!-- actions -->

<!-- EDITING NOW VIA INDEX SLIDE, remove below after testing
        <div>
          <edit-button
            v-if="!showCollectionEdit"
            class="absolute-bottom-right"
            :color="edit? 'primary':'korenblauw'"
            :icon="showInfo? 'fa fa-times':'fa fa-info'"
            @click="toggleInfo"
            @dragstart.native.stop
          />
          <edit-button
            v-if="edit"
            style="right:42px"
            class="absolute-bottom-right"
            :icon="showCollectionEdit? 'fa fa-times':'fa fa-pencil'"
            @click="toggleCollectionEdit"
            @dragstart.native.stop
          />
        </div>
 -->

      </q-responsive>

      <!-- single source -->
      <template v-else>

        <hi-video
          v-if="node.attrs.type=='Video'"
          :url="node.attrs.src"
          style="width:100%"
        />

        <q-img
          v-else
          contenteditable="false"
          class="source-image"
          :src="node.attrs.src"
          spinner-color="korenblauw"
          @click="selectNode"
          @load="imageLoaded"
        >
          <div
            class="source-info-overlay source-text absolute-full"
            :class="{
              hover:hoverInfo,
              active:showInfo
            }"
          >
            <q-scroll-area class="fit">
              <section>
                [TBD source acknowledgements]
              </section>
            </q-scroll-area>
          </div>
  <!--
          <edit-button
            class="absolute-bottom-right"
            color="korenblauw"
            :icon="showInfo? 'fa fa-times':'fa fa-info'"
            @click="toggleInfo"
          />
   -->
        </q-img>
      </template>

      <!-- source(collection) caption -->
      <node-view-content class="source-text" v-show="slide=='index'"/>
      <div v-show="slide!='index'">
        <q-input
          v-if="edit"
          textarea
          autogrow
          borderless
          dense
          v-model="sourceDescription"
          class="source-text"
          placeholder="Write source caption …"
          @focus="inputFocus(true)"
          @blur="inputFocus(false)"
          @input="textInput"
        />
        <div v-else class="source-text" v-html="sourceDescription"/>
      </div>

    </div>

  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2'
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
// import { generateHTML } from '@tiptap/core';
// import StarterKit from '@tiptap/starter-kit';
import Draggable from 'vuedraggable'
import EditButton from '../tags/EditButton';
import HiVideo from '../components/hiVideo';

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
    EditorContent,
    BubbleMenu,
    EditButton,
    Draggable,
    HiVideo
  },

  props: {
    ...nodeViewProps,
    collection:{
      type:Boolean,
      value:false
    },
    decorations:{ //NOTE in nodeViewProps it's defined as object, seems to be a bug?
      type:Array,
      default:function () {
        return []
      }
    },
  },

  data () {
    return {
      focus:false,
      isSelected:false,
      portrait:false,
      slide:"index",
      showInfo:false,
      hoverInfo:false,
      showCollectionEdit:false,
      isSorting:false,
      noSorting:false
    }
  },

  computed: {

    edit () {
      return this.editor.view.editable;
    },

    sources: {
      get: function () {
        return this.node.attrs.sources;
      },
      set: function (sources) {
        sources.forEach((s,i) => this.$set(this.node.attrs.sources,i,sources[i]))
      }
    },

    sourceTitle: {
      get: function() {
        return this.slide=='index'? this.node.attrs.title:this.node.attrs.sources[this.slide].title;
      },
      set: function(val) {
        if (this.slide=='index') this.node.attrs.title = val
        else this.node.attrs.sources[this.slide].title = val
      }
    },

    sourceDescription: {
      get: function () {
        let desc;
        if (this.slide!='index') desc = this.node.attrs.sources[this.slide].text;
        return this.edit? desc:'<p>'+desc+'</p>';
      },
      set: function(val) {
        if (this.slide!='index') this.node.attrs.sources[this.slide].text = val;
      }
    },

//     sourceDescription() {
//       let html;
//       if (this.slide=='index')
//       {
//         //generate html via tiptap //NOTE we're using <node-view-content> instead (leaving code here for future reference)
//         const json = {
//           type: 'doc',
//           content:JSON.parse(JSON.stringify(this.node.content)) //get rid of vue reactiveness, it breaks tiptap render
//         }
//         html = generateHTML(json,[StarterKit]);
//       }
//       else
//       {
//         html = '<p>'+this.node.attrs.sources[this.slide].text+'</p>';
//       }
//
//       return html;
//     },

    swipeable () {
      return !(this.editor.view.editable && this.showInfo);
    },
  },

  methods: {

    imageLoaded (src) {
      //determine landscape/portrait
      const
        vue = this,
        img = new Image();
        img.src = src;
        img.onload = e => {
          this.portrait = e.target.naturalHeight>e.target.naturalWidth;
        }
    },

    selectNode () {
      this.editor.commands.setNodeSelection(this.getPos());
      this.isSelected = true;
    },

    dragOver (e) {
      //hide the editor drop cursor
      e.preventDefault();
      this.editor.options.onToggleDropcursor(false);
      //this.editor.commands.hideDropcursor();
    },

    dragLeave (e) {
      this.editor.options.onToggleDropcursor(true);
      //this.editor.commands.showDropcursor();
    },

    drop (e) {
      const
        tag = e.dataTransfer.getData("text/html"),
        transferData = e.dataTransfer.getData("text/plain");

      if (!tag || tag.indexOf('draggable-')==-1) return; //skip drop event from sortable

      console.log('📙 Narrative-source: drop event',e.target)

      if (event.dataTransfer.dropEffect=='link' || tag.indexOf('link')>-1) //we added the desired dropeffect to the tagname, as native drag drop seems to be inconsistent across browsers
      {
        //TODO allow adding related items to a source(collection)
        return false;
      }
      else (event.dataTransfer.dropEffect=='copy' || tag.indexOf('copy')>-1)
      {
        const
          s = JSON.parse(transferData),
          sources = [];

        //single or multiple sources?
        if (s.attrs.sources?.length)
        {
          s.attrs.sources.forEach(source => {
            sources.push({
              type:'source',
              uuid:source.uuid,
              title:source.title,
              text:source.text,
              url:source.url
            })
          });
        }
        else
        {
          //disallow dropped video source until supported
          if (s.attrs.type=='Video')
          {
            this.$q.notify({
              type: 'negative',
              position: 'top',
              message:'Adding video Sources to a slideshow is not supported at the moment',
              timeout:1500
            });
          }
          else sources.push({
            type:'source',
            uuid:s.attrs.uuid,
            title:s.attrs.title,
            text:s.content[0].content[0]?.text,
            url:s.attrs.src
          });
        }

        //get insert position
        const
          elm = e.target,
          on_thumb = elm.classList.contains('thumb') || elm.classList.contains('item'),
          item = elm.classList.contains('thumb')? elm.parentNode.parentNode:elm,
          insert_at = on_thumb? Array.from(item.parentNode.childNodes).indexOf(item) + 1:this.sources.length;

        let offset = 0;
        sources.forEach((source) => {
          if (this.sources.find(s=>s.uuid==source.uuid))
          {
            this.$q.notify({
              type: 'negative',
              position: 'top',
              message:'Source is already part of the slideshow!',
              timeout:1500
            });
          }
          else
          {
            this.sources.splice(insert_at+offset,0,source);
            offset++
          }
        })
      }
    },

    inputFocus (focus) {
      this.focus = focus;
    },

    textInput () {
      this.editor.options.onUpdate();
    },

    toggleDraggable (draggable) {
      if (this.$refs.nodeview) this.$refs.nodeview.$el.setAttribute('draggable',draggable)
    },

    toggleInfo () {
      this.showInfo = !this.showInfo;
    },

    toggleCollectionEdit() {
      this.showCollectionEdit = !this.showCollectionEdit;
    },

    removeSource (index) {
      //remove source from collection
      this.$q.dialog({
        title: '<i class="fa fa-trash"></i>&nbsp;Confirm Remove',
        message: '<p>Removing source from slideshow, are you sure?</p>',
        html: true,
        cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
        ok: { label: 'Yes', color: 'primary', noCaps: true, }
      }).onOk(() => {
        this.sources.splice(index,1)
      });
    },

  },

  mounted () {
//     console.log('edit=',this.edit)
//     //set autogrow on title textarea after it is rendered
//     if (this.editor.options.editable) setTimeout(e => this.autogrow = true,100);

    this.$nextTick(e => {

      //Firefox input interaction fix: remove draggable attribute from top element //TODO find better solution?
      this.toggleDraggable(false);
      //this.$refs.nodeview.$el.setAttribute('draggable',false)
    });
  }
}
</script>

<style lang="stylus" scoped>
@import '~quasar-variables'

.source-node
  position: relative;
  margin:16px 0;
  clear:left;
  /* pointer-events: auto !important; */
  /* box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.137), 0px 5px 22px 4px rgba(0,0,0,0.118); */

.source-node.has-focus
/*
  border:2px solid $korenblauw;
  margin:-2px;
  margin-bottom:14px;
 */
  box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.137), 0px 5px 22px 4px rgba(0,0,0,0.118);

.source-header
  position:absolute;
  left:0;
  top:0;
  width:100%;
  min-height:50px;
  transition:background-color .2s ease 0s;
  z-index:1;

.source-node:hover
.source-node.has-focus
  .source-header
    background-color:alpha($korenblauw,.35)


.drag-handle
  float:left;
  width:50px;
  height:50px;
  cursor: grab;
  background-color:$korenblauw;

.source-info
  position:absolute;
  left:0;
  top:50px;
  background-color:alpha($korenblauw,.6)
  color:$white;

h4.source-title,
.source-title >>> .q-field__native
  padding-bottom:9px;
  font-family:"Ubuntu", Arial, Helvetica, sans-serif;
  font-size:1.25rem;
  line-height:1.25;
  letter-spacing:normal !important;
  font-weight:500 !important;
  min-height:50px;
  padding-left:16px;
  color:$white;
  text-shadow:0 1px 3px rgba(0,0,0,.15), 0 1px 1px rgba(0,0,0,.35)

h4.source-title
  margin:0 !important;
  padding:11px 16px 11px calc(50px + 16px);

.source-image
  min-height:100px;
  background-color:$lighter-gray;

.source-slides
  user-select:none;

.source-slides >>> .q-carousel
  background-color:$lighter-gray;

.source-slides >>> .q-carousel .q-carousel__thumbnail
  border-radius:0;

.source-slides >>> .q-carousel__slide
  background-repeat:no-repeat;
  background-size:contain;
  padding:0;

.source-text
  padding:10px 0 12px 0;
  white-space: pre-wrap;
  font-size: 0.875rem;
  line-height: 21.5px;
  color:alpha($black,.9)

.source-text >>> p:last-child
  margin-bottom:0;

.source-info-overlay
  width:auto !important;
  padding:50px 0 86px 50px;
  transition:transform .3s ease 0s
  background-color:alpha($light-gray,.85)
  transform:translateY(100%)

.source-image .source-info-overlay
  padding-bottom:0px;

.source-info-overlay section
  padding:16px 66px 16px 16px;

.source-info-overlay >>> .q-scrollarea__thumb
  background:$korenblauw

/*
  .source-info-overlay
  padding:66px
  transition:transform .3s ease 0s, background-color .2s ease 0s
  background-color:transparent
  transform:translateX(calc(-100% + 50px))

.source-info-overlay.hover,
.source-info-overlay.active
  background-color:alpha($light-gray,.85)
 */

.source-info-overlay.active
  transform:none;

.q-transition--slide-left-leave-active,
.q-transition--slide-left-enter-active,
.q-transition--slide-right-leave-active,
.q-transition--slide-right-enter-active
  .source-info-overlay:not(.active)
    display:none;

/* .source-node >>> img:not([src]) */
.source-node >>> img[src='/static/blank.svg']
  width:50px;
  background-image:url(/static/block-bg.svg);
  /* display:none; */

.index-slide
  padding:50px 0 50px 50px;
  .item
    padding:0 12px 12px 0;
    cursor:pointer;
    .thumb
      display:block;
      max-height:100px;
      mix-blend-mode: hard-light;
    &:hover div
      background-color:$korenblauw;


/* edit */

.info
  margin:14px 0;
  font-weight: 600;
  font-style: italic;
  font-size: 0.875rem;
  line-height: 21.5px;
  color:alpha($black,.9)

.slide-title
  font-size: 1.25rem;
  font-weight: 400;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;

.slide-title >>> .q-field__native
  color:alpha($black,.9)
  padding:0 !important;
  line-height: 1.85rem;
  min-height:1.85rem !important;
  overflow:hidden;

.source-text >>> .q-field__control-container
  padding:0;
  margin-bottom: 0.5rem;

.source-text.q-field >>> .q-field__native
  padding:0 25px 0 25px;
  color:alpha($black,.9)
  line-height:21.5px;
  letter-spacing:0;
  overflow:hidden;

.source-node >>> .q-placeholder::placeholder
  color:$medium-gray !important;

.sortable
  position:relative;
  display:flex;

.sortable .item
  position:relative;
  display:inline-block;
  cursor:grab;

.flip-list-move
  transition: transform 0.5s;

.sortable .item.ghost
  opacity:.2;

.sortable,
.indexslide
   .thumb
      display:block;
      max-height:100px;

.sortable .item .remove
  display:none;

.sortable .item:hover:not(.sortable-chosen) .remove
  display:block;



/* portrait layout, narrower image, caption on the right */

.source-node.is-portrait
  .source-content
    display:flex;
  .source-image,
  .source-header
    width:65%;
  .source-text
    width:35%;

</style>
