import Historiana from '../Historiana';
import SelectSources from '../components/SelectSources';
import MyHistoriana from '../components/MyHistoriana';

import LearningActivities from '../components/LearningActivities';

import MyDashboard from '../components/MyDashboard';
import MyNarratives from '../components/MyNarratives';
import MyActivities from '../components/MyActivities';
import MySources from '../components/MySources';
import MyResources from '../components/MyResources';
import MyTags from '../components/MyTags';
import MyTeam from '../components/MyTeam';
import MyProfile from '../components/MyProfile';
import MyShares from '../components/MyShares';
import MyCollections from '../components/MyCollections';
import MyCollectionViewer from '../pages/MyCollectionViewer';
// import MyActivityLog from '../components/MyActivityLog'

import SASExplore from '../components/SASExplore';
import SASExploreSlug from '../components/SASExploreSlug';
import SASPartners from '../components/SASPartners';
import SASPartnerView from '../components/SASPartnerView';
import SASEuropeana from '../components/SASEuropeana';

// Pages
import HC from '../pages/HistoricalContent';
import HCbySlug from '../pages/HCbySlug';
import TL from '../pages/TeachingLearning';
import HOME from '../pages/Homepage';
import MediaLibrary from '../pages/MediaLibrary';
import CaseStudy from '../pages/CaseStudy';
import Partner from '../pages/Partner';

// Narrative prototyping
import Narrative from '../pages/Narrative';



// e-Activity builder and BuildingBlocks Editor
import eActivity from '../pages/eActivity';
import Builder from '../eabuilder/Builder';
import BlockEditor from '../eabuilder/BlockEditor';

// e-Activity building blocks
import BBtext from '../eabuilder/BB-text';
import BBquestion from '../eabuilder/BB-question';
import BBembed from '../eabuilder/BB-embed';
import BBsorting from '../eabuilder/BB-sorting';
import BBprioritizing from '../eabuilder/BB-prioritizing';
import BBanalysing from '../eabuilder/BB-analysing';
import BBcomparing from '../eabuilder/BB-comparing';
import BBhighlighting from '../eabuilder/BB-highlighting/BB-highlighting';
import BBdiscovering from '../eabuilder/BB-discovery/BB-discovery';


const BBlocks = [
  BBtext,
  BBquestion,
  BBembed,
  BBsorting,
  BBanalysing,
  BBcomparing,
  BBhighlighting,
  BBdiscovering
];

// Student View
import eaViewer from '../pages/eaViewer';

// Test Page
import TestPage from '../pages/TestPage';
import Experiment from '../pages/Experiment';
import histRedirect from '../components/histRedirect';

import ResetPassword from '../pages/ResetPassword';
import Admin from '../admin/Admin';

export const routes = [
  {
    path: '/admin',
    component: () => import('../admin/Admin.vue'),
    children: [
      {
        path: '/',
        name: 'admin-dashboard',
        component: () => import('../admin/Dashboard.vue')
      },
      {
        path: '/',
        name: 'admin-contenttypes',
        component: () => import('../admin/ContentTypes.vue'),
        props: true,

        // meta: {
        //   label: 'Contenttypes'
        // }
      },
      {
        path: 'members',
        name: 'admin-members',
        component: () => import('../admin/Members.vue'),
        meta: {
          label: 'Members'
        }
      },
      {
        path: 'tags',
        name: 'admin-tags',
        component: () => import('../admin/Tags.vue'),
        meta: {
          label: 'Tags'
        }
      },
      {
        path: 'teams',
        name: 'admin-teams',
        component: () => import('../admin/Teams.vue'),
        meta: {
          label: 'Teams'
        }
      },
      {
        path: 'partners',
        name: 'admin-partners',
        component: () => import('../admin/Partners.vue'),
        meta: {
          label: 'Partners'
        }
      },
      {
        path: 'roles',
        name: 'admin-roles',
        component: () => import('../admin/Roles.vue'),
        meta: {
          label: 'Roles'
        }
      },
      {
        path: 'collections',
        name: 'admin-collections',
        component: () => import('../admin/Collection.vue'),
        meta: {
          label: 'Collection'
        }
      },
      {
        path: 'learningactivities',
        name: 'admin-learningactivities',
        component: () => import('../admin/LearningActivity.vue'),
        meta: {
          label: 'Learning Activities'
        }
      },
      {
        path: 'narratives',
        name: 'admin-narratives',
        component: () => import('../admin/Narrative.vue'),
        meta: {
          label: 'Narrative'
        }
      },
      {
        path: 'viewpoints',
        name: 'admin-viewpoints',
        component: () => import('../admin/Viewpoint.vue'),
        meta: {
          label: 'Viewpoint'
        }
      },
      {
        path: 'search-insights',
        name: 'admin-search-insights',
        component: () => import('../admin/SearchInsights.vue'),
        meta: {
          label: 'Insights'
        }
      },
      {
        path: 'code',
        name: 'admin-code',
        component: () => import('../admin/CodeGenerator.vue'),
        meta: {
          label: 'CodeGenerator'
        }
      }


    ]
  },

  {
    name: 'logout',
    path: '/logout',
    component: () => import('../components/Logout.vue')
  },
  {
    name: 'register',
    path: '/testcomponent',
    component: () => import('../pages/TestComponent.vue')
  },
  {
    name: 'pdftest',
    path: '/test/pdf',
    component: () => import('../pages/pdfTest.vue')
  },
  {
    name: 'error',
    path: '/error',
    component: () => import('../components/Error.vue')
  },
  {
    name: 'reset-password',
    component: ResetPassword,
    path: '/reset-password/:challenge',
    props: true
  },
  {
    // http://localhost/hist/ for local development redirects
    // online we use the http://hi.st/ service
    name: 'histRedirect',
    path: '/hist/:shortCode',
    component: histRedirect
  },
  {
    name: 'histRedirectIndex',
    path: '/hist',
    component: histRedirect
  },

  // new eActivity viewer
  {
    name: 'eaViewer',
    path: '/ea/:mode/:uuid',
    component: eaViewer,
    props: true,
    meta: { class: 'is-ea-viewer' },
    children: BBlocks.map(b => {
      let type = b.name.substring(2).toLowerCase();
      return {
        path: '/ea/:mode/:uuid/' + type + '/:block',
        component: b,
        props: true,
        meta: {
          class: 'is-bb-' + type
        }
      };
    })
  },

  // new viewer
  {
    name: 'view',
    path: '/view/collection/:slug',
    component: () => import('../components/viewCollection.vue'),
    props: true,
    meta: {
      class: 'is-collection'
    }
  },

  // Narratives prototyping
  // note: /narratives is defined as child of / below

  {
    name: 'Narrative',
    path: '/narratives/:slug/:view/:edit?',
    component: Narrative,
    props: true,
    meta: {
      color: 'plasticgroen',
      class: 'is-narrative-view'
    }
  },
  {
    path: '/narratives/new',
    redirect: '/narratives/new/start/edit'
  },
  {
    path: '/narratives/new/:view',
    redirect: '/narratives/new/start/edit'
  },
  {
    path: '/narratives/:slug',
    redirect: '/narratives/:slug/start'
  },

  {
    name: 'TestPage',
    path: '/TestPage',
    component: TestPage,
    meta: {
      color: 'primary',
      class: 'is-test-page'
    }
  },

  {
    name: 'NarrativeRenderTest',
    path: '/nrt',
    component: () => import('../pages/NarrativeRenderTest.vue'),
  },


  {
    name: 'Experiment',
    path: '/Experiment',
    component: Experiment
  },
  {
    name: 'MediaLibrary',
    path: '/MediaLibrary',
    props: true,
    component: MediaLibrary,
    meta: {
      class: 'is-media-library'
    }
  },
  {
    path: '/',
    component: Historiana,
    meta: {
      class: 'is-homepage'
    },

    children: [
      {
        name: 'About',
        path: '/about',
        component: () => import('../pages/About.vue'),
        meta: {
          color: 'cyaan',
          class: 'is-homepage'
        }
      },

      {
        name: 'Labs',
        path: '/labs',
        component: () => import('../pages/Labs.vue'),
        meta: {
          color: 'eigeel',
          class: 'is-homepage'
        }
      },

      {
        name: 'search',
        path: '/search',
        component: () => import('../pages/Search.vue'),
        meta: {
          color: 'korenblauw',
          class: 'is-select-sources'
        }
      },

      {
        component: HOME,
        name: 'homepage',
        path: '/',
        meta: {
          color: 'cyaan',
          class: 'is-homepage'
        }
      },
      {
        name: 'CaseStudy',
        path: '/case-study',
        component: CaseStudy,
        meta: {
          class: 'is-case-study'
        }
      },
      {
        name: 'NarrativesIndex',
        path: '/narratives',
        component: () => import('../pages/NarrativesIndex.vue'),
        props: true,
        meta: {
          color: 'plasticgroen',
          class: 'is-narrative'
        }
      },
      {
        name: 'ViewpointsIndex',
        path: '/viewpoints',
        component: () => import('../pages/ViewpointsIndex.vue'),
        meta: {
          color: 'plasticgroen',
          class: 'is-historical-content'
        }
      },
      {
        name: 'Viewpoints',
        alias: '/historical-content/viewpoints/:slug',
        path: '/viewpoints/:slug',
        component: () => import('../pages/Viewpoints.vue'),
        props: true,
        meta: {
          color: 'plasticgroen',
          class: 'is-historical-content'
        }
      },
      {
        name: 'Narratives',
        alias: '/historical-content/narratives/:slug',
        path: '/narratives/:slug',
        component: () => import('../pages/Narrative.vue'),
        props: true,
        meta: {
          color: 'plasticgroen',
          class: 'is-historical-content'
        }
      },

      {
        name: 'PartnerIndex',
        path: '/partners',
        component: () => import('../pages/PartnerIndex.vue'),
        meta: {
          color: 'korenblauw',
          class: 'is-partner-page'
        }
      },
      {
        name: 'Partner',
        path: '/partners/:slug',
        component: Partner,
        props: true,
        meta: {
          color: 'korenblauw',
          class: 'is-partner-page'
        }
      },
      {
        name: 'PartnerCollection',
        path: '/partners/:partnerSlug/:collectionSlug',
        component: () => import('../pages/PartnerCollection.vue'),
        props: true,
        meta: {
          color: 'korenblauw',
          class: 'is-partner-page'
        }
      },

      {
        name: 'historical-content-slug-source',
        path: '/historical-content/source-collections/:slug',
        component: SASExploreSlug,
        meta: {
          class: 'is-historical-content'
        },
        props: true
      },

      {
        name: 'historical-content-slug-chapter',
        path: '/historical-content/:type/:slug/:chapter',
        component: HCbySlug,
        props: true,
        meta: {
          class: 'is-historical-content'
        }
      },
      {
        name: 'hc-type-index',
        path: '/historical-content/:type',
        component: () => import('@/pages/HCbyType.vue'),
        props: true,
        meta: {
          class: 'is-historical-content'
        }
      },
      {
        name: 'historical-content-slug',
        path: '/historical-content/:type/:slug',
        component: HCbySlug,
        meta: {
          class: 'is-historical-content',
          color: 'plasticgroen'
        },
        props: true
      },
      {
        name: 'historical-content',
        path: '/historical-content',
        component: HC,
        meta: {
          class: 'is-historical-content',
          color: 'plasticgroen'
        },
        props: true
      },
      {
        name: 'teaching-learning',
        path: '/teaching-learning',
        component: TL,
        meta: {
          class: 'is-teaching-learning',
          color: 'blauwgroen'
        },
        children: [
          {
            name: 'tl-learning-activities',
            path: 'activities',
            component: LearningActivities,
            meta: {
              class: 'is-teaching-learning'
            }
          }
        ]
      },
      {
        name: 'learning-activity',
        path: '/learning-activity/:slug',
        component: LearningActivities,
        meta: {
          class: 'is-teaching-learning'
        },
        props: true
      },
      {
        name: 'sassearch',
        path: '/search',
        component: SelectSources,
        meta: {
          class: 'is-select-sources',
          color: 'korenblauw'
        },
        children: [
          {
            name: 'explore',
            path: 'explore',
            component: SASExplore,
            meta: {
              class: 'is-select-sources',
              color: 'korenblauw'
            }
          },
          {
            name: 'exploreslug',
            path: 'explore/:slug',
            component: SASExploreSlug,
            meta: {
              class: 'is-select-sources'
            }
          },
          {
            name: 'partners',
            path: 'partners',
            component: SASPartners,
            meta: {
              class: 'is-select-sources',
              color: 'korenblauw'
            }
          },
          {
            // we need the uuid because there is data duplication
            // TODO: cleanup SearchPartner entries; make slug an unique
            // MIGRATE slug:
            // match (p:SearchPartner) set p.slug=apoc.text.slug(toLower(p.name))
            name: 'explore-partner',
            path: 'partners/:slug/:uuid',
            component: SASPartnerView,
            props: true,
            meta: {
              class: 'is-select-sources',
              color: 'korenblauw'
            }
          },
          {
            name: 'sas-search',
            path: 'search',
            component: SASEuropeana,
            meta: {
              class: 'is-select-sources',
              color: 'korenblauw'
            }
          }
        ]
      },
      {
        name: 'builder',
        path: '/builder/:uuid',
        props: true,
        component: Builder,
        // 		beforeEnter (to, from, next) {
        // 		  console.log("before loading builder")
        // 		  next()
        // 		},

        meta: {
          class: 'is-activity-builder'
        }
      },
      {
        name: 'builderIndex',
        path: '/builder',
        component: Builder,
        props: true,
        meta: {
          class: 'is-activity-builder'
        }
      },
      {
        name: 'elabuilder',
        path: '/ela/:uuid',
        component: Builder,
        // beforeEnter: (to, from, next) => {
        // //return console.log('beforeenter')
        //   console.log("** elabuilder **")
        //   console.log("from: ", from)
        //   console.log("to: ", to)
        //   console.log("next: ", next)
        //   console.log("** end elabuilder **")
        // },
        //store.dispatch('initApp').then(response => {
        // the above state is not available here, since it
        // it is resolved asynchronously in the store action
        //}, error => {
        // handle error here
        //})
        //},
        meta: {
          class: 'is-activity-builder'
        }
      },
      {
        name: 'my',
        path: '/my',
        // we need /my as parent for the active-link magic to work correctly
        // but we redirect to get activities as the first active page instead
        // of the empty my- one.
        redirect: '/my/dashboard',
        component: MyHistoriana,
        meta: {
          class: 'is-my-historiana'
        },
        children: [
          {
            name: 'my-dashboard',
            path: 'dashboard',
            component: MyDashboard,
            meta: {
              color: 'hardroze',
              class: 'is-my-historiana'
            }
          },
          {
            name: 'my-narratives',
            path: 'narratives',
            component: MyNarratives,
            meta: {
              color: 'hardroze',
              class: 'is-my-historiana'
            }
          },
          {
            name: 'my-activities',
            path: 'activities',
            component: MyActivities,
            meta: {
              class: 'is-my-historiana',
              color: 'hardroze'
            }
          },
          {
            name: 'my-collections',
            path: 'collections',
            component: MyCollections,
            meta: {
              color: 'hardroze',
              class: 'is-my-historiana'
            }
          },
          {
            name: 'mycolview',
            path: '/my/collections/:slug',
            props: true,
            component: MyCollectionViewer
          },
          {
            name: 'my-sources',
            path: 'sources',
            component: MySources,
            meta: {
              class: 'is-my-historiana',
              color: 'hardroze'
            }
            // beforeEnter (to, from, next) {
            //   console.log("before entry my sources")
            //   next()
            //   $('#main-menu-my-historiana').addClass('selected')

            // }
          },
          {
            name: 'my-resources',
            path: 'resources',
            component: MyResources,
            meta: {
              class: 'is-my-historiana',
              color: 'hardroze'
            }
          },
          {
            name: 'my-tags',
            path: 'tags',
            component: MyTags,
            meta: {
              class: 'is-my-historiana',
              color: 'hardroze'
            }
          },
          {
            name: 'my-profile',
            path: 'profile',
            component: MyProfile,
            meta: {
              class: 'is-my-historiana',
              color: 'hardroze'
            }
          },
          {
            name: 'my-shares',
            path: 'shares',
            component: MyShares,
            meta: {
              class: 'is-my-historiana',
              color: 'hardroze'
            }
          },
          {
            name: 'my-team',
            path: 'team',
            component: MyTeam,
            meta: {
              class: 'is-my-historiana',
              color: 'hardroze'
            }
          },
          {
            name: 'my-viewpoints-index',
            path: 'viewpoints',
            component: () => import('@/components/MyViewpoints.vue'),
            meta: {
              class: 'is-my-historiana',
              color: 'hardroze'
            }
          },

          {
            name: 'my-viewpoints',
            path: 'viewpoints/:slug',
            component: () => import('../pages/Viewpoints.vue'),
            props: true,
            meta: {
              color: 'plasticgroen',
              class: 'is-historical-content'
            }
          },
          //          {
          //            name: 'my-activitylog',
          //            path: 'activitylog',
          //            component: MyActivityLog,
          //            meta: { class: 'is-my-historiana' }
          //          }

          {
            path: '*',
            // vue3 needs
            // path: "/:catchAll(.*)",
            name: 'NotFound',
            component: () => import('../components/NotFound.vue'),
            meta: {
              color: 'cyaan',
              class: 'is-homepage'
            }
          }
        ]
      }
    ]
  },

  {
    name: 'eWrapper',
    path: '/builder',
    component: eActivity,
    children: [
      {
        name: 'bbeditor',
        path: '/builder/:uuid/edit',
        props: true,
        component: BlockEditor,
        meta: {
          class: 'is-my-historiana'
        },
        children: [
          {
            name: 'bbText',
            path: '/builder/:uuid/edit/text/:block',
            component: BBtext,
            props: true,
            meta: {
              class: 'is-bb-text',
              color: 'hardroze'
            }
          },
          {
            name: 'bbQuestion',
            path: '/builder/:uuid/edit/question/:block',
            component: BBquestion,
            meta: {
              class: 'is-bb-question',
              color: 'hardroze'
            }
          },
          {
            name: 'bbEmbed',
            path: '/builder/:uuid/edit/embed/:block',
            component: BBembed,
            meta: {
              class: 'is-bb-embed',
              color: 'hardroze'
            }
          },
          {
            name: 'bbSorting',
            path: '/builder/:uuid/edit/sorting/:block',
            component: BBsorting,
            meta: {
              class: 'is-bb-sorting',
              color: 'hardroze'
            }
          },
          {
            name: 'bbPrioritizinging',
            path: '/builder/:uuid/edit/prioritizing/:block',
            component: BBprioritizing,
            meta: {
              class: 'is-bb-prioritizing',
              color: 'hardroze'
            }
          },
          {
            name: 'bbAnalysing',
            path: '/builder/:uuid/edit/analysing/:block',
            component: BBanalysing,
            meta: {
              class: 'is-bb-analysing',
              color: 'hardroze'
            }
          },
          {
            name: 'bbComparing',
            path: '/builder/:uuid/edit/comparing/:block',
            component: BBcomparing,
            meta: {
              class: 'is-bb-comparing',
              color: 'hardroze'
            }
          },
          {
            name: 'bbHighlighting',
            path: '/builder/:uuid/edit/highlighting/:block',
            component: BBhighlighting,
            meta: {
              class: 'is-bb-highlighting',
              color: 'hardroze'
            }
          },
          {
            name: 'bbDiscovering',
            path: '/builder/:uuid/edit/discovering/:block',
            component: BBdiscovering,
            meta: {
              class: 'is-bb-discovering',
              color: 'hardroze'
            }
          }
        ]
      }
    ]
  }
];

export default routes;
