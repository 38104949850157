<template>
  <div>
    <!-- sub navigation of Narrative -->
    <section id="buttonbar">
      <div class="row q-mx-auto">
        <div class="col q-py-md">
          <a class="subnavlink" href="/narratives/new">
            <i class="fas fa-plus" />&nbsp;Create a Narrative
          </a>
        </div>
      </div>
    </section>

    <hi-search
      v-if="items.length"
      v-model="searchFor"
      placeholder="Find your Narratives"
    />

    <section v-if="items.length" id="card-holder">
      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md" v-if="items.length">
          <span
            :key="narrative.uuid"
            v-for="narrative in filteredItems"
            :id="narrative.uuid"
          >
            <card
              :tags="narrative.tags"
              :title="narrative.title"
              :imageUrl="narrative.icon_url"
              :url="narrative.url"
              type="Narrative"
              :csstype="narrative.is_published ? 'narrative' : 'private'"
              :btn-edit-modal="user === narrative.owner"
              @showEdit="showEdit(narrative)"
              @cardClicked="cardClick(narrative)"
              :btn-delete="user === narrative.owner"
              @deleteCard="deleteCard(narrative.uuid)"
              :btn-publish="user === narrative.owner && !narrative.is_published"
              :btn-un-publish="user === narrative.owner && narrative.is_published"
              @publish="publish(narrative)"
              @unpublish="unpublish(narrative)"
            >
              {{ narrative.teaser }}
            </card>
          </span>
        </div>
      </div>
    </section>
    <section v-else id="card-holder">
      <div class="row q-mx-auto justify-between items-start nosources">
        <div class="col">
          <h3 v-if="$store.getters['user/totalActivities']">
            <i class="fa fa-exclamation-circle" aria-hidden="true" /> Your filter didn't
            contain any Narratives
          </h3>
          <h3 v-else>
            <i class="fa fa-exclamation-circle" aria-hidden="true" /> No narratives yet
          </h3>
          <q-btn
            class="q-mb-lg"
            icon="fa fa-plus"
            label="Create a new Narrative"
            color="primary"
            no-caps
            @click="$router.push('/narratives/new')"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Card from "../tags/Card";
import tic from "../tic";
import { API } from "../tic";
import MyFilter from "../components/MyFilter";
import _ from "lodash";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "MyNarratives",
  components: { Card, MyFilter },
  computed: {
    ...mapGetters("user", { isAdmin: "isAdmin", user: "uuid" }),
    ...mapState({
      CAN: (state) => (perm) => {
        // return true if any is true
        return [
          state.user.permissions.includes("is_partner"),
          state.user.permissions.includes(perm),
        ].some((v) => v);
      },

      filteredItems() {
        if (this.searchFor) {
          return this.items.filter(
            (e) => e.title.includes(this.searchFor) || e.teaser.includes(this.searchFor)
          );
        } else {
          return this.items;
        }
      },
    }),
  },
  methods: {
    cardClick(card) {
      this.$router.push(`/narratives/${card.slug}`);
    },

    reload(toggle = false) {
      return API.post("/narratives/my", { member: this.user })
        .then((r) => {
          if (r.data.data) {
            this.items = [...r.data.data];
            console.log("narratives: ", this.items);
          } else {
            this.items = [];
          }
          //this.$q.notify({ message: "RELOADED", position: "center" });
        })
        .then(() => {
          console.log("done");
        });
    },

    isChecked(uuid) {
      return this.shareTags.includes(uuid);
    },

    meta(item) {
      const fields = [
        {
          label: "title",
          value: item.activity.title ? item.activity.title : null,
        },
        {
          label: "created",
          value: item.activity.created ? item.activity.created : null,
        },
        { label: "id", value: item.activity.uuid ? item.activity.uuid : null },
        {
          label: "shared",
          value: item.share_count ? `${item.share_count} times` : "never",
        },
      ].filter((e) => {
        return e.value;
      });

      return {
        type: "activity",
        objectType: "activity",
        fields: fields,
      };
    },

    sort(key) {
      this.sortKey = key;
    },
    updateTags(tags) {
      this.shareTags = tags;
    },

    deleteSelected() {
      let vm = this;
      this.$confirm("This will permanently delete the items. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "Delete completed",
          });
          vm.$store.dispatch("activity/delete", vm.selectedCards).then(() => {
            vm.$store.dispatch("user/getActivities");
            vm.selectedCards = [];
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    toggleSelect(card, iets) {
      this.selectedCards = tic.toggleItemInArray(this.selectedCards, card);
    },

    publish(narrative) {
      let vm = this;
      let me = this;
      API.post("/publish-narrative", {
        user: this.$store.getters["user/uuid"],
        uuid: narrative.uuid,
      })
        .then((resp) => {
          vm.$q.notify({
            type: "positive",
            position: "top",
            message: "Narrative published successfully!",
          });
          // this.getCollections();
          vm.reload();
        })
        .catch((err) => {
          this.$q.dialog({
            title: '<i class="fa fa-bomb"></i>&nbsp;Error',
            message: `${err}`,
            html: true,
            ok: { label: "Ok", color: "primary", noCaps: true },
          });
        });
    },

    unpublish(narrative) {
      let vm = this;
      console.log("unpub ", narrative);
      API.post("/unpublish-narrative", {
        user: this.$store.getters["user/uuid"],
        uuid: narrative.uuid,
      })
        .then((resp) => {
          vm.$q.notify({
            type: "positive",
            position: "top",
            message: "Narrative UNpublished successfully!",
          });
          vm.reload();
        })
        .catch((err) => {
          this.$q.dialog({
            title: '<i class="fa fa-bomb"></i>&nbsp;Error',
            message: `${err}`,
            html: true,
            ok: { label: "Ok", color: "primary", noCaps: true },
          });
        });
    },

    deleteCard(narrative) {
      let me = this;
      API.post("/narrative/delete", {
        user: this.$store.getters["user/uuid"],
        narrative: narrative,
      }).then(
        (resp) => {
          if (resp.status === 200) {
            me.reload();
            me.$store.dispatch("sendFeedback", {
              msg: "Activity deleted",
              status: "ok",
            });
          } else {
            console.log("api err resp: ", resp.data);
            me.$store.dispatch("sendFeedback", {
              msg: "Error deleting activity ",
              status: "nok",
            });
          }
        },
        (err) => {
          console.log("ERROR", err);
          me.$store.dispatch("sendFeedback", {
            msg: "Error deleting activity " + err,
            status: "nok",
          });
        }
      );
    },

    addTag(item, tagset) {
      this.$store.dispatch("user/addTag", { set: tagset, name: item });
    },

    showEdit(e) {
      this.$router.push(`/narratives/${e.slug}/start/edit`);
    },
  },

  mounted() {
    let me = this;
    me.reload();

    // needed for the tags
    API.post("/getProfile", { uuid: this.$store.state.user.uuid }).then((response) => {
      for (let t of response.data.user.tags) {
        let tagLookup = {
          MyLanguages: me.languages,
          MyYears: me.years,
          MyClasses: me.classes,
          MyKeywords: me.keywords,
        };

        try {
          tagLookup[t.tag].push(t);
        } catch (e) {
          console.log("Ignored unsupported TAG cloud: ", t.tag);
        }
      }
    });
  },
  data() {
    return {
      // new
      items: [],

      // old, needs cleanup

      clicked: "",
      selectedYears: [],
      isCopied: false,
      selectedCards: [],
      iclass: "",
      iyear: "",
      searchFor: null,
      languages: [],
      years: [],
      classes: [],
      keywords: [],
      activeFilters: [],
      // these for sharing ux
      shareTags: [], // the tags for a share (in modal)
      shareWith: "?",
      flashMsg: "",
      shareLink: null,
      thisShare: null,
      currentCard: null,
      sortKey: "newest",
      sendTo: null,
    };
  },
};
</script>
