/* eslint-disable no-new */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// import editor from 'vue2-medium-editor'

// als je niet via Home komt maar direct een router url opvraagt dan is jQuery/Foundation niet geinitialiseerd
// waarom weet ik niet. van alles geprobeerd; er lijkt hier nuttige info te staan maar die heeft nog niet
// tot een oplossing geleid:
// http://stackoverflow.com/questions/34297788/npm-zurb-foundation-webpack-cannot-resolve-module-foundation/35373347#35373347

import Vue from "vue"
// import VueCompositionAPI from '@vue/composition-api'
// Vue.use(VueCompositionAPI)

require('../public/fontawesome/css/all.css')


//import Vuex from "vuex"
import Router from "vue-router"
//import './style/new_settings.scss'
//import './style/newstyle.scss'
// import 'element-ui/lib/theme-chalk/index.css'
// import './style/element-variables.scss'
// import "./style/historiana.scss"
// not sure if we need this; it colors the buttons teal in the admmin
//import './style/styles.scss'
//import './style/_colors.scss'

// import 'animate.css/animate.min.css'
// import locale from 'element-ui/lib/locale/lang/en'
import _ from "lodash"

import { sync } from "vuex-router-sync"

// $http ajax lib replace this with Axios?
// import VueResource from 'vue-resource'
// import axios from 'axios'
// import VueAxios from 'vue-axios'

import App from "./App"
import store from "./store"
import { routes } from "./config/routes"
// import axios from 'axios'
//import $ from "jquery"

import VueZoomer from "./external/vue-zoomer/src"
Vue.use(VueZoomer)

//import ElementUI from 'element-ui'
//import locale from 'element-ui/lib/locale/lang/en'
//import createPersistedState from 'vuex-persistedstate'

// register custom global components
import HiButton from "@/components/hiButton.vue"
Vue.component("hi-button", HiButton)

import HiLogo from "@/components/hiLogo.vue"
Vue.component("hi-logo", HiLogo)

import HiLoading from "@/components/hiLoading.vue"
Vue.component("hi-loading", HiLoading)

import HiSearch from "@/components/hiSearch.vue"
Vue.component("hi-search", HiSearch)

import HiThemeFilter from "@/components/hiThemeFilter.vue"
Vue.component("hi-themefilter", HiThemeFilter)

import HiFilter from "@/components/hiFilter.vue"
Vue.component("hi-filter", HiFilter)

import HiSubnav from "@/components/hiSubnav.vue"
Vue.component("hi-subnav", HiSubnav)

Vue.filter("formatDate", value => {
  moment.locale("en-gb")
  return moment(value).format("LL - HH:mm")
})

import Quasar from "quasar"
Vue.use(Quasar)
//Vue.use(ElementUI, { locale })
//Vue.use(VueLocalStorage)
//Vue.use(ElementUI)
Vue.use(Router)

import "animate.css/animate.min.css"

Vue.prototype._ = _

Vue.prototype.FOOJA = function () {
  console.log("FOOJA")
}

window.eventBus = new Vue()
window.eventBus.$on("alert", (message, title = "Error", autoHide = false) => {
  // use global $q.dialog function
  // https://quasar.dev/quasar-plugins/dialog

  if (autoHide) {
    const timer = setTimeout(() => {
      dialog.hide()
    }, 3000)
  }

  const dialog = Vue.prototype.$q.dialog({
    //dark:true,
    title: title,
    message: message,
    html: true
  })

  return dialog
})

import { API } from "./tic"
import "./quasar"

/////////////////////////////////////////
// register these in the global namespace
Vue.prototype.$API = API
Vue.prototype.video_supported = ['video/mp4', 'video/x-ms-wmv', 'video/quicktime', 'video/webm']


// wrapper for Lodash to avoid imports in all components
Vue.prototype.isEmpty = (e) => {
  return _.isEmpty(e)
}

// load content-types globally
Vue.prototype.content_types = []
API.get("/content-types").then(r => {
  console.log("GET CONTEN TYPOES")
  Vue.prototype.content_types = [...r.data.data]
})



/////////////////////////////////////////

// hier een ref naar de global jQuery (en Foundation) zou fijn zijn.
// er moet een manier zijn om de via <script> in index geladen jQ en F te bereiken

// import jQuery from 'jQuery'
// window.jQuery = jQuery
// import 'expose?$!expose?jQuery!jquery'
// require('expose?$!expose?jQuery!jquery')
// var $ = require('jquery')

// let $ = window.jQuery

// const store = new Vuex.Store(Store)

const router = new Router({
  routes, // short for routes: routes
  //  linkActiveClass: 'selected',
  mode: "history", // was hash / history

  scrollBehavior(to, from, savedPosition) {
    //NOTE this is still v3.x router, so we use x,y not left,top !
    const y = to.name == 'Narrative' && from.name == 'Narrative' ? 1 : 0;
    //scroll to 1 within Narratives, to auto-hide main menu
    return { y: y }
  }
})

// used in eactivity to import it
const $q = Vue.prototype.$q

export { router, $q }

router.beforeEach((to, from, next) => {
  //remove previous meta class(es)
  document.body.classList.forEach(function (v, i, list) {
    if (v.indexOf("is-") === 0) list.remove(v)
  })

  //add current route meta class
  document.body.classList.add(to.meta.class)

  //continue route
  next()
})

// import Editable from './tags/Editable'
// Vue.component('editable', Editable)
// import CountrySelect from './components/CountrySelect'
// Vue.component('countryselect', CountrySelect)

// https://www.npmjs.com/package/vue-localstorage

// are we still using this?
// sync(store, router)

// handle global key events

window.onkeydown = e => {
  // console.log(e)
  // see e.className: "is-bb-text" for context
  if (e.key === "s" && e.ctrlKey) {
    //store.state.activity.showStudentPreview = !store.state.activity.showStudentPreview
    store.commit("activity/toggleDebug")
    store.commit("toggleShowDebug")
  }
}

window.hiDialog = () => {
  window.alert("sdsddsds")
}

const app = new Vue({
  router,
  store,
  render: h => h(App),
  localStorage: {
    autologin: {
      type: Boolean,
      default: true
    },
    session: {
      type: String,
      default: "" // do not use null, it becomes thruthy
    }
  }
}).$mount("#vueapp")

// store.dispatch('initApp').then(
//   response => {
//     return new Vue({
//       router,
//       Store,
//       render: h => h(App),
//       localStorage: {
//         autologin: {
//           type: Boolean,
//           default: true
//         },
//         session: {
//           type: String,
//           default: '' // do not use null, it becomes thruthy
//         }
//       }
//     }).$mount('#vueapp')
//   },
//   error => {
//     console.log("ERROR", error)
//     // alert via email
//     // axios.get('http://api.webtic.nl/alert?server=Historiana')
//     // document.location = '/static/error.html'
//     error(new Error('Cannot start Historiana'))
//   })

// store.dispatch('initApp').then(e => {
//   return new Vue({
//     router,
//     store,
//     render: h => h(App),
//     localStorage: {
//       autologin: {
//         type: Boolean,
//         default: true
//       },
//       session: {
//         type: String,
//         default: '' // do not use null, it becomes thruthy
//       }
//     }
//   }).$mount('#vueapp')
// })
