<template>
  <div>

    <bubble-menu
      v-if="editor"
      class="tooltip inline"
      :editor="editor"
      :shouldShow="showBubble"
    >
      <q-btn
        icon="fa fa-bold"
        size="12px"
        flat dense
        :color="editor.isActive('bold')? 'primary':'white'"
        @click="editor.chain().focus().toggleBold().run()"
      />
      <q-btn
        icon="far fa-italic"
        size="12px"
        flat dense
        :color="editor.isActive('italic')? 'primary':'white'"
        @click="editor.chain().focus().toggleItalic().run()"
        style="margin:0 8px"
      />
      <q-btn
        icon="far fa-underline"
        size="11px"
        flat dense
        :color="editor.isActive('underline')? 'primary':'white'"
        @click="editor.chain().focus().toggleUnderline().run()"
      />
    </bubble-menu>

    <floating-menu
      v-if="editor && !hideFloatingMenu"
      class="tooltip newline"
      :tippyOptions="{ maxWidth:'none' }"
      :editor="editor"
    >
      <q-btn
        label="Chapter"
        no-caps flat dense
        :color="editor.isActive('heading', { level: 2 })? 'primary':'white'"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
      />
      <q-btn
        label="Subchapter"
        no-caps flat dense
        :color="editor.isActive('heading', { level: 3 })? 'primary':'white'"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        style="margin:0 4px"
      />
      <q-btn
        label="Question"
        no-caps flat dense
        :color="editor.isActive('keyquestion')? 'primary':'white'"
        @click="editor.chain().focus().toggleKeyQuestion().run()"
        style="margin:0 4px"
      />
      <q-btn
        label="Paragraph"
        no-caps	flat dense
        :color="editor.isActive('paragraph')? 'primary':'white'"
        @click="editor.chain().focus().clearNodes().run()"
      />
    </floating-menu>

<!--
    <q-btn
      label="image test"
      @click="addImage"
    />
 -->
    <editor-content :editor="editor" @dragover.native="onDragOver"/>

  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/vue-2'
import { isTextSelection, Extension } from '@tiptap/core'
import { Plugin, PluginKey, NodeSelection } from 'prosemirror-state'
import { selectParentNode } from 'prosemirror-commands'

import StarterKit from '@tiptap/starter-kit'

import Image from '@tiptap/extension-image'

import Focus from '@tiptap/extension-focus'
import Placeholder from '@tiptap/extension-placeholder'
import Gapcursor from '@tiptap/extension-gapcursor'
import Dropcursor from '@tiptap/extension-dropcursor'

import Heading from '@tiptap/extension-heading'
import Paragraph from '@tiptap/extension-paragraph'
import Underline from '@tiptap/extension-underline'

import NarrativeSource from './../narratives/NarrativeSourceNode.js'
import NarrativeSourceCollection from './../narratives/NarrativeSourceCollectionNode.js'
import NarrativeKeyQuestion from './../narratives/NarrativeKeyQuestionNode.js'


//store summary and icon data on headers
const HiHeading = Heading.extend({
  defaultOptions: {
    ...Heading.options,
    levels: [1,2,3],
  },

  addAttributes() {
    return {
      level: {
        default: 2,
        rendered: false,
      },
      blockData: {
        default:{},
        rendered:false
      }
    }
  },

//   onUpdate({ editor }) {
//     console.log('heading change!')
//     // The content has changed.
//   },

})

// const HiDropcursor = Dropcursor.extend({
//   defaultOptions: {
//     width:2,
//     class:'dropcursor'
//   },
//
//   addCommands() { //??? is there a way to change the dropcursor class dynamically?
//     return {
//       hideDropcursor: () => ({ commands }) => {
//         //console.log('custom hideDropcursor',this)
//       },
//       showDropcursor: () => ({ commands }) => {
//         //console.log('custom showDropcursor')
//       },
//     }
//   },
//
// })

const HiParagraph = Paragraph.extend({

  addAttributes() {
    return {
      blockData: {
        default:{},
        rendered:false
      }
    }
  },

  addProseMirrorPlugins() {
    return [ new Plugin({
      appendTransaction: (transactions, prevState, nextState) => {

        const tr = nextState.tr;
        let modified = false;
        if (transactions.some((transaction) => transaction.docChanged)) {

          //clear blockdata of empty paragraphs

          nextState.doc.descendants((node, pos) => {
            const {paragraph} = nextState.schema.nodes;
            if (node.type===paragraph && node.nodeSize==2 && Object.keys(node.attrs.blockData).length && !node.attrs.blockData.isCaption)
            {
              console.log('NEdit: clear blockdata on empty paragraph (uuid='+node.attrs.blockData.uuid+')');
              const attrs = node.attrs;
              tr.setNodeMarkup(pos, undefined, {...attrs, blockData:{} });
              modified = true;
            }
          });
        }

        return modified ? tr : null;
      },
    })]
  },

});

const KeyQuestion = Paragraph.extend({
  name:'keyquestion',
  addAttributes() {
    return {
      blockData: {
        default:{},
        rendered:false
      }
    }
  },
  renderHTML({ HTMLAttributes }) {
    return ['question', HTMLAttributes, 0]
  },
  parseHTML() {
    return [
      {
        tag: 'question',
      },
    ]
  },
  addCommands() {
    return {
      toggleKeyQuestion: () => ({ commands }) => {
        return commands.setNode('keyquestion')
      },
    }
  },

});


export default {
  name: "NarrativeEditor",

  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu
  },

  props: {
    value:{
      type:Object,
      default:() => {}
    },
    editable:{
      type:Boolean,
      default:true
    },
    pSelectable:{
      type:Boolean,
      default:true
    },
    placeholderH1:{
      type:String,
      default:'Narrative title'
    },
    placeholderH2:{
      type:String,
      default:'Chapter title'
    },
    placeholderH3:{
      type:String,
      default:'Subchapter title'
    },
    placeholderCaptionSource:{
      type:String,
      default:'Write source caption …'
    },
    placeholderCaptionCollection:{
      type:String,
      default:'Write slideshow summary …'
    },
    placeholderQuestion:{
      type:String,
      default:'Key Question'
    },
    placeholderText:{
      type:String,
      default:'Write something …'
    },
    hideFloatingMenu:{
      type:Boolean,
      default:false
    }
  },

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    editable (to,from) {
      //force editor update when editable changes //??? why is this needed, should be reactive already
      this.editor.setOptions({editable: this.editable});
      this.editor.commands.setContent(this.value, false);
    }
  },

  mounted() {
    this.editor = new Editor({
      editable: this.editable,
      content: this.value,

      extensions: [
        StarterKit.configure({
          heading:false,
          paragraph:false,
          dropcursor:false,
        }),
        Placeholder.configure({
          showOnlyCurrent: false,
          includeChildren: true,
          placeholder: ({ node }) => {
            return node.type.name==="heading"?
              this['placeholderH'+node.attrs.level]
              :node.type.name=='keyquestion'?
                this.placeholderQuestion
                :node.attrs.blockData?.isCaption?
                  this['placeholderCaption'+node.attrs.blockData.isCaption]
                  :this.placeholderText;
          }
        }),
        Focus.configure({
          className: 'has-focus',
          mode: 'all',
        }),
        Underline,
        //Gapcursor,
        //
        //Image,
        HiHeading,
        HiParagraph,
        Dropcursor.configure({
          width: 3,
          class: 'dropcursor'
        }),

        NarrativeSource,
        NarrativeSourceCollection,
        //NarrativeKeyQuestion,
        KeyQuestion
      ],

      editorProps: {

        handleClickOn: (view, pos, node, nodePos, event, direct) => {

          const { state } = view;

          //FIXME deal with clicks on marks inside a paragraph -> offsetX then is relative to mark
          //console.log('direct=',direct,node.type.name,pos,event.offsetX)

          if (node.type.name=='paragraph' && this.pSelectable && event.offsetX<25)
          {

            //click left area before text: select paragraph node
            view.dispatch(state.tr.setSelection(NodeSelection.create(state.doc, nodePos)))

            //event.preventDefault();
            return true;
          }
        },

        handleDrop: (view,event,slice,moved) => {

          if (moved)
          {
            //do not process moved nodes, but offsets may have changed
            this.$emit('refreshOffsets');
            return
          }

          //ev.preventDefault();
          const
            { state } = view,
            tag = event.dataTransfer.getData("text/html"),
            position = view.posAtCoords({ left:event.clientX, top:event.clientY }),
            transferData = event.dataTransfer.getData("text/plain");

          console.log('📙 Narrative-editor: drop event')
          console.log('dropped tag=',tag);

          if (event.dataTransfer.dropEffect=='link' || tag.indexOf('link')>-1) //we added the desired dropeffect to the tagname, as native drag drop seems to be inconsistent across browsers
          {
            //drop related: determine node at position, then update attributes.blockData.related

            console.log('node=',view.state.doc.nodeAt(position.pos)?.type.name)
            console.log('resolve=',view.state.doc.resolve(position.pos).parent.type.name)
            console.log('position.inside',position.inside)

            if (position.inside==-1)
            {
              //on doc
              //TODO ->use resolved position OR hide the dropcursor?
            }
            else
            {
              //on node
              const
                node = view.state.doc.nodeAt(position.inside),
                attrs = node.attrs,
                data = attrs.blockData,
                rel = JSON.parse(transferData);

              if (data.related) data.related.push(rel)
              else data.related = [rel]

              console.log('update related=',data.related);

              view.dispatch(view.state.tr.setNodeMarkup(position.inside, undefined, {...attrs, blockData:data } ));
            }

            //dropped JSON is not valid for tiptap, return false to ignore
            return false;
          }
          else (event.dataTransfer.dropEffect=='copy' || tag.indexOf('copy')>-1)
          {
            //drop embed: insert JSON content at drop position
            this.editor.commands.insertContentAt(position.pos,JSON.parse(transferData));
          }

        },

        handleKeyDown: (view,event) => {

          const
            { state } = view,
            selection = state.selection,
            isEmptyParagraph = () => {
              const
                {$from, to} = selection,
                same = $from.sharedDepth(to);

              if (same == 0) return false

              const
                pos = $from.before(same),
                node = state.doc.nodeAt(pos);

              if (node.type.name=='paragraph' && node.nodeSize==2) return true
            }


          if (event.key=='Escape')
          {
            //delete empty paragraphs on escape
            if (selection.empty)
            {
              //first check if selection parent is empty paragraph

              if (isEmptyParagraph()) {
                //select and delete
                selectParentNode(state,view.dispatch);
                view.dispatch(view.state.tr.deleteSelection());
              }

//               const
//                 {$from, to} = selection,
//                 same = $from.sharedDepth(to);
//
//               if (same == 0) return false
//
//               const
//                 pos = $from.before(same),
//                 node = state.doc.nodeAt(pos);
//
//               if (node.type.name=='paragraph' && node.nodeSize==2)
//               {
//                 //select and delete //??? can we delete node directy?
//                 selectParentNode(state,view.dispatch);
//                 view.dispatch(view.state.tr.deleteSelection());
//               }

              //OR using tiptap?
//               this.editor
//                 .chain()
//                 .selectParentNode()
//                 .deleteSelection()
//                 .run();
            }
          }

//           if (event.key=='Enter')
//           {
//             console.log('enter key!')
//             console.log('p empty=',isEmptyParagraph())
//           }
        }
      },

      onCreate: () => {
        this.$emit('ready')
      },

      onFocus: () => {
        this.$emit('focus')
      },

      onBlur: () => {
        this.$emit('blur')
      },

      onUpdate: () => {
        this.$emit('input', this.editor.getJSON())
      },

      onToggleDropcursor: (e) => {
        //custom handler to toggle the Dropcursor visibility
        this.$emit('toggleDrop',e)
      }
    })
  },

  methods: {

    onDragOver (e) {
      //console.log('drag over editor','effect='+e.dataTransfer.dropEffect,'allowed='+e.dataTransfer.effectAllowed)
      const
        node = e.target.offsetParent,
        is_droppable = e.target.classList.contains('droppable') || node.classList.contains('droppable'),
        droppable = is_droppable || node.tagName=='P' || node.classList.contains('ProseMirror') || e.target.classList.contains('ProseMirror');

      //TODO allow link drag on source (and other embedded vue nodes?)

      //set dropEffect, NOTE this only seems to be working in Mozilla at the moment
      e.dataTransfer.dropEffect = droppable? e.dataTransfer.effectAllowed:'none';
    },

    updateContent() {
      this.editor.commands.setContent(this.value, false)
    },

    showBubble({ state, from, to }) {

      //not editable?
      if (!this.editable) return false;

      //no menu on source nodes //TODO needs more work, allow on description text of source node?
      if (this.editor.isActive('NarrativeSource') || this.editor.isActive('NarrativeSourceCollection')) return false;

      //below copied from extension-bubble-menu/bubble-menu-plugin.ts, to keep default behaviour

      const { doc, selection } = state
      const { empty } = selection

      // Sometime check for `empty` is not enough.
      // Doubleclick an empty paragraph returns a node size of 2.
      // So we check also for an empty text size.
      const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection)

      if (empty || isEmptyTextBlock) {
        return false
      }

      return true
    },

//     addImage() {
//       const url = window.prompt('URL')
//
//       if (url) {
//         this.editor.chain().focus().setImage({ src: url }).run()
//       }
//     },

    onDrop(e) {
      console.log('drop event',e)

    }

  },

  beforeDestroy() {
    //this.editor.destroy()
  },
}
</script>

<style lang="stylus" scoped>
@import '~quasar-variables'

  .tooltip
    display:flex;

  .tooltip.newline
    transform:translate(-65px,-160%)
    padding:4px 10px;
    max-width:none;

  .tooltip.newline::after
    left:20%

</style>