<template>
  <!-- wrapper for using NarrativeSource with multiple sources -->
  <narrative-source
    collection
    v-bind="$props"
  />
</template>

<script>
import { nodeViewProps } from '@tiptap/vue-2';
import NarrativeSource from './NarrativeSource';

export default {
  name: "NarrativeSourceCollection",

  components:{
    NarrativeSource
  },

  props:nodeViewProps,
}
</script>