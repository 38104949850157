<template>
  <section>
    <q-dialog full-height :value="open" @before-show="getData" @hide="taggerClose">
      <q-card style="width: 100%">
        <q-bar class="bg-hardroze text-white"
          ><b>Edit Tags</b>
          <q-space />
          <q-btn
            flat
            round
            class="float-right text-white"
            icon="far fa-times"
            v-close-popup
          />
        </q-bar>

        <q-card-section v-if="isLoading">
          <div style="display: flex; justify-content: center; align-items: center;">
            <div>
              <center>
                <h5>Loading</h5>
                <q-spinner color="primary" size="3em" />
              </center>
            </div>
          </div>
        </q-card-section>


        <q-scroll-area v-else>
          <p v-if="!this.uuid">uuid missing!</p>

          <q-card-section>
            <div class="row">
              <q-input
                clearable
                debounce="500"
                outlined
                v-model="lookup"
                @input="search"
                @clear="
                  () => {
                    this.lookup = '';
                  }
                "
                label="Lookup a tag"
                style="width: 100%"
              >
                <template v-slot:append>
                  <q-icon name="far fa-tag" color="primary" />
                </template>
              </q-input>
            </div>
          </q-card-section>

          <q-card-section>
            <h3>{{ title }}</h3>
          </q-card-section>

          <!-- <p>uuid: {{ item.uuid }}</p>
          <p>active: {{ active }}</p> -->

          <p class="note">Don't forget to scroll down and use the SAVE button</p>
          <q-card-section class="" v-if="lookup.length">
            <p v-for="tag in tags">
              <q-checkbox dense v-model="active" :label="tag.name" :val="tag.uuid" />
            </p>
          </q-card-section>

          <q-card-section>
            <div v-if="!lookup.length">
              <tagEntry :actives="active" @bus="bus" :tag="global_tags" />
            </div>
          </q-card-section>

          <q-card-actions align="center">
            <q-btn @click="saveTags" color="primary">Save</q-btn>
          </q-card-actions>
        </q-scroll-area>
      </q-card>
    </q-dialog>
  </section>
</template>

<script>
import { API } from "@/tic";
import tagEntry from "@/lib/tagEntry.vue";
export default {
  name: "HiTagger",
  props: {
    root: {
      type: String,
      default: "Historiana",
    },
    open: {
      type: Boolean,
      default: "",
    },
    item: {
      type: Object,
    },
    uuid: {
      type: String,
    },
    title: {
      type: String,
    }
  },

  components: {
    tagEntry,
  },

  computed: {
    showDialog() {
      let vm = this
      return this.open
    },
  },

  watch: {
    lookup(nValue) {
      console.log("searchFor changed");
      if (!nValue.length) {
        console.log("no zoek");
      }
    },

    root() {
      if (this.root === "Historiana") {
        API.get("/tags/all").then((r) => {
          this.global_tags = { ...r.data.data };
        });
      } else {
        console.log(`LOAD /tags/all/${this.root}`);
        API.get(`/tags/all/${this.root}`).then((r) => {
          this.global_tags = { ...r.data.data };
        });
      }
    },
  },

  mounted: function () {
    // get the current tags for the current Item
    // console.log("loadData in mounted")
    // this.loadActive();
  },

  methods: {
    getData() {
      this.isLoading=true
      console.log("hiTagger getData with ", this.uuid, this.item)
      API.get(`/tags/item/${this.uuid}`).then((r) => {
        this.active = r.data.data.map((e) => e.uuid);
        this.isLoading=false;
      });
    },

    taggerClose() {
      this.$emit("closeTagger");
    },

    loadActive() {
      API.get(`/tags/item/${this.uuid}`).then((r) => {
        this.active = r.data.data.map((e) => e.uuid);
      });
    },

    saveTags() {
      console.log("saveTags ", this.uuid, this.active);
      API.post("/tags/setItem", {
        uuid: this.uuid,
        tags: this.active,
      }).then(() => {
        console.log("emit reload");
        this.$emit("reload");
        this.$emit("closeTagger");
      });
    },

    search: function (searchFor) {
      API.get(`/tags/lookup?s=${searchFor}`).then((r) => {
        this.tags = [...r.data.data];
      });
    },

    bus(data) {
      console.log("TAGGER RECEIVED DATA ", data);
      // add or remove the tag from the item
      if (this.active.includes(data)) {
        console.log("remove ", data);
        this.active = this.active.filter((e) => {
          return e !== data;
        });
      } else {
        console.log("add");
        this.active.push(data);
      }
    },
  },

  data() {
    return {
      isLoading: true,
      lookup: "",
      tags: [],
      active: [],
      global_tags: [],
    };
  },
};
</script>
<style>
.tags-container {
  display: grid;
  border: 1px solid grey;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
