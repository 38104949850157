<!--
  recursively show all tags for a tree.
  used in hiTagger
-->
<template>
  <section class="iterate">
    <span v-if="!tag.child">
      <q-checkbox
        dense
        @input="update(tag.uuid)"
        v-model="active"
        :label="tag.name"
        :val="tag.uuid"
        :value="tag.uuid"
      />
    </span>
    <span v-else-if="tag.name !== 'Historiana'">
      <h4 v-if="level === 10">{{ tag.name }}</h4>
      <h5 v-if="level > 10">{{ tag.name }}</h5>
    </span>

    <ul v-if="tag.child">
      <div class="" v-if="tag.child.length">
        <div v-for="(item, index) in tag.child" :key="index">
          <tag-entry :actives="actives" @bus="bus" :tag="item" :level="level + 10" />
        </div>
      </div>
    </ul>
  </section>
</template>

<script>
export default {
  name: "tag-entry",
  props: {
    level: {
      type: Number,
      default: 0,
    },
    tag: {
      type: Object,
      required: true,
    },
    actives: {
      type: Array,
      default: [],
    },
  },

  mounted: function () {
    //console.log("set active to : ", this.actives);
    this.active = [...this.actives];
  },

  data() {
    return {
      active: [],
    };
  },

  methods: {
    update(event) {
      this.$emit("bus", event);
    },

    bus: function (data) {
      this.$emit("bus", data);
    },
  },
};
</script>

<style>
ul:first-child {
  padding-left: 0;
  margin-top: -80px;
}

ul {
  margin-top: 0;
  padding-top: 0;
}

h4 {
  padding-top: 2em;
}

h5 {
  margin-bottom: 0.2em;
  padding-bottom: 0;
}
</style>
