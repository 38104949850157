<template>
  <div class="cover">

    <pre class="debug hidden" style="top:65vh; height:35vh; overflow:scroll; font-size:12px; line-height:1">{{localInfo}}</pre>

    <div class="cover-image">
      <q-img
        v-if="info.icon"
        class="absolute-top-left cursor-pointer"
        :src="info.icon.url"
        :ratio="1.6"
        spinner-color="plasticgroen"
        @click="$emit('discover')"
      />

      <!-- login warning -->

      <q-badge
        v-if="editview && !edit && !info.uuid"
        class="absolute-center cursor-pointer"
        align="middle"
        color="rood"
        @click="$router.push('/')"
      >
        <i class="far fa-exclamation-triangle q-mr-xs"/>you need to be signed in
      </q-badge>

      <!-- edit -->

      <edit-button
        v-if="edit"
        class="absolute-bottom-left"
        icon="fa fa-image"
        @click="addCoverImage"
      >Edit image</edit-button>

      <!-- discover hint -->

      <div v-if="!editview" class="next nav-block action absolute-bottom-right" @click="$emit('discover')">
        <i class="fa fa-arrow-right"/>
        <label>Discover</label>
      </div>

    </div>

    <div class="row no-wrap cover-body">
      <div class="body">

        <!-- title -->

        <q-input
          textarea
          autogrow
          v-if="edit"
          class="cover-title"
          borderless
          dense
          v-model="localInfo.title"
          placeholder="Narrative title"
          @blur="autosave"
        />
        <h1 v-else class="cover-title">{{info.title}}</h1>

        <!-- introduction (summary) -->

        <narrative-editor
          ref="intro"
          class="introduction"
          v-model="doc"
          placeholderH1="Narrative title"
          placeholderText="Write an introduction …"
          hideFloatingMenu
          :editable="edit"
          :pSelectable="false"
        />

      </div>

      <div class="meta">

        <!-- acknowledgement -->

        <div v-if="!edit && localInfo.acknowledgement">
          <i class="fa fa-users"/>
          <p v-html="acknowledgement"/>
        </div>

        <!-- licence -->

        <div v-if="!editview && localInfo.meta && localInfo.meta.license.code">
          <i class="fa fa-copyright"/>
          <p><a :href="localInfo.meta.license.url" target="_blank">{{localInfo.meta.license.code}}</a><br>{{localInfo.meta.license.name}}</p>
        </div>

        <div v-if="edit">
          <i class="fa fa-users"/>
          <q-input
            class="meta-text"
            textarea
            autogrow
            borderless
            dense
            v-model="localInfo.acknowledgement"
            placeholder="Acknowledgements"
          />
          <i class="fa fa-copyright"/>
          <hi-license
            :items="licenses"
            :value="localInfo.meta.license"
            @input="v=>updateLicence(v)"
          />
        </div>

      </div>
    </div>

  </div>
</template>

<script>
//import { ref, computed, watch } from '@vue/composition-api'
import { API, clone } from '../tic'
import NarrativeEditor from './NarrativeEditor';
import hiLicense from '../components/hiLicense';
import EditButton from '../tags/EditButton';

export default {
  name: "NarrativeCover",

  props: {

    info:{
      type:Object,
      default:() => {}
    },

    edit:{
      type:Boolean,
      default:false
    },

    editview:{
      type:Boolean,
      default:false
    }

  },

  setup (props, context) {
  },

  components: {
    NarrativeEditor,
    hiLicense,
    EditButton
  },


  data () {
    return {
      setByNarrative:false,
      localInfo:{
        meta:{
          license:{}
        }
      },
      doc:{
        type:'doc',
        content:[{ type:'paragraph' }]
      },
      licenses:[],
      license:null
    }
  },

  computed: {
    acknowledgement () {
      return this.localInfo.acknowledgement.replace(/\n/g,'<br>');
    }
  },

  watch: {
    info: {
      handler() {
        const hasChanged = JSON.stringify(this.info)!=JSON.stringify(this.localInfo);
        if (hasChanged && Object.keys(this.info).length)
        {
          console.log('📘 Narrative-cover: set info')

          this.setByNarrative = true;
          this.localInfo = clone(this.info);

          //set summary content
          if (this.localInfo.summary && this.localInfo.summary.length)
          {
            this.$set(this.doc,'content',this.localInfo.summary);

            //force editor update (change is for some reason not picked on inital page load)
            if (this.$refs.intro) this.$refs.intro.updateContent();
          }
        }
      },
      immediate:true,
      deep:true
    },

    localInfo: {
      handler(to,from) {
        if (this.setByNarrative) return delete this.setByNarrative;
        //send changes to parent
        this.updateNarrative()
      },
      deep: true
    },

    doc: {
      handler(to,from) {
        //update local info, will trigger updateNarrative to parent
        this.$set(this.localInfo,'summary',this.doc.content);
      },
      deep: true
    },

    edit: {
      handler() {
        if (this.edit && !this.licenses.length) {
          //fetch license info from API
          API.get('select/license').then(r => {
            this.licenses = [...r.data];
          })
        }
      },
      immediate:true
    }

  },

  mounted () {
    console.log('📘 Narrative-cover mounted');

    //force scroll reset for nicer view transitions (offset 1 to keep main menu hidden)
    //window.scrollTo(0,this.edit? 0:1);
  },

  methods: {

    addCoverImage() {
      const options = {
        label: 'Add as cover',
        max:1,
        action:(s) => {
          const source = s[0]
          this.localInfo.icon = {
            uuid:source.uuid,
            url:source.url
          }
          this.updateNarrative();
        }
      }

      this.$emit('addSources',options);
      this.$emit('addMedia');
    },

    updateLicence (value) {
      this.$set(this.localInfo.meta,'license',value);
    },

    updateNarrative () {
      //notify parent
      this.$emit('edit',this.localInfo)
    },

    autosave () {
      //save a new narrative as soon as title is set
      if (this.localInfo.title.length>2 && !this.localInfo.uuid)
      {
        this.$emit('autosave',this.localInfo)
      }
    }
  }

}
</script>

<style lang="stylus" scoped>
@import '~quasar-variables'

.cover
  position:relative;
  z-index:10;

.cover-image
  position:absolute;
  width:100%;
  padding-bottom:62.5%;
  background-color:$lighter-gray;

.cover-title,
.introduction
  margin:25px 50px;

.cover-title
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  font-size: 3.25rem;

.cover-title >>> .q-field__control
  height:auto;

.cover-title >>> .q-field__native
  padding:0 !important;
  letter-spacing: -0.01562em !important;
  line-height: 4.25rem;
  overflow:hidden;

.cover-title >>> .q-field__control-container
  padding:0;

.introduction
  margin-bottom:75px;

.introduction >>> .ProseMirror h1
  margin:25px 0;

.introduction >>> .ProseMirror p
  padding-left:0;
  padding-right:0

.introduction >>> .ProseMirror p::before
  background-color:transparent !important;

.cover-body
  align-items: stretch;
  padding-top:62.5%;
  min-height:calc(100vh - 99px);

.body
  position:relative;
  width:67%;

.meta
  width:33%;
  background-color:$light-gray;
  padding:25px;

.meta,
.meta .meta-text >>> textarea
  color:$medium-gray;
  font-size:14px;
  line-height: 1.5;
  letter-spacing:0;

.meta .meta-text >>>
  .q-field__control-container,
  textarea
    padding-top:0;
    padding-bottom:8px;

.meta a
  color:$dark-gray;

</style>