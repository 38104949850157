<!--
  recursively show all tags for a tree.
  ADMIN/Partners
-->
<template>
  <div class="iterate">
    <span v-if="!tag.child && level > 1">
      <p class="q-my-xs">
        &bull; {{ tag.name }}
        <adminTagTools @bus="bus" :tag="tag" />
      </p>
    </span>
    <span v-else-if="tag.name !== 'Historiana'">
      <h3 class="q-mt-lg" v-if="level === 1">
       {{ tag.name }} <adminTagTools @bus="bus" :tag="tag" />
      </h3>
      <h6 v-if="level > 1">{{ tag.name }} <adminTagTools @bus="bus" :tag="tag" /></h6>
    </span>

    <span v-if="tag.child">
      <div class="" v-if="tag.child.length">
        <div v-for="(item, index) in tag.child" :key="index">
          <tag-entry @bus="bus" :tag="item" :level="level + 1" />
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import adminTagTools from "@/admin/components/adminTagTools";
export default {
  name: "tag-entry",
  components: { adminTagTools },
  props: {
    level: {
      type: Number,
      default: 0,
    },
    tag: {
      required: true,
    },
  },

  methods: {
    bus: function (data) {
      console.log("in adminTageEntry bus: ", data);
      // relay up to parent
      this.$emit("bus", data);
    },
  },
};
</script>

<style>
h4 {
  padding-top: 2em;
}

h5 {
  margin-bottom: 0.2em;
  padding-bottom: 0;
}
</style>
