<template>
  <q-layout
    view="hHh lpr fFf"
    v-if="$store.getters['user/isAdmin'] | $store.getters['user/isPartner']"
  >
    <q-header elevated class="bg-primary text-white" height-hint="98">
      <q-toolbar class="bg-primary text-white">
        <q-btn dense flat round icon="menu" @click="left = !left" />
        <q-toolbar-title
          ><span class="text-white text-bold">Historiana Admin</span></q-toolbar-title
        >
        <q-space />
        <b>{{ $store.getters["user/displayname"] }}</b>
        <q-btn type="a" href="/logout" round flat icon="fad fa-sign-out" />
      </q-toolbar>
    </q-header>

    <q-drawer show-if-above v-model="left" side="left" bordered class="bg-black">
      <!-- drawer content -->
      <q-list bordered class="rounded-borders">
        <q-item-label class="q-pa-md"><b>System</b></q-item-label>

        <q-item :to="{ name: 'admin-contenttypes' }" clickable v-ripple>
          <q-item-section avatar
            ><q-icon color="primary" name="fad fa-tools"
          /></q-item-section>
          <q-item-section>Content types</q-item-section>
        </q-item>

        <q-item :to="{ name: 'admin-members' }" clickable v-ripple>
          <q-item-section avatar
            ><q-icon color="primary" name="fad fa-user"
          /></q-item-section>
          <q-item-section>Members</q-item-section>
        </q-item>

        <q-item :to="{ name: 'admin-tags' }" clickable v-ripple>
          <q-item-section avatar
            ><q-icon color="primary" name="fad fa-tags"
          /></q-item-section>
          <q-item-section>Tags</q-item-section>
        </q-item>

        <q-item :to="{ name: 'admin-teams' }" clickable v-ripple>
          <q-item-section avatar
            ><q-icon color="primary" name="fad fa-users"
          /></q-item-section>
          <q-item-section>Teams</q-item-section>
        </q-item>

        <q-item :to="{ name: 'admin-roles' }" clickable v-ripple>
          <q-item-section avatar
            ><q-icon color="primary" name="fad fa-user-tag"
          /></q-item-section>
          <q-item-section>Roles</q-item-section>
        </q-item>

        <q-item :to="{ name: 'admin-partners' }" clickable v-ripple>
          <q-item-section avatar
            ><q-icon color="primary" name="fad fa-handshake"
          /></q-item-section>
          <q-item-section>Partners</q-item-section>
        </q-item>

        <q-separator />
        <q-item-label class="q-pa-md"><b>Content</b></q-item-label>

        <q-item :to="{ name: 'admin-collections' }" clickable v-ripple>
          <q-item-section avatar
            ><q-icon color="primary" name="fad fa-album-collection"
          /></q-item-section>
          <q-item-section>Collections</q-item-section>
        </q-item>

        <q-item :to="{ name: 'admin-learningactivities' }" clickable v-ripple>
          <q-item-section avatar
            ><q-icon color="primary" name="fad fa-graduation-cap"
          /></q-item-section>
          <q-item-section>Learning Activities</q-item-section>
        </q-item>

        <q-item :to="{ name: 'admin-narratives' }" clickable v-ripple>
          <q-item-section avatar
            ><q-icon color="primary" name="fad fa-books"
          /></q-item-section>
          <q-item-section>Narratives</q-item-section>
        </q-item>

        <q-item :to="{ name: 'admin-viewpoints' }" clickable v-ripple>
          <q-item-section avatar
            ><q-icon color="primary" name="fad fa-megaphone"
          /></q-item-section>
          <q-item-section>Viewpoints</q-item-section>
        </q-item>

        <q-separator />
        <q-item-label class="q-pa-md"><b>Statistics</b></q-item-label>

        <q-item :to="{ name: 'admin-search-insights' }" clickable v-ripple>
          <q-item-section avatar
            ><q-icon color="primary" name="fad fa-magnifying-glass-dollar"
          /></q-item-section>
          <q-item-section>Search Insights</q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view
        :title="currentRouteName"
        :showAdd="showAdd"
        @hideAdd="showAdd = false"
      />
    </q-page-container>

    <q-footer elevated class="bg-grey-8 text-white">
      <q-toolbar>
        <q-toolbar-title style="color: white">
          <small>
            <center>
              VueJS: {{ vueVersion }} &bull; Quasar: {{ $q.version }} &bull; Admin: 1.0
            </center></small
          >
        </q-toolbar-title>
      </q-toolbar>
    </q-footer>
  </q-layout>
  <div v-else class="bg-grey-5 column justify-center items-center window-height">
    <a href="/login">
      <div class="column error justify-center items-center bg-red">
        <hi-logo size="xxl" color="white" />
        <span class="q-pa-xs text-h3 text-white"><b>NO ACCESS</b></span>
      </div>
    </a>
  </div>
</template>

<script>
import { API } from "../tic";
import Vue from "vue";

export default {
  name: "Admin",
  data() {
    return {
      left: false,
      showAdd: false,
    };
  },
  computed: {
    vueVersion() {
      return Vue.version;
    },

    currentRouteName() {
      return this.$route.meta ? this.$route.meta.label : false;
    },
  },
  mounted() {},

  methods: {
    add() {
      this.showAdd = !this.showAdd;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");

.admin-header {
  font-family: "Noto Sans";
  font-size: 1.2em;
  font-weight: bold;
  background-color: lightgrey;
  text-transform: uppercase;
}

.form-title {
  font-family: "Noto Sans";
  font-size: 0.8em;
  letter-spacing: 0.004rem;
  text-transform: uppercase;
}

.code {
  font-family: "Fira Code", monospace;
}

tbody.permissions td:nth-child(even) {
  background-color: #eee;
}

.bold {
  font-weight: bold;
}

.error {
  border-radius: 50%;
  border: 4px solid white;
  height: 300px;
  width: 300px;
}
</style>
