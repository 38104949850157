<template>
  <!-- the delete/edit/add icons for each entry in the tags-list -->
  <span>
<!--     <q-btn
      icon="fad fa-down"
      color="primary"
      flat
      round
      dense
      size="sm"
      @click.stop="bus({ action: 'remove', tag })"
    /> -->

    <q-btn
      icon="fad fa-trash"
      color="primary"
      flat
      round
      dense
      size="sm"
      @click.stop="bus({ action: 'remove', tag })"
    />

    <q-btn
      icon="fad fa-pencil"
      color="primary"
      flat
      round
      dense
      size="sm"
      @click.stop="bus({ action: 'edit', tag })"
    />

    <q-btn
      icon="fa fa-plus"
      color="primary"
      flat
      round
      dense
      size="sm"
      @click.stop="bus({ action: 'add', tag })"
    />
  </span>
</template>
<script>
export default {
  name: "admin-tag-tools",
  props: { tag: { type: Object } },
  methods: {
    bus: function (data) {
      this.$emit("bus", data);
    },
  },
};
</script>
