import { API } from "../../tic"
import _ from "lodash"

const state = {
  ApiStatus: false,
  authenticated: false, // did we pass the /api/login succesfull?
  login: null,
  name: null,
  avatar: null,
  email: null,
  countrycode: null,
  joined: null,
  uuid: null,
  session: null,
  hasTags: 0,
  hasActivities: 0,
  tags: [],
  tagcloud: [],
  showUpload: false,
  sources: [],
  activities: [],
  numActivities: null, // total number of activities in active filter for user
  totalActivities: 0, // total number of activities for this user
  permissions: [],
  showBeta: false,
  partnerAdmin: {}
}

const getters = {
  // retrieve with  this.$store.getters["user/tagLanguages"]
  partnerAdmin: state => state.partnerAdmin,
  sources: state => state.sources,
  asources: state => (key = "newest") => {
    // some interesting insights on sorting
    // https://www.reddit.com/r/vuejs/comments/7dqlfc/vuex_best_practice_do_you_keep_sorted_data_in_the/
    let items = []
    switch (key) {
      case "oldest":
        items = [...state.sources].sort((a, b) => {
          return parseInt(a.source.created) - parseInt(b.source.created)
        })
        break

      case "newest":
        items = [...state.sources].sort((b, a) => {
          return parseInt(a.source.created) - parseInt(b.source.created)
        })
        break

      case "az":
        items = [...state.sources].sort((a, b) => {
          let nameA = a.source.title ? a.source.title.toUpperCase() : ""
          let nameB = b.source.title ? b.source.title.toUpperCase() : ""
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
        break

      case "za":
        items = [...state.sources].sort((b, a) => {
          let nameA = a.source.title ? a.source.title.toUpperCase() : ""
          let nameB = b.source.title ? b.source.title.toUpperCase() : ""
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
        break
    }
    return items
  },

  sources_by_oldest: state => _.sortBy(state.sources, "activity.created"),
  activities: state => state.activities,
  aactivities: state => (key = "oldest") => {
    // some interesting insights on sorting
    // https://www.reddit.com/r/vuejs/comments/7dqlfc/vuex_best_practice_do_you_keep_sorted_data_in_the/
    let items = []
    switch (key) {
      case "oldest":
        items = [...state.activities].sort((a, b) => {
          return parseInt(a.activity.created) - parseInt(b.activity.created)
        })
        break

      case "newest":
        items = [...state.activities].sort((b, a) => {
          return parseInt(a.activity.created) - parseInt(b.activity.created)
        })
        break

      case "az":
        items = [...state.activities].sort((a, b) => {
          let nameA = a.activity.title ? a.activity.title.toUpperCase() : ""
          let nameB = b.activity.title ? b.activity.title.toUpperCase() : ""
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
        break

      case "za":
        items = [...state.activities].sort((b, a) => {
          let nameA = a.activity.title ? a.activity.title.toUpperCase() : ""
          let nameB = b.activity.title ? b.activity.title.toUpperCase() : ""
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })
        break
    }
    return items
  },
  numActivities: state => state.numActivities,
  showUpload: state => state.showUpload,
  hasTags: state => (parseInt(state.hasTags) ? true : false),
  totalActivities: state => state.totalActivities,
  avatar: state => state.avatar,
  showBeta: state => state.showBeta,
  currentSession(state) {
    return window.localStorage.getItem("session")
  },

  hasTag(state) {
    // check if a `tag` is in the set of name `set`
    // used in MyTags.vue to see if we should sent a new tag to the API
    // calling example:
    // this.$store.getters['user/hasTag'](tagname, tagset)

    return (name, set) => {
      return state.tags.filter(e => {
        return e.tag === set && e.name.toLowerCase() === name.toLowerCase()
      }).length
    }
  },

  // used in MyTags.vue
  tagLanguages(state) {
    return state.tags.filter(e => {
      return e.tag === "MyLanguages"
    })
  },

  tagYears(state) {
    return state.tags.filter(e => {
      return e.tag === "MyYears"
    })
  },

  tagClasses(state) {
    return state.tags.filter(e => {
      return e.tag === "MyClasses"
    })
  },

  tagKeywords(state) {
    return state.tags.filter(e => {
      return e.tag === "MyKeywords"
    })
  },

  isAuthenticated(state) {
    return state.authenticated
  },

  isPartner(state) {
    return [
      state.authenticated,
      state.permissions.includes("is_partner")
    ].every(e => e)
  },

  isAdmin(state) {
    return [state.authenticated, state.permissions.includes("is_admin")].every(
      e => e
    )
  },

  userx(state) {
    return state.login
  },

  uuid(state) {
    return state.uuid
  },

  session(state) {
    return state.session
  },

  loginUuid: state => state.uuid,
  name: state => state.name,
  login: state => state.login,
  email: state => state.email,
  countrycode: state => state.countrycode,

  // butt-ugly use:
  // :btn-publish="$store.getters['user/can']('can_publish_tl')"
  can: state => perm => {
    // XXX before eslintreturn state.permissions.includes(perm) ? true : false
    return state.permissions.includes(perm)
  },

  displayname(state) {
    // return best displayname (in order of appearance)
    return (
      [state.name, state.login, state.email].filter(n => n)[0] || "Anonymous"
    )
  },

  permissions(state) {
    return state.permissions
  }
}

const mutations = {
  setShowBeta(state, params) {
    state.showBeta = params
  },

  updateSource(state, params) {
    // called from mysources
    state.sources[params.index].source = params.item
  },

  clearFeedback(state) {
    state.feedback = false
  },

  setFeedback(state, msg) {
    state.feedback = msg
  },

  addTag(state, tag) {
    state.tags.push(tag)
  },

  removeTag(state, tagUuid) {
    state.tags = state.tags.filter(e => {
      return e.uuid !== tagUuid
    })
  },

  setSources(store, sources) {
    state.sources = sources
  },

  setActivities(store, activities) {
    state.activities = activities
    state.numActivities = activities.length
  },

  setTotalActivities(store, data) {
    state.totalActivities = data.totalActivities
  },

  setActivityPublished(store, idx) {
    state.activities[idx].activity.is_published = true
  },

  setActivityUnPublished(store, idx) {
    state.activities[idx].activity.is_published = false
  },

  closeUpload(store) {
    state.showUpload = false
  },

  showUpload(store) {
    console.log("showupload-----------")
    state.showUpload = true
  },

  logout(store) {
    store.authenticated = false
    store.login = null
    store.name = null
    store.email = null
    store.joined = null
    store.uuid = null
    store.session = null
    store.hasTags = 0
    store.tags = []
    store.tagcloud = []
    store.permissions = []
  },

  setUser(store, { data, isError, authenticated, session }) {
    console.log("==================================")
    console.log("--> setuser:  auth", authenticated)
    console.log("==================================")
    let graphPerms = [
      "can_create",
      "is_admin",
      "is_teacher",
      "is_staff",
      "is_partner",
      "is_confirmed",
      "can_publish_tl",
      "show_beta"
    ]

    // console.log("setUser DATA: ", data)

    if (isError) {
      //window.alert('Error logging in..')
      console.log("setUser isError set")
      return
    }

    // auto-assign all properties? s
    //console.log(Object.keys(store))

    for (let p of graphPerms) {
      if (data.user[p]) {
        state.permissions.push(p)
      }
      //console.log("perm: ", p, data.user[p] || false)
    }

    state.joined = data.user.joined
    state.authenticated = authenticated
    state.login = data.user.login
    state.name = data.user.name
    state.email = data.user.email
    state.session = data.session.sessionId
    state.uuid = data.user.uuid
    state.countrycode = data.country
    state.tags = data.tags
    state.tagcloud = data.tagcloud
    state.hasTags = data.has_tags
    state.activities = data.activities
    state.avatar = data.user.avatar
    state.showBeta = data.user.show_beta
    state.partnerAdmin = { ...data.partner_admin }
    state.partnerMember = { ...data.partner_member }

    // console.log("xx: ", state.permissions)
    // console.log("state.session now: ", state.session)
  },

  updateUser(store, data) {
    state.name = data.name
    state.email = data.email
    state.countrycode = data.countrycode
  }
}

const actions = {
  sendFeedback(ctx, msg) {
    ctx.commit("setFeedback", msg)
    setTimeout(function () {
      ctx.commit("clearFeedback")
    }, 1500)
  },

  //
  saveSource(context, params) {
    //console.log("params: ", params)
    //console.log('xxxx: ', params.uuid)
    API.post("/update-source", {
      user: context.state.uuid,
      source: params.uuid,
      ...params
    })
    //.then(resp => {
    // XXX TODO fix the feedback loop here instead of in the caller
    // console.log("source updated")
    // update UI
    // this.userInput = false

    //console.log("xxxx: ", context)
    // console.log("xx: ", context.rootState)
    // context.dispatch('sendFeedback', {msg: 'Changes saved', status: 'ok'})

    //context.rootState.dispatch('sendFeedback', {msg: 'Changes saved', status: 'ok'})

    // },
    // err => {
    //   console.log("ERROR saveCurrentCard")
    // }
    // )
  },
  getSources(context, params, rootState) {
    console.log("-> GETSOURCES FROM API")
    context.commit("setIsLoading", true, { root: true })

    return API.post("/my-sources", {
      session: context.state.session,
      uuid: context.state.uuid,
      ...params // spread/push in the additonal request params :)
    })
      .then(response => {
        if (response.data.isError) {
          window.eventBus.$emit("alert", response.data.msg)
        } else {
          context.commit("setSources", response.data.sources)
        }
        context.commit("setIsLoading", false, { root: true })
      })
      .catch(error => {
        context.commit("setIsLoading", false, { root: true })
        window.eventBus.$emit("alert", "Can't retrieve Sources from server.")
      })
  },

  getActivities(context, params) {
    console.log("getActivities params: ", params)
    API.post("/my-activities", {
      session: context.state.session,
      uuid: context.state.uuid,
      ...params // spread/push in the additonal request params :)
    }).then(response => {
      //response.data.sources
      context.commit("setActivities", response.data.activities)
      context.commit("setTotalActivities", response.data)
    })
  },

  removeTag(context, tagUuid) {
    API.post("/MyTags/remove", {
      session: context.state.session,
      member: context.state.uuid,
      tag_uuid: tagUuid
    }).then(response => {
      context.commit("removeTag", tagUuid)
    })
  },

  //
  addTag(context, tag) {
    return API.post("/MyTags/create", {
      session: context.state.session,
      member: context.state.uuid,
      tagset: tag.set,
      tagname: tag.name
    }).then(response => {
      let newtag = response.data.data
      newtag.tag = tag.set
      context.commit("addTag", newtag)
    })
  },

  //
  autologin(context, sessionId) {
    console.log("promise autologin in store: ", sessionId)
    return API.post("/startsession", {
      session: sessionId
    }).then(response => {
      context.commit("setUser", response.data)
      // console.log("AUTOLOGIN STARTED WITHx: ", context.state.uuid)
      // console.log("xXXX SET ADMIN")
    })
  },

  //
  updateProfile(context, r) {
    //console.log("update profile with ", JSON.stringify(r.record))
    //console.log("ctx: ", context)
    API.post("/updateMember", {
      _context: "Member",
      _session: context.state.session,
      _uuid: context.state.uuid,
      login: r.record.login,
      name: r.record.name,
      email: r.record.email,
      countrycode: r.record.countrycode,
      avatar: r.record.avatar,
      show_beta: r.record.showBeta
    }).then(response => {
      r.isSaved = true
      context.commit("setShowBeta", r.record.showBeta)
      context.commit("updateUser", r.record)
      setTimeout(function () {
        r.isSaved = false
      }, 1500)
      console.log("re: ", response)
    })
  },

  //
  logout(ctx) {
    ctx.commit("logout")
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
}
