<template>
  <div id="workbench" class="ea-canvas">
    <q-resize-observer @resize="containerPosition" />
    <div id="bb-sorting"
      ref="container"
      :class="{ refresh:refresh }"
      :style="{ backgroundImage:backgroundImage, backgroundSize:backgroundContain? 'contain':'cover' }"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
      @mousemove="onMouseMove"
      @touchstart="onTouchStart"
      @touchend="onTouchEnd"
      @touchmove="onTouchMove"
    >

      <!-- intro -->
      <h5
        v-if="!sources.length"
        class="guide"
      >
        <span v-if="mode!='teacher'">Please add sources in teacher mode first</span>
        <span v-else>Start creating your Sorting block by adding some <a href="/add-media" class="text-hardroze" @click.prevent="$emit('addMedia')">sources</a></span>
      </h5>

      <!-- set background menu -->
      <q-btn
        id="bg-button"
        v-if="mode=='teacher' && sources.length"
        color="primary"
        label="Set background"
        icon="fal fa-th-large"
        no-caps
      >
        <q-menu ref="bgMenu" content-style="background-color:rgba(254,254,254,.9)" max-height="540px">
          <!-- custom background button -->
          <q-item>
             <q-btn
              flat
              color="primary"
              no-caps
              icon="fal fa-images"
              label="Add Source background"
              @click="addBackground"
            />
          </q-item>
          <!-- static backgrounds list -->
          <q-scroll-area style="width:400px; height:calc(540px - 52px)">
            <q-list class="bg-list" dense>
              <q-item
                class="bg-list-item"
                v-for="(b) in backgrounds"
                clickable
                v-ripple
                :key="b.id"
                :active="b.url==background"
                active-class="bg-selected"
                @click="setBackground(b)"
              >
                <q-item-section avatar>
                  <q-avatar square size="60px" color="purple-1">
                    <img v-if="b.url" :src="b.url">
                  </q-avatar>
                </q-item-section>
                <q-item-section v-html="b.desc"/>
              </q-item>
            </q-list>
          </q-scroll-area>
        </q-menu>
      </q-btn>

      <!-- draggable sources -->
      <div
        v-for="(s,i) in sources"
        class="draggable-source"
        :key="i"
        :class="{ active:index==i, inactive:index!=i && source && isExpanded }"
        :style="{
          left:selectionLeft(s.x) + 'px',
          top:selectionTop(s.y) + 'px',
          zIndex:index==i? 1:0,
          transform:'scale('+scale+')',
        }"
        @mousedown.prevent="selectedSource = i+1"
        @touchstart.prevent="selectedSource = i+1"
      >
        <img class="draggable" :src="s.thumb_url || s.thumb || s.url">
        <div v-if="s.title" class="caption draggable">{{ s.title }}</div>
      </div>

      <!-- draggable labels -->
      <div
        v-for="(l,i) in labels"
        class="draggable-label"
        :key="`l_${i}`"
        :class="{ edit:l.edit }"
        :style="{
          left:selectionLeft(l.x) + 'px',
          top:selectionTop(l.y) + 'px',
          zIndex:selectedLabel==i? 1:0,
          transform:'scale('+scale+')',
          borderColor:l.edit? labelColors[l.c]:'transparent',
          backgroundColor:l.invert && !l.edit? labelColors[l.c]:''
        }"
        @mousedown.prevent="selectLabel(i)"
        @touchstart.prevent="selectLabel(i)"
      >
        <!-- edit label UI -->
        <div v-if="l.edit" @mousedown.stop @mouseup.stop>
          <q-input
            ref="label"
            v-model="l.text"
            filled
            dense
            placeholder="Label text"
            style="width:204px"
            maxlength="25"
            counter
            autofocus
            :color="labelColorNames[l.c]"
            @keyup.enter="exitAddLabel(true)"
          />
          <div class="colors row justify-center">
            <span
              v-for="(color,cIndex) in labelColors"
              class="color"
              :class="{ active:l.c==cIndex }"
              :key="color"
              :style="{ backgroundColor:color }"
              @click="setLabelColor(cIndex)"
            />
          <q-btn
            round
            dense
            class="invert"
            size="9px"
            icon="far fa-font"
            :flat="!l.invert"
            :color="labelColorNames[l.c]"
            @click.stop="setLabelStyle(l)"
          ><q-tooltip>Invert label</q-tooltip>
          </q-btn>

          <q-btn
            flat
            round
            dense
            class="remove"
            icon="fas fa-trash"
            color="korenblauw"
            size="12px"
            @click.stop="removeLabel(l)"
          />
          </div>
        </div>

        <div v-else
          class="caption"
          :class="{ draggable:mode=='teacher' }"
          :style="{ color:l.invert? 'white':labelColors[l.c] }"
        >{{ l.text }}</div>
      </div>


      <div
        ref="ghostText"
        id="add-text"
        v-if="addText"
        class="draggable-label drag"
        :style="{
          left:mx + 'px',
          top:my + 'px',
          color:labelColors[defaultLabelColor]
        }"
      ><div class="caption">Place text label</div></div>

      <!-- sources list -->
      <div id="sources"
        v-if="sources.length>=1"
        :class="{ collapsed:!isExpanded }"
        @mousedown.stop
        @mouseup.stop
        @touchend.stop
        @click.stop="isExpanded? false:isExpanded=true"
      >
        <div id="sources-header" class="row justify-between items-center">
          <q-btn
            no-wrap
            no-caps
            flat
            round
            style="color:#e0e0e0"
            :size="isExpanded? '10px':'14px'"
            :icon="isExpanded? 'fas fa-chevron-right':'fas fa-info'"
            @click.stop="isExpanded=!isExpanded"
          />

          <q-pagination
            dark
            v-if="sources.length>1"
            v-model="selectedSource"
            color="white"
            :max="sources.length"
            :input="true"
            :boundary-links="false"
            @input="$refs.zoomer? $refs.zoomer.reset():null"
          />

        </div>

        <div v-if="isExpanded && source" id="source-display">

          <!-- video player -->
          <div
            v-if="video_supported.includes(source.type)"
            class="row content-center fit"
          >

            <hi-video ref="video" :thumb="source.thumb_url" :url="source.playback" style="width:100%"/>
          </div>

          <!-- image zoomer -->
          <v-zoomer
            v-else
            controls
            ref="zoomer"
            class="fit"
            :zoom="1"
            :zoomed.sync="zoomed"
            @click="showInfo=false"
          >
            <img :src="source.url">
          </v-zoomer>

          <!-- remove source -->
          <q-btn
            v-if="mode=='teacher'"
            round
            size="sm"
            icon="fas fa-trash"
            class="remove-source"
            @click="removeSource"
          />

          <!-- source info -->
          <q-btn
            round
            size="sm"
            icon="fas fa-info"
            class="source-info"
            @click="showInfo=!showInfo"
          />

          <!-- source info panel -->
          <source-info
            :mode="mode"
            :show.sync="showInfo"
            :source.sync="source"
            @store="$emit('updateSource')"
          />

        </div>

      </div>

    </div>

    <!-- instructions -->
    <block-instructions
      v-model="instructions"
      :mode="mode"
      :show="showInstructions"
      @close="closeInstructions"
    />

  </div>
</template>

<script>
import { clone } from '../tic';
import SourceInfo from '../components/SourceInfo';
import { mapGetters } from 'vuex';
import BlockInstructions from './BlockInstructions';
import HiVideo from '../components/hiVideo';

export default {
  name: 'BbSorting',

  components: {
    SourceInfo,
    BlockInstructions,
    HiVideo
  },

  props: {
    blockId: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'student'
    },
    view: {
      type: String,
      default: 'view'
    },
    editor: {
      type: Boolean,
      default:false
    },
    showInstructions: {
      type: Boolean,
      default: false
    },
    showMedia: {
      type: Boolean,
      default: false
    },
    addText: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      refresh:false,
      isExpanded:false,
      showInfo:false,
      zoomed:false,

      instructions: null,
      localRecord: {},
      sources: [],
      selectedSource: 0,
      selectedLabel: 0,
      background: undefined,
      backgroundContain: true,
      studentPositions: [],

      labels:[],
      labelColors:[
        '#EEBB00',
        '#CC0000',
        '#00AAEE',
        '#55BB00',
        '#AA0099'
      ],
      labelColorNames:[
        'eigeel',
        'rood',
        'cyaan',
        'grasgroen',
        'lichtpaars'
      ],
      defaultLabelColor:4,

      backgrounds:[{
        id:'none',
        desc:'Useful for <strong>randomly presenting sources</strong>',
      }, {
        id:'circles',
        desc:'Useful for showing <strong>relationship to a central factor</strong>',
        url:'/static/img/sorting/Circles.svg'
      }, {
        id:'TimelineNoNumbers',
        desc:'Useful for showing <strong>sequence</strong>',
        url:'/static/img/sorting/TimelineNoNumbers.svg'
      }, {
        id:'LineHorizontal',
        desc:'Useful for showing <strong>positive and negative</strong>',
        url:'/static/img/sorting/LineHorizontal.svg'
      }, {
        id:'LineVertical',
        desc:'Useful for showing <strong>left/right division</strong>',
        url:'/static/img/sorting/LineVertical.svg'
      }, {
        id:'SquaresTwo',
        desc:'Useful for showing <strong>layers of inference</strong>',
        url:'/static/img/sorting/SquaresTwo.svg'
      }, {
        id:'SquaresThree',
        desc:'Useful for showing <strong>more layers of inference</strong>',
        url:'/static/img/sorting/SquaresThree.svg'
      }, {
        id:'XYaxis',
        desc:'Useful for showing <strong>relationship on a graph</strong>',
        url:'/static/img/sorting/XYaxis.svg'
      }, {
        id:'Venn2',
        desc:'Useful for <strong>categorising under two thematic headings</strong>',
        url:'/static/img/sorting/Venn2.svg'
      }, {
        id:'Venn3',
        desc:'Useful for <strong>categorising under three thematic headings</strong>',
        url:'/static/img/sorting/Venn3.svg'
      }],

      drag:false,
      mx:0,
      my:0,
      container: {
        x:0,
        y:0,
        w:0,
        h:0,
        cx:0,
        cy:0
      },

    }
  },

  computed: {
    ...mapGetters('activity',['record','answer']),

    backgroundImage() {
      return this.background? 'url("'+this.background+'")':'none';
    },

    source() {
      return this.index<0? false:this.sources[this.index]
    },

    index() {
      return this.selectedSource-1;
    },

    label() {
      return this.selectedLabel<0? false:this.labels[this.selectedLabel]
    },

    dragItem() {
      return this.source || this.label;
    },

    scale() {
      return this.container.w / 1140;
    }
  },

  watch: {
    record: {
      //monitor global record update (e.g. login changes after block is mounted)
      immediate: true,
      deep: true,
      handler() {
        console.log('BB-sorting: record changed')
        if (this.record && this.record.images)
        {
          this.setSources();
          this.setActions();
        }
      }
    },
    isExpanded (expanded) {
      //auto-select first source if none is selected
      if (expanded && this.selectedSource==0) this.selectedSource = 1;
      else if (!expanded) this.selectedSource = 0;
    },
    mode (m) {
      //handle teacher/student view toggle in BlockEditor
      if (m=='teacher')
      {
        //reset positions
        this.$store.commit('activity/syncCurrentRecord',this.blockId)
        this.setSources()
      }

      //toggle actions in side menu
      this.setActions();
    },
    showMedia (show) {
      //reset options when closing mediaLib
      if (!show) this.$emit('addSources',this.mode=='teacher');
    },
    addText () {
      this.mx = this.container.w - 100;
      this.my = 250;
    }

  },

  mounted() {
    this.containerPosition();

    //listen for esc key
    window.addEventListener('keydown', this.exitAddLabel);
  },

  destroyed() {
    window.removeEventListener('keydown', this.exitAddLabel);
  },

  methods: {

    setActions() {
      this.$emit('addSources',this.mode=='teacher');
      this.$emit('addText',this.mode=='teacher' && this.sources.length? { show:true, button:'Add Label' }:false);
      this.$emit('addInstructions',this.mode=='teacher'? true:this.instructions && this.instructions!='');
    },

    closeInstructions () {
      this.$emit('closeInstructions');
      if (this.mode=='teacher') this.store(true);
    },


    //background

    setBackground(b) {
      this.background = b.url;
      this.store()

      //hide menu with small delay
      setTimeout(() => { this.$refs.bgMenu.hide() },450)
    },

    addBackground() {
      //add custom background: set options and show mediaLib
      let options = {
        title:'Select Source background',
        label:'Set as background',
        max:1,
        excludeTypes:['application/pdf'],
        props:{ background:true }
      }
      this.$emit('addSources',options);
      this.$emit('addMedia');
    },


    //sources

    setSources() {
      this.instructions = this.record.instructions;
      this.background = this.record.background;
      this.backgroundContain = this.background && this.background.indexOf('static')>-1;
      this.sources = clone(this.record.images);

      //check if there's a source to be used as background
      let index = this.sources.findIndex(s => s.background);
      if (index>-1)
      {
        console.log('BB-sorting: using source index='+index+' as background');

        //set background url and remove it from the sources array
        this.background = this.record.images[index].url;
        this.sources.splice(index, 1);
        this.store(true);
      }

      //add answer positions
      if (this.answer) this.studentPositions = clone(this.answer.positions || []);

      let x = -.48;
      let y = -.48;

      this.sources.forEach((s,index) => {

        if (typeof s.x === 'undefined')
        {
          //set default position
          this.$set(s,'x',x);
          this.$set(s,'y',y);
          x += .08;
          y += .03;
        }

        //use student answer if available
        if ((this.mode=='student' || this.mode=='review') && this.studentPositions[index])
        {
          this.$set(s,'x',this.studentPositions[index].x);
          this.$set(s,'y',this.studentPositions[index].y);
        }

      });

      this.setLabels();
    },

    setLabels() {
      this.labels = clone(this.record.labels || []);
    },

    selectSource(index) {
      this.selectedSource = index;
    },

    removeSource() {
      this.$q.dialog({
        title: '<i class="fa fa-trash"></i>&nbsp;Confirm Remove',
        message: '<p>Removing source from this block.</p><p>Are you sure?</p>',
        html: true,
        cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
        ok: { label: 'Yes', color: 'primary', noCaps: true, }
      }).onOk(() => {

        //temporary block transitions
        this.refresh = true;

        this.sources.splice(this.index,1);
        this.selectedSource = 1;
        this.store(true);

        this.$store.commit('activity/syncCurrentRecord',this.blockId)

        this.$nextTick(() => { this.refresh = false });
      })
    },


    //labels

    addLabel() {
      //place new label
      const { x, y, width, height } = this.$refs.ghostText.getBoundingClientRect();
      this.labels.push({
        x:this.selectionX(x - this.container.x),
        y:this.selectionY(y - this.container.y),
        c:this.defaultLabelColor,
        text:'',
        edit:true,
        add:true,
      });

      this.selectedLabel = this.labels.length-1;

      //focus text input
      setTimeout(() => { this.$refs.label[0].focus() },100)

      //exit addText mode
      this.$emit('closeAddText');
    },

    exitAddLabel(e) {
      if (e===true || (this.addText && e.key=='Escape')) this.rset();
    },

    selectLabel(index) {
      if (this.label && this.label.index!=index && this.label.edit)
      {
        //store edited label first
        //?

        delete this.label.edit;
      }
      this.selectedLabel = index;
      this.selectedSource = 0;
      this.isExpanded = false;
    },

    setLabelColor(index) {
      this.label.c = index;
      //keep label input focussed
      this.$nextTick(() => { this.$refs.label[0].focus() });
    },

    setLabelStyle(label) {
      this.$set(label,'invert',!label.invert);
      //keep label input focussed
      this.$nextTick(() => { this.$refs.label[0].focus() });
    },

    removeLabel(label) {
      //remove label, confirm first
      const remove = () => {
        this.labels = this.labels.filter(l => l!=label);
        this.selectedLabel = -1;
        this.store(true);
        //remove label from array and store activity
        //this.$store.commit('activity/syncCurrentRecord',this.blockId) //->??
      }

      if (this.label.add) remove() //remove newly added label without a warning
      else this.$q.dialog({
        title: '<i class="fa fa-trash"></i>&nbsp;Confirm Remove',
        message: '<p>Removing '+(label.text==''? 'empty ':'')+'Label, are you sure?</p>',
        html: true,
        cancel: { noCaps: true, color: 'grey-2', textColor: 'black' },
        ok: { label: 'Yes', color: 'primary', noCaps: true, }
      }).onOk(remove);
    },


    //vuex

    store(save) {
      //update activity or submit answer data
      if (this.mode=='teacher')
      {
        this.storeRecord(save)
      }
      else
      {
        if (!this.editor) this.storeAnswer();
      }
    },

    storeRecord(save) {
      console.log('BB-sorting: push localRecord to Store')
      this.$store.commit('activity/setRecord', {
        block: this.blockId,
        record: {
          instructions: this.instructions,
          background: this.background,
          images: this.sources,
          labels: this.labels.map(l => {
            delete l.add;
            delete l.edit;
            return l;
          })
        }
      })

      if (save) this.$store.dispatch('activity/save');

      this.$store.commit('activity/syncCurrentRecord',this.blockId)
    },

    storeAnswer() {
      console.log('BB-sorting: push answer to Store')
      this.$emit('answer',{ positions:this.sources.map(s => ({ x:s.x, y:s.y })) })
    },


    //event handling

    onMouseDown (e) {
      this.startDrag(e,e.clientX,e.clientY)
    },
    onMouseUp (e) {
      if (this.source || this.label) this.endDrag(e.target)
    },
    onMouseMove (e) {
      if (this.drag) this.updateDrag(e.clientX, e.clientY)
      if (this.addText) this.ghostText(e.clientX, e.clientY)
    },
    onTouchStart (e) {
      if (e.touches.length === 1) this.startDrag(e,e.touches[0].clientX,e.touches[0].clientY)
    },
    onTouchEnd (e) {
      if (e.touches.length === 0 && this.source) this.endDrag(e.target);
    },
    onTouchMove (e) {
      if (this.drag && e.touches.length === 1) this.updateDrag(e.touches[0].clientX, e.touches[0].clientY);
    },

    startDrag (e,x,y) {
      //add new label?
      if (this.addText) return this.addLabel(x,y);

      //not on draggable: reset
      if (!e.target.classList.contains('draggable')) return;

      //block transitions on dragged item
      e.target.parentNode.classList.add('drag');

      this.containerPosition();
      x = x - this.container.x;
      y = y - this.container.y;
      this.drag = {
        x:x,
        y:y,
        dx:x,
        dy:y
      }

      //get current source/label position
      this.drag.sx = this.selectionLeft(this.dragItem.x);
      this.drag.sy = this.selectionTop(this.dragItem.y);
    },

    updateDrag (tx,ty) {
      //store drag position
      this.drag.dx = tx - this.container.x;
      this.drag.dy = ty - this.container.y;

      //get delta
      let dx = tx - this.container.x - this.drag.x;
      let dy = ty - this.container.y - this.drag.y;

      //apply to source or label
      this.dragItem.x = this.selectionX(this.drag.sx + dx);
      this.dragItem.y = this.selectionY(this.drag.sy + dy);
    },

    endDrag (elm) {
      const d = this.drag;
      if (Math.abs(d.dx - d.x)<=1 && Math.abs(d.dy - d.dy)<=1)
      {
        if (this.source)
        {
          //clicked but not dragged: expand sources panel
          this.isExpanded = true;

          //show source info when target elm was the caption
          if (elm.classList.contains('caption')) this.showInfo = true;
        }
        else
        {
          console.log('endDrag on label',this.label)

          //label click: switch to edit mode in teacher view
          if (this.mode=='teacher') this.$set(this.label,'edit',true);
        }
      }
      else
      {
        this.rset();

        if (!(this.label && this.label.edit))
        {
          //save new position
          this.store();
        }
      }

      this.drag = false;
    },

    rset () {
      //exit addLabel mode
      if (this.addText) this.$emit('closeAddText');

      //reset active source
      this.selectedSource = 0;
      this.isExpanded = false;

      //reset active label
      if (this.label && this.label.edit)
      {
        if (this.label.text=='')
        {
          //remove empty label
          this.removeLabel(this.label);
        }
        else
        {
          //store and de-select
          this.store(true)
          delete this.label.edit;
          delete this.label.add;
          this.selectedLabel = -1;
        }
      }
    },

    containerPosition () {
      const { x, y, width, height } = this.$refs.container.getBoundingClientRect();
      this.container = { x:x, y:y, w:width, h:height, cx:width/2, cy:height/2 }
    },

    ghostText (x,y)
    {
        this.mx = x - this.container.x;// - ((this.nw/2) * this.zoom);
        this.my = y - this.container.y;// - ((this.nh/2) * this.zoom);
    },

    //pixel position/size of selection elm
    selectionLeft (x) {
      return this.container.cx + (x * this.container.w);
    },
    selectionTop (y) {
      return this.container.cy + (y * this.container.h);
    },
    selectionWidth (w) {
      return w * this.container.w;
    },
    selectionHeight (h) {
      return h * this.container.h;
    },

    //relative position/size of selection  elm
    selectionX (x) {
      return (x - this.container.cx) / this.container.w;
    },
    selectionY (y) {
      return (y - this.container.cy) / this.container.h;
    },
    selectionW (w) {
      return w / this.container.w;
    },
    selectionH (h) {
      return h / this.container.h;
    },
  }
}
</script>

<style scoped lang="stylus">
@import '~quasar-variables'

#bb-sorting
{
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;

  background:transparent none no-repeat 50% 50%;
  background-size:contain;
  /* background-size:100% auto; */

  overflow:hidden;
}

.guide {
  position:absolute;
  left:20px;
  right:20px;
  top:100px;
  text-align:center;
  z-index:1;
}

#bg-button
{
  position:absolute;
  left: 20px;
  bottom: 20px;
  z-index:2;
}
.bg-list
{
  /* width:450px; */
  font-size:14px;
}
.bg-list-item
{
  color:$paars;
}
.bg-selected
{
  background-color:$paars;
  color:$white;
}

.remove-source
{
  position:absolute;
  right:50%;
  transform:translateX(50%);
  bottom:15px;
  background-color:rgba(0,102,204,.5);
}

#sources
{
  position:absolute;
  right:0;
  top:0;
  width:50%;
  height:100%;
  transition:transform .3s ease 0s;
  z-index:1500;

  background-color:rgba(0,0,0,.75);
  box-shadow: -2px 0 5px rgba(0,0,0,.2);
}
#sources.collapsed
{
  transform:translateX(calc(100% - 50px));
}

.draggable-source
{
  position:absolute;
  left:0;
  top:0;
  width:140px;
  box-shadow:0 3px 3px -2px rgba(0,0,0,.3),0 3px 4px 0 rgba(0,0,0,.2),0 1px 8px 0 rgba(0,0,0,.15)

  cursor:move;

  transform-origin:0 0;
}
.draggable-source:not(.drag)
{
  transition:
    opacity .4s ease 0s,
    left .4s ease 0s,
    top .4s ease 0s;
}
#bb-sorting.refresh .draggable-source
{
  transition:none !important;
}
.draggable-source .caption
{
  padding:2px 5px;
  max-width:100%;
  background-color:$white;

  font-size:14px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;

  cursor:pointer;
}
.draggable-source .caption:hover
{
  white-space:normal
}
.draggable-source.active
{
 /*  border:1px solid magenta; */
}
.draggable-source.inactive
{
  opacity:.55;
  transition-duration:.75s;
}
.draggable-source img
{
  position:relative;
  display:block;
  max-width:140px;
}

.draggable-label
{
  position:absolute;
  left:0;
  top:0;

  border-radius:10px;
  border:2px solid transparent;

  transform-origin:0 0;

  /* font-size:14px; */
  font-weight:600;

  background-color:rgba(255,255,255,.8);
}
.draggable-label .caption
{
  padding:3px 10px;
}
.draggable-label .caption.draggable
{
  cursor:move;
}
.draggable-label.edit
{
  margin:-5px 0 0 -1px;
}
.draggable-label .remove
{
   margin: 5px -5px 0 5px;
}
.draggable-label .invert
{
  margin:8px -1px 4px -1px;
}

.colors
{
  margin:4px;
}
.color
{
  margin:10px 10px 0 0;
  display:block;
  width:18px;
  height:18px;
  border-radius:50%;
  border:2px solid transparent;
  opacity:.5;
  cursor:pointer;
}
.color:last-child
{
  margin-right:0;
}
.color.active
{
  margin-top:9px;
  border-color:rgba(0,0,0,.6);
  opacity:1;
  width:20px;
  height:20px;
}
.color:hover
{
  opacity:1;
}

#add-text
{
  transform:translate(-50%,-50%);
  white-space:nowrap;
  cursor:none;
}


#sources-header
{
  position:absolute;
  width:100%;
  padding:1px 15px 1px 13px;
  height:42px;

  background-color:rgba(0,0,0,.2)
  color:$white;

  z-index:1;
}
#sources.collapsed #sources-header
{
  padding-left:4px;
  background:none;
}
#source-display
{
  height:100%;
  color:$white;
}
#source-display .video
{
  display:flex;
}


</style>
