<template>
  <section class="q-mt-md">
    <div><span class="text-h5">Tags</span> <addButton @add="addRootTag" /></div>
    <div class="row q-pa-md">
      <adminTagEntry @bus="bus" :tag="tags" />

      <div>
        Sorting box

        <h1 v-for="tag in tags">{{tag.child}}</h1>
      </div>
    </div>




  </section>
</template>
<script>
import { API } from "../tic";
import { mapGetters } from "vuex";
import tagOptions from "@/components/hiTags";
import adminTagEntry from "@/admin/components/adminTagEntry";
import tagmodel from "@/models/tag";
import addButton from "@/admin/components/addButton";

export default {
  name: "partner-admin-tags",
  components: { tagOptions, adminTagEntry, addButton },
  computed: {
    ...mapGetters("user", ["isAdmin", "isPartner", "uuid"]),
  },

  mounted() {
    console.log(">> mounted PartnerTags");
    this.load();
    console.log(this.tags)
  },

  methods: {
    load() {
      const vm = this;
      API.post("/partners/get-details", { member: vm.uuid }).then((r) => {
        vm.partner = { ...r.data.data };
        vm.code = vm.partner.slug;
        API.get(`/tags/all/${vm.code}`).then((r) => {
          console.log("tags: ", r.data);
          vm.tags = r.data.data;
        });
      });
    },

    remove(tag) {
      this.$q
        .dialog({
          title: '<i class="fad fa-trash"></i>&nbsp;Confirm Delete',
          message: `Are you sure you want to delete this tag?<br><b>${tag.name}</b>`,
          options: {
            type: "checkbox",
            model: [],
            items: [{ label: "Remove childs too", value: "opt1", color: "primary " }],
          },
          html: true,
          cancel: {
            noCaps: true,
            color: "grey-2",
            textColor: "black",
          },
          ok: { label: "Yes", color: "primary", noCaps: true },
        })
        .onOk(() => {
          API.post("/tags/delete", { tag: tag.uuid, member: this.user }).then((r) => {
            this.load();
          });
        });
    },

    bus(data) {
      if (data.action === "remove") {
        // console.log("* REMOVE");
        this.remove(data.tag);
      }

      if (data.action === "edit") {
        // console.log("* EDIT");
        this.edit(data.tag);
      }

      if (data.action === "add") {
        // console.log("* ADD");
        this.add(data.tag.uuid);
      }
    },

    add(parent) {
      const vm = this;
      this.$q
        .dialog({
          title: '<i class="fa fa-plus"></i>&nbsp;Add Tag',
          message: "",
          prompt: {
            model: "",
            type: "text",
          },
          html: true,
          ok: { label: "Ok", color: "primary", noCaps: true },
        })
        .onOk((data) => {
          console.log("received: ", data)
          API.post("/tags/add", {
            tag: data,
            member: vm.uuid,
            parent: parent,
          });
          vm.load();
        });
    },

    edit(tag) {
      const vm = this;
      this.$q
        .dialog({
          title: '<i class="fa fa-plus"></i>&nbsp;Edit Tag',
          message: "",
          prompt: {
            model: tag.name,
            type: "text",
          },
          html: true,
          ok: { label: "Ok", color: "primary", noCaps: true },
        })
        .onOk((data) => {
          tag.name = data;
          API.post("/tags/update", {
            tag: tag,
            member: vm.uuid,
          });
          vm.load();
        });
    },

    addRootTag() {
      const vm = this;
      console.log("addRootTag");
      this.$q
        .dialog({
          title: '<i class="fa fa-plus"></i>&nbsp;Add Tag',
          message: `Enter a tag`,
          prompt: {
            model: "",
            type: "text",
          },
          html: true,
          ok: { label: "Ok", color: "primary", noCaps: true },
        })
        .onOk((data) => {
          API.post("/tags/add", {
            tag: data,
            member: vm.uuid,
            parent: vm.code,
          });
          vm.load();
        });
    },
  },

  data() {
    return {
      code: "code",
      partner: { slug: "" },
      tag: { ...tagmodel.model },
      parent: "",
      tags: [],
    };
  },
};
</script>
