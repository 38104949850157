<template>
  <div>
    <hi-search v-model="searchFor" placeholder="Find your e-Learning Activities" />
    <my-filter :show-only-sort="true" @filterChanged="updateTags" @sortOn="sort" />

    <section v-if="hasData" id="card-holder">
      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md" v-if="sources.length">
          <span
            :key="i"
            v-for="(card, i) in sources"
            class="column"
            :id="card.activity.uuid"
          >
            <Card
              :index="i"
              :item="card.activity"
              :title="card.activity.title"
              :image="card.image"
              :uuid="card.activity.uuid"
              :tags="card.tags"
              :has_tags="card.has_tags"
              :btn-share="true"
              :btn-edit-url="'/ela/' + card.activity.uuid + '?load'"
              :btn-student-view="`/student/view/${card.activity.uuid}/`"
              :btn-delete="true"
              :btn-select="false"
              :goto="`/ela/${card.activity.uuid}`"
              type="e-Learning Activity"
              :csstype="card.activity.is_published ? 'ela published' : 'ela'"
              @goShowSharing="showShareModal(card.activity.uuid)"
              @deleteCard="deleteCard"
              :btnPublish="CAN('can_publish_tl') && !card.activity.is_published"
              :btnUnPublish="CAN('can_publish_tl') && card.activity.is_published"
              @publish="publishCard"
              @unpublish="unpublishCard"
              @toggleSelect="toggleSelect"
              :meta="meta(card)"
              :hover="false"
              @reload="$store.dispatch('user/getActivities')"
            >
              {{ card.activity.description }}</Card
            >
          </span>
        </div>
      </div>
    </section>
    <section v-else id="card-holder">
      <div class="row q-mx-auto justify-between items-start nosources">
        <div class="col">
          <h3 v-if="$store.getters['user/totalActivities']">
            <i class="fa fa-exclamation-circle" aria-hidden="true" /> Your filter didn't
            contain any eLA's
          </h3>
          <h3 v-else>
            <i class="fa fa-exclamation-circle" aria-hidden="true" /> No activities yet
          </h3>
          <q-btn
            no-caps
            color="primary"
            class="q-my-md"
            label="Create an e-Learning Activity"
            icon="fas fa-pencil"
            to="/builder"
          />
        </div>
      </div>
      <!--
        <div class="row small-up-2 medium-up-3 large-up-4">
            <h3><i class="fa fa-exclamation-circle" aria-hidden="true"></i>no activities</h3>
        </div>
      -->
    </section>

    <!-- pop-up modal SHARE -->
    <!-- we need the classes for the Foundation CSS. Triggered in Foundation via showShareModal-->
    <!-- eventually we could/should use v-show instead -->
    <q-dialog v-model="showSharing">
      <q-card style="width: 700px; max-width: 80vw" class="q-pa-md text-center">
        <h3 class="q-mb-md">Choose this</h3>

        <div class="q-btn-group flat spread">
          <q-btn
            @click="shareWithStudents"
            label="Share with students"
            icon="fa fa-users"
          />
          <q-btn @click="shareWithOthers" label="Share with Others" icon="fa fa-share" />
        </div>
      </q-card>
    </q-dialog>

    <!--  ===== SHARE WITH STUDENTS ===== -->
    <q-dialog v-model="showStudents">
      <q-card style="width: 700px; max-width: 80vw" class="q-pa-md">
        <div class="row q-py-md q-mx-auto justify-between items-start">
          <div class="col">
            <div xhow="showStudents">
              <h4 class="q-my-md">
                1. Select or add tags
                <small>
                  so that you can use your activity multiple times and can differentiate
                  between answers.
                </small>
              </h4>
              <div
                class="row q-mx-auto justify-between items-start selections"
                id="select-tags"
              >
                <div class="col">
                  <h5>Year</h5>

                  <q-checkbox
                    :key="i"
                    v-for="(year, i) in $store.getters['user/tagYears']"
                    @input="meChanged(year.uuid)"
                    :value="isChecked(year.uuid)"
                    color="primary"
                    keep-color
                    :label="year.name"
                    :toggle-indeterminate="false"
                    class="tag"
                  />
                </div>
                <div class="col">
                  <h5>Class</h5>
                  <q-checkbox
                    :key="i"
                    v-for="(clas, i) in $store.getters['user/tagClasses']"
                    @input="meChanged(clas.uuid)"
                    :value="isChecked(clas.uuid)"
                    color="primary"
                    keep-color
                    :label="clas.name"
                    :toggle-indetermrinate="false"
                    class="tag"
                  />
                </div>
              </div>

              <div
                class="row q-mx-auto q-py-md justify-between items-start"
                id="select-tags"
              >
                <div class="col add-tags-container q-pr-md q-py-md">
                  <q-input
                    filled
                    v-model="iyear"
                    @keyup.enter="
                      addTag(iyear, 'MyYears');
                      iyear = '';
                    "
                    type="text"
                    placeholder="Add new year"
                  />
                  <q-btn
                    filled
                    icon-right="fa fa-plus"
                    label="Add"
                    @click="
                      addTag(iyear, 'MyYears');
                      iyear = '';
                    "
                    class="q-my-md"
                    color="primary"
                  />
                </div>

                <div class="col add-tags-container q-py-md">
                  <q-input
                    filled
                    v-model="iclass"
                    @keyup.enter="
                      addTag(iclass, 'MyClasses');
                      iclass = '';
                    "
                    type="text"
                    placeholder="Add new class/group"
                  />
                  <q-btn
                    filled
                    icon-right="fa fa-plus"
                    label="Add"
                    @click="
                      addTag(iclass, 'MyClasses');
                      iclass = '';
                    "
                    class="q-my-md"
                    color="primary"
                  />
                </div>
              </div>

              <div class="row justify-end items-end q-py-md">
                <div class="col">
                  <q-btn
                    filled
                    v-if="!showShareLink"
                    @click="startSharing()"
                    label="Next steps"
                    no-caps
                    icon-right="fa fa-arrow-right"
                    class="float-right"
                    color="primary"
                  />
                </div>
              </div>
              <div v-if="showShareLink">
                <h4 class="q-my-md">
                  2. Copy shareable link, anyone with the link can view.
                  <small>
                    Shareable links are stored under MyHistoriana > MyShares
                  </small>
                </h4>
                <!-- <div class="input-group large-6"> -->
                <q-input
                  readonly
                  id="sl1"
                  class
                  type="text"
                  v-model="shareLink"
                  ref="shareStudents"
                />
                <q-btn
                  icon="fa fa-clipboard"
                  label="Copy link"
                  color="primary"
                  @click="flash('shareStudents', 'Link copied to clipboard')"
                />

                <!-- </div> -->
                <p class="msg">{{ flashMsg }}</p>

                <h4 class="q-my-md">
                  3. Paste the copied link in mail or other program to send to students
                </h4>

                <!-- deze button is nodig omdat anders de modal niet gesloten wordt in de flow-->
                <q-btn
                  label="Done"
                  color="primary"
                  :class="{ ok: codeGenerated, inactive: !isCopied }"
                  @click="closeStudentsModal"
                />
              </div>
            </div>
            <!-- </div>
            </div>-->
          </div>
        </div>
      </q-card>
    </q-dialog>

    <!-- ===== SHARE WITH OTHERS ===== -->
    <q-dialog v-model="showOthers">
      <q-card style="width: 700px; max-width: 80vw" class="q-pa-md">
        <div class="row">
          <div class="col">
            <div v-show="showOthers">
              <div v-show="!showShareLink" class="row clearfix">
                <q-btn class="button" @click="startSharing()" label="START SHARING ???" />
              </div>
              <div v-if="showShareLink">
                <h4 class="q-my-md">
                  1. Copy shareable link. Anyone with the link can view.
                </h4>
                <q-input
                  readonly
                  class="linkBox input-group-field"
                  type="text"
                  v-model="shareLink"
                  ref="shareOthers"
                />
                <q-btn
                  icon="fa fa-clipboard"
                  label="Copy link"
                  @click="flash('shareOthers', 'Link copied to clipboard')"
                  color="primary"
                />
                <p class="msg">{{ flashMsg }}</p>
                <!-- TODO implement later!!!
        <h4>2. Paste the copied link in mail or other program to send to others <strong>OR</strong></h4>
        <input type="text" v-model="sendTo" placeholder="Enter email addresses" />
                <hr class="spacer" />-->
                <!-- deze button is nodig omdat anders de modal niet gesloten wordt in de flow-->
                <q-btn label="Done" @click="sendMail()" color="primary" />
              </div>
            </div>
          </div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import Card from "../tags/Card";
import tic from "../tic";
import { API } from "../tic";
import MyFilter from "../components/MyFilter";
import _ from "lodash";

import { mapState, mapActions } from "vuex";

export default {
  name: "MyActivities",
  components: { Card, MyFilter },
  watch: {
    showStudents() {
      let me = this;
      let url = "http://hi.st/ela";
      this.shareLink = me.showStudents ? url + "s" : url + "o";
    },
    // user input in search field
    searchFor(e) {
      this.$store.dispatch("user/getActivities", { searchFor: e });
    },
    activeFilters(e) {
      this.$store.dispatch("user/getActivities", {
        filters: this.activeFilters,
      });
      console.log("filters: ", this.activeFilters);
    },
  },
  computed: {
    ...mapState({
      CAN: (state) => (perm) => {
        // return true if any is true
        return [
          state.user.permissions.includes("is_partner"),
          state.user.permissions.includes(perm),
        ].some((v) => v);
      },
    }),

    bmeChecked: {
      get: function (e) {
        console.log("getter CHECK", e, this);
        return this.shareTags.includes(this.clicked);
      },

      set: function (value, e) {
        console.log("setter SET: ", value, this);
      },
    },

    hasData() {
      return this.$store.getters["user/numActivities"];
    },
    sources() {
      console.log("** my activities");
      console.log("user -> ", this.$store.getters["user/uuid"]);
      return this.$store.getters["user/aactivities"](this.sortKey);
    },
  },
  methods: {
    // COPY OF PUBLISH COLLECTION - we use the OLD methods publishCard/unpublishCard for now
    // unpublishActivity(item) {
    //   let vm = this;
    //   console.log('Activity PUBLISH : ', item, vm.uuid);
    //   API.post('/activity/private', {
    //     partner: vm.uuid,
    //     activity: item.uuid
    //   })
    //     .then(r => {
    //       vm.$q.notify({
    //         type: 'positive',
    //         position: 'top',
    //         message: 'e-Learning Activity made private successfully!'
    //       });
    //       this.getCollections();
    //     })
    //     .catch(err => {
    //       this.$q.dialog({
    //         title: '<i class="fa fa-bomb"></i>&nbsp;Error',
    //         message: `${err}`,
    //         html: true,
    //         ok: { label: 'Ok', color: 'primary', noCaps: true }
    //       });
    //     });
    // },

    // publishActivity(item) {
    //   let vm = this;
    //   console.log('Activity PUBLISH : ', item, vm.uuid);
    //   API.post('/activity/publish', {
    //     partner: vm.uuid,
    //     activity: item.uuid
    //   })
    //     .then(r => {
    //       vm.$q.notify({
    //         type: 'positive',
    //         position: 'top',
    //         message: 'e-Learning Activity published successfully!'
    //       });
    //       this.getCollections();
    //     })
    //     .catch(err => {
    //       this.$q.dialog({
    //         title: '<i class="fa fa-bomb"></i>&nbsp;Error',
    //         message: `${err}`,
    //         html: true,
    //         ok: { label: 'Ok', color: 'primary', noCaps: true }
    //       });
    //     });
    // },

    meChanged(uuid) {
      this.clicked = uuid;
      console.log("changeklik: ", uuid);
      if (this.shareTags.includes(uuid)) {
        this.shareTags = this.shareTags.filter((e) => {
          e.uuid !== uuid;
        });
      } else {
        this.shareTags.push(uuid);
      }
    },

    isChecked(uuid) {
      return this.shareTags.includes(uuid);
    },

    shareWithStudents() {
      this.showSharing = false;
      this.showStudents = true;
      this.showOthers = false;
      this.shareWith = "Students";
    },

    shareWithOthers() {
      this.showSharing = false;
      this.showStudents = false;
      this.showOthers = true;
      this.shareWith = "Others";
      this.startSharing();
    },

    meta(item) {
      const fields = [
        {
          label: "title",
          value: item.activity.title ? item.activity.title : null,
        },
        {
          label: "created",
          value: item.activity.created ? item.activity.created : null,
        },
        { label: "id", value: item.activity.uuid ? item.activity.uuid : null },
        {
          label: "shared",
          value: item.share_count ? `${item.share_count} times` : "never",
        },
      ].filter((e) => {
        return e.value;
      });

      return {
        type: "activity",
        objectType: "activity",
        fields: fields,
      };
    },

    sort(key) {
      this.sortKey = key;
    },
    updateTags(tags) {
      this.shareTags = tags;
    },

    closeStudentsModal() {
      this.showStudents = false;
      this.isCopied = false; // reset UI when modal is gone
      if (this.showShareLink) {
        window.eventBus.$emit(
          "alert",
          "Your share is created, you can share it by passing the link to your students.<br>Monitor progess via MyShares",
          "Share created"
        );
      }
    },
    deleteSelected() {
      let vm = this;
      this.$confirm("This will permanently delete the items. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "Delete completed",
          });
          vm.$store.dispatch("activity/delete", vm.selectedCards).then(() => {
            vm.$store.dispatch("user/getActivities");
            vm.selectedCards = [];
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    toggleSelect(card, iets) {
      this.selectedCards = tic.toggleItemInArray(this.selectedCards, card);
    },

    publishCard(item, idx) {
      let vm = this;
      console.log("publish: ", item);
      let me = this;
      API.post("/publish-activity", {
        user: this.$store.getters["user/uuid"],
        uuid: item.activity.uuid,
      })
        .then((resp) => {
          vm.$q.notify({
            type: "positive",
            position: "top",
            message: "e-Learning Activity published successfully!",
          });
          // this.getCollections();
          vm.$store.dispatch("user/getActivities");
        })
        .catch((err) => {
          this.$q.dialog({
            title: '<i class="fa fa-bomb"></i>&nbsp;Error',
            message: `${err}`,
            html: true,
            ok: { label: "Ok", color: "primary", noCaps: true },
          });
        });

      // vm.$store.commit('user/setActivityPublished', idx);
    },
    unpublishCard(item, idx) {
      let vm = this;
      API.post("/unpublish-activity", {
        user: this.$store.getters["user/uuid"],
        uuid: item.activity.uuid,
      })
        .then((resp) => {
          vm.$q.notify({
            type: "positive",
            position: "top",
            message: "e-Learning Activity UNpublished successfully!",
          });
          vm.$store.dispatch("user/getActivities");
        })
        .catch((err) => {
          this.$q.dialog({
            title: '<i class="fa fa-bomb"></i>&nbsp;Error',
            message: `${err}`,
            html: true,
            ok: { label: "Ok", color: "primary", noCaps: true },
          });
        });
    },

    sendMail() {
      // check if we need to send a mail
      if (this.sendTo) {
        console.log("card: ", this.currentCard);
        console.log("MAIL SENT TO: ", this.sendTo);
        console.log("Link: ", this.shareLink);
      }
      // close the popup
      this.showOthers = false;
    },
    deleteCard(index) {
      let me = this;
      API.post("/delete-activity", {
        user: this.$store.getters["user/uuid"],
        activity: this.sources[index].activity.uuid,
      }).then(
        (resp) => {
          //console.log(JSON.stringify(resp))
          if (resp.data.status === "ok") {
            me.$store.dispatch("user/getActivities");
            me.$store.dispatch("sendFeedback", {
              msg: "Activity deleted",
              status: "ok",
            });
          } else {
            console.log(resp.data.msg);
            me.$store.dispatch("sendFeedback", {
              msg: "Error deleting activity",
              status: "nok",
            });
          }
        },
        (err) => {
          console.log("ERROR", err);
        }
      );
    },

    addTag(item, tagset) {
      this.$store.dispatch("user/addTag", { set: tagset, name: item });
    },

    startSharing() {
      let me = this;
      me.showShareLink = true;
      me.shareLink = "Creating unique link...";

      // first register the share to generate a unique link
      // with the MyShare.uuid
      API.post("/share-activity", {
        user: this.$store.getters["user/uuid"],
        activity: me.currentCard,
        mode: me.shareWith,
        tags: me.shareTags,
      }).then((resp) => {
        // create the url in the Shortener service
        console.log("create share link url: ", resp.data.share.url);
        console.log("create share link uuid: ", resp.data.share.uuid);

        API.post("/hist/create", {
          user: this.$store.getters["user/uuid"],
          url: resp.data.share.url,
          shortlinkfor: resp.data.share.uuid,
          activity: me.currentCard,
        }).then((z) => {
          me.shareLink = z.data.shortUrl;
          me.isCopied = false; // reset state for new link
          // enable Done button
          me.codeGenerated = true;
        });
        me.thisShare = resp.data.share.uuid;
        //console.log('created ',JSON.stringify(r.data))
      });
    },
    showShareModal(card) {
      let me = this;
      console.log("## show sharing modal ##", card);
      //$('#share_modal').foundation('open')
      me.showSharing = true;
      me.currentCard = card;
      me.codeGenerated = false;
      me.shareLink = "Creating unique link...";
      me.showShareLink = false;
      // console.log("currentCard is now: ", me.currentCard)
    },
    flash(ref, msg) {
      let vm = this;
      vm.$refs[ref].select();
      document.execCommand("copy");
      //localhost:8080/hist/HO
      http: vm.flashMsg = msg;
      vm.isCopied = true;
      setTimeout(function () {
        vm.flashMsg = "";
      }, 1000);
    },
  },

  mounted() {
    let me = this;
    // needed for tags-dropdown
    me.$store.dispatch("user/getActivities");

    // needed for the tags
    API.post("/getProfile", { uuid: this.$store.state.user.uuid }).then((response) => {
      console.log("GET SOURCES in activties ");
      console.log("user: ", this.$store.state.user.uuid);
      if (response.data.user) {
        for (let t of response.data.user.tags) {
          let tagLookup = {
            MyLanguages: me.languages,
            MyYears: me.years,
            MyClasses: me.classes,
            MyKeywords: me.keywords,
          };

          try {
            tagLookup[t.tag].push(t);
          } catch (e) {
            console.log("Ignored unsupported TAG cloud: ", t.tag);
          }
        }
      }
    });
  },
  data() {
    return {
      clicked: "",
      selectedYears: [],
      isCopied: false,
      selectedCards: [],
      iclass: "",
      iyear: "",
      searchFor: null,
      languages: [],
      years: [],
      classes: [],
      keywords: [],
      activeFilters: [],
      // these for sharing ux
      shareTags: [], // the tags for a share (in modal)
      showStudents: false,
      showOthers: false,
      showSharing: false,
      showShareLink: false,
      codeGenerated: false,
      shareWith: "?",
      flashMsg: "",
      shareLink: null,
      thisShare: null,
      currentCard: null,
      sortKey: "newest",
      sendTo: null,
    };
  },
};
</script>
