<template>
  <section style="height: 100vh; background-color: #ccc">
    <!-- <div class="row q-mx-auto justify-between item-start">
      <div class="col q-pa-md">
        <p>
          Manage your tags by adding or deleting. You use these custom tags to
          add them to the e-Learning activities you create.
        </p>
      </div>
    </div> -->

    <div class="row q-mx-auto justify-between item-start">
      <div class="col-8 q-pa-md">
        <h2>
          Team Members for {{ partnerAdmin.name }}
          <q-btn
            @click="addTeamMember = !addTeamMember"
            flat
            size="md"
            icon="fas fa-plus"
            label="Add"
            class="bg-primary text-white"
          />
        </h2>

        <q-dialog v-model="addTeamMember">
          <q-card style="width: 80vw">
            <q-bar>
              <span class="form-title">Add a team member</span>
              <q-space />
              <q-btn round flat v-close-popup icon="far fa-times" />
            </q-bar>
            <q-card-section>
              Type name or email of person to add:
              <q-select
                filled
                v-model="newTeamMember"
                :options="filteredMembers"
                use-input
                input-debounce="2"
                option-value="uuid"
                option-label="label"
                @filter="filterMember"
                hint="Note: Person must be an existing Historiana member"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </q-card-section>
            <q-card-actions align="center">
              <q-btn v-close-popup label="Cancel" />
              <q-btn
                color="primary"
                label="Ok"
                :disable="!(newTeamMember && newTeamMember.uuid)"
                @click="doAddTeamMember(partnerAdmin, newTeamMember)"
              />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </div>
    </div>

    <q-markup-table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th style="text-align: right">Action</th>
        </tr>
      </thead>
      <tr v-for="e in teamMembers">
        <td>{{ e.member.name }}</td>
        <td>{{ e.member.email }}</td>
        <td>{{ e.role }}</td>
        <td style="text-align: right">
          <q-btn
            @click="doDelete(e)"
            round
            flat
            color="primary"
            icon="fad fa-trash"
          >
            <q-tooltip>Delete member</q-tooltip>
          </q-btn>

          <q-btn
            @click="doDelegate(e)"
            round
            flat
            color="primary"
            icon="fad fa-cog"
          >
            <q-tooltip>Make admin</q-tooltip>
          </q-btn>
        </td>
      </tr>
    </q-markup-table>
  </section>
</template>
<script>
import { API } from "../tic";

import { mapGetters } from "vuex";

export default {
  name: "partner-team",
  mounted() {
    this.load();
    // this.loadTeamMembers();
    // console.log("user: ", this.uuid);
    this.loadTeamMembers();
  },

  computed: {
    ...mapGetters("user", ["uuid", "isPartner", "partnerAdmin"]),
  },
  methods: {
    doDelegate(member) {
      const vm = this;
      this.$q
        .dialog({
          title: '<i class="fad fa-trash"></i>&nbsp;Confirm',
          message: `Delegate <b>Admin</b> rights to this member?`,
          html: true,
          cancel: {
            noCaps: true,
            color: "grey-2",
            textColor: "black",
          },
          ok: { label: "Yes", color: "primary", noCaps: true },
        })
        .onOk(() => {
          API.post("/partners/make-member-admin", {
            partner: vm.partnerAdmin.uuid,
            member: member.member.uuid,
            role: member.role,
            admin: vm.uuid,
          }).then((r) => {
            this.loadTeamMembers();
          });
        });
    },

    doDelete(member) {
      const vm = this;

      const adminCount = this.teamMembers.filter((e) => {
        return e.role === "admin";
      }).length;

      if (adminCount <= 1 && member.role === "admin") {
        this.$q.dialog({
          title: '<i class="fad fa-octagon-exclamation"></i>&nbsp;Error',
          message: `You cannot delete the only admin!`,
          html: true,
          ok: { label: "Doh", color: "primary", noCaps: true },
        });
        return;
      }

      this.$q
        .dialog({
          title: '<i class="fad fa-trash"></i>&nbsp;Confirm',
          message: `Are you sure you want to remove this member?`,
          html: true,
          cancel: {
            noCaps: true,
            color: "grey-2",
            textColor: "black",
          },
          ok: { label: "Yes", color: "primary", noCaps: true },
        })
        .onOk(() => {
          API.post("/partners/delete-member", {
            partner: vm.partnerAdmin.uuid,
            member: member.member.uuid,
            role: member.role,
            admin: vm.uuid,
          }).then((r) => {
            this.loadTeamMembers();
          });
        });
    },

    load() {
      API.get("/system/member-select").then((r) => {
        this.members = r.data.data;
      });
    },

    loadTeamMembers() {
      if (this.partnerAdmin) {
        API.post("/partners/team-members", {
          partner: this.partnerAdmin.uuid,
        }).then((r) => {
          this.teamMembers = [...r.data.data];
        });
      }
    },

    doAddTeamMember(partner, member) {
      const vm = this;
      console.log("ADD team member to partner: ", partner);
      console.log("ADD member: ", member);
      API.post("/partners/add-member", {
        member: member.uuid,
        partner: partner.uuid,
      }).then((r) => {
        //console.log("reply: ", r);
        vm.addTeamMember = false;
        this.loadTeamMembers();
      });
    },

    // autocomplete for Members
    filterMember(val, update, abort) {
      if (val.length < 2) {
        abort();
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredMembers = this.members.filter((v) => {
          return v.label?.toLowerCase().indexOf(needle) > -1;
        });
      });
    },
  },
  data() {
    return {
      addTeamMember: false,
      filteredMembers: [],
      members: [],
      newTeamMember: null,
      teamMembers: [],
    };
  },
};
</script>
