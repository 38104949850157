import { render, staticRenderFns } from "./NarrativeRelated.vue?vue&type=template&id=064249f6&scoped=true&"
import script from "./NarrativeRelated.vue?vue&type=script&lang=js&"
export * from "./NarrativeRelated.vue?vue&type=script&lang=js&"
import style0 from "./NarrativeRelated.vue?vue&type=style&index=0&id=064249f6&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064249f6",
  null
  
)

export default component.exports