<template>
  <div v-if="!$q.loading.isActive" class="historical-content">
    <hi-search
      placeholder="Find your multiperspective resources to teach history beyond borders"
      v-model="searchFor"
    />

    <!-- hi-tags context="hc" @updateTags="applyTagsFilter" :showactiveTags="activeTags" /-->

    <hi-filter :types="types" @setFilter="setFilter" />

    <cFilter :ct="this.$route.name" @updateFilter="updateFilter" :activeTags="activeTags"/>

    <!-- ===== END NAVIGATION HISTORICAL CONTENT | START BODY CONTENT ===== -->
    <section v-if="hasData" id="card-holder">
      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md">
          <span
            :key="i"
            v-for="(card, i) in filteredSources(activeFilter)"
            :id="card.uuid"
          >
            <Card
              :partner="card.partner"
              :item="card"
              :title="card.name"
              :type="card.type"
              :csstype="card.csstype"
              :uuid="card.uuid"
              :image-url="card.thumb"
              :url="url(card)"
              :meta="meta(card)"
              :show-meta-button="true"
              class="animated slideInUp fast"
              @reload="reload"
              @updateTags="applyTagsFilter"
            >
              {{ card.intro }}
              <br />
            </Card>
          </span>
        </div>
      </div>
    </section>
    <section v-else class="flex flex-center">
      <h5>No content found.</h5>
    </section>
  </div>
</template>

<script>
// content from:
// http://graph.historiana.eu/key-moments/
// http://graph.historiana.eu/units/
// http://graph.historiana.eu/modules
//
// het lijkt er op dat modules alles is en de andere twee een onderverdeling van dezelfde content

import { API, slugify } from "../tic"
import Card from "../tags/Card"
import hiTags from "../lib/hiTags"
import cFilter from "../lib/cFilter"

export default {
  name: "HistoricalContent",
  components: { Card, hiTags, cFilter },
  //props: ['slug', 'type'],

  props: {
    slug: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "",
    },
  },

  computed: {
    hasData() {
      return this.filteredSources(this.activeFilter).length;
    },
  },

  methods: {
    updateFilter(filterData) {
      // user has checked/unchecked tags in popup
      console.log("update with ", filterData)
      this.activeTags = filterData.map(e => e.uuid)

    },

    // tag in Card is clicked, add tag
    applyTagsFilter(tagUuid) {
      console.log("HC.applyTagsFilter : update tags.. ", tagUuid);
      if (!this.activeTags.includes(tagUuid)) {
       this.activeTags.push(tagUuid)
      }
    },

    reload() {
      // fired by hiTagger
      console.log("** RELOAD ");
      this.$q.loading.show({
        delay: 400, // ms
      });

      this.isLoading = false;
      API.get("/index-historical-content").then((resp) => {
        this.items = resp.data.data.items;
        this.types = resp.data.data.types;
        this.$q.loading.hide();
      });
    },

    meta(item) {
      let fields = [
        { label: "title", value: item.name },
        { label: "type", value: item.type },
        { label: "slug", value: item.slug },
        { label: "uuid", value: item.uuid },
      ];

      return {
        sectionTitle: "Historical Content",
        sectionType: "historical-content",
        objectType: item.type,
        fields: fields,
      };
    },

    url(item) {
      return `${this.$route.path}/${slugify(item.type)}/${item.slug}`;
    },

    setFilter(a) {
      this.activeFilter = a;
    },

    filteredSources(filter) {
      console.log("filtered sources.");
      const vm = this;
      let items = this.items;
      if (this.searchFor) {
        items = items.filter((e) => {
          // search in all property values; this needs more work
          // as deeper property-names are part of the string now
          let item = JSON.stringify(Object.values(e)).toLowerCase();
          return item.includes(this.searchFor.toLowerCase());
        });
      }

      if (this.activeTags.length > 0) {
        console.log("apply tag filters, ", this.activeTags);
        return items.filter((e) => {
          if (e.tags?.length) {
            return e.tags.some(function (v) {
              return vm.activeTags.indexOf(v.uuid) >= 0;
            });
          } else {
            return false;
          }
        });
      }

      switch (filter) {
        case "All":
          return items;

        default:
          return items.filter((e) => {
            return e.type === filter;
          });
      }
    },
  },

  mounted() {
    this.$q.loading.show({
      delay: 400, // ms
    });

    this.isLoading = false;
    API.get("/index-historical-content").then((resp) => {
      this.items = resp.data.data.items;
      this.types = resp.data.data.types;
      this.$q.loading.hide();
    });
  },
  data() {
    return {
      isLoading: false,
      items: [],
      types: [],
      activeFilter: "All",
      activeTags: [],
      searchFor: "",
    };
  },
};
</script>
<style>
.card-anim-enter-active {
  animation: coming 1s;
  /* animation-delay: 0.5s; */
  opacity: 0;
}
.card-anim-leave-active {
  /* animation: going 1s; */
}

@keyframes going {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes coming {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
