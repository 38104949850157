<template>
  <div>
    name: {{$route.name}}

      <!-- tags only to be shown in my-sources context, not in the eBuilder -->
            <div v-if="$route.name === 'my-sources'">
              <!-- select tags mag weg, komt in de pop-up van de i -->
              <p v-if="$store.getters['user/hasTags']">
                <!-- <p v-if="!$store.getters['user/hasTags']"> -->
                <q-btn
                  no-caps
                  label="Select tags"
                  @click="showTags = !showTags"
                  icon="fa fa-tags"
                />

                <q-dialog v-model="showTags">
                  <q-card style="width: 40vw">
                    <q-bar>
                      Select tags
                      <q-space />
                      <q-btn
                        dense
                        flat
                        round
                        icon="far fa-times"
                        v-close-popup
                      />
                    </q-bar>

                    <q-card-section>
                      <MemberTags
                        v-if="hasTags('MyClasses')"
                        :active="item.mytags"
                        name="Class"
                        nodelabel="MyClasses"
                        @input="toggleTag"
                      />
                      <MemberTags
                        v-if="hasTags('MyYears')"
                        :active="item.mytags"
                        name="Year"
                        nodelabel="MyYears"
                        @input="toggleTag"
                      />
                      <MemberTags
                        v-if="hasTags('MyKeywords')"
                        :active="item.mytags"
                        name="Keyword"
                        nodelabel="MyKeywords"
                        @input="toggleTag"
                      />
                      <MemberTags
                        v-if="hasTags('MyLanguages')"
                        :active="item.mytags"
                        name="Language"
                        nodelabel="MyLanguages"
                        @input="toggleTag"
                      />
                    </q-card-section>

                    <q-card-actions align="center" class="q-mb-md">
                      <q-btn
                        no-caps
                        label="Ok"
                        @click="isChanged = true"
                        v-close-popup
                      />
                    </q-card-actions>
                  </q-card>
                </q-dialog>
              </p>
              <p v-else>
                You can assign your own tags if you define them via
                <router-link :to="'/my/tags'">MyTags</router-link>
              </p>
</div>

</div>
</template>
<script>
export default {
  name: "usertags",
  props: {
    foo:"bar"
  }

};
</script>